import React, {Component} from "react"

import {withTranslation} from "react-i18next";
import Header_phone from "../../../components/header/Header_phone";
import Tail_phone from "../../../components/tail/Tail_phone";

import "./MusiON_phone.scss"

import line from "./img/line.png"
import musiCON_img1 from "./img/1.png"
import musiCON_img2 from "./img/2.png"
import musiCON_img3 from "./img/3.png"

class MusiON_phone extends Component {

    linkToClick(url) {
        this.props.history.push("/" + url);
    }

    render() {
        const {t} = this.props;
        return (
            <div className="project-phone-main">
                <Header_phone></Header_phone>
                <div className="project-phone-content">
                    <div className="project-title">
                        <img src={line}/>
                        <div className="text1">
                            MusiCON：
                        </div>
                        <div className="text2">
                            MusiCON介绍
                        </div>
                    </div>
                    <div className="project-A">
                        <div className="project-bg-A" onTouchEnd={()=>this.linkToClick("musiCON-International-Composition-Competition")}>
                            <img src={musiCON_img1} className="img" />
                            <span className="text-A">A</span>
                            <span className="text">{t("header.musiCON.musiCON_a")}</span>
                        </div>
                    </div>
                    <div className="project-B">
                        <div className="project-bg-B" >
                            <img src={musiCON_img2} className="img"/>
                            <span className="text-B">B</span>
                            <span className="text">{t("header.musiCON.musiCON_b")}</span>
                        </div>
                    </div>
                    <div className="project-C">
                        <div className="project-bg-C" >
                            <img src={musiCON_img3} className="img"/>
                            <span className="text-C">C</span>
                            <span className="text" >{t("header.musiCON.musiCON_c")}</span>
                        </div>
                    </div>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        );
    }
}

export default withTranslation()(MusiON_phone);
