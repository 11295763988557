import React, { Component } from "react";
import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import style from'./NewsDetail-5.module.scss'
import fan from './img/victory.png'
import pupd from './img/meal.png'
import unit from './img/unit.png'
import annual from './img/annual.png'
import also from './img/also.png'
import found from './img/found.png'
import music from './img/music.png'
import { withTranslation } from "react-i18next"

class NewsDetail5 extends Component {
    constructor() {
        window.scrollTo(0, 0)
        super();
    }

    changeLast() {
        this.props.history.push("/1st-musicon-composition-competition")
    }
    changeNext() {
        this.props.history.push("/detail-four")
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Header></Header>
                <div className={style.leftCircle}></div>
                <div className={style.rightCircle}></div>

                <div className={style.main}>
                    <div className={style.top_date}>
                        <div></div>
                        <div>07/27/2022</div>
                    </div>
                    <div className={style.mbody}>
                        <div className={style.top_text}>
                            <span>{t('news.sixth_text_student')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <br />
                            {t('news.sixth_text_facemask')}
                        </div>
                        <img src={fan} />
                        <div className={style.text_center_red}>
                            <span>{t('news.sixth_text_english')}</span>
                        </div>
                        {/* <img src={line} className="line"/> */}
                        <div className={style.detail_text}>
                            <br />
                            {t('news.sixth_text_title')}
                        </div>
                        <img src={pupd} />
                        <div className={style.text_center_red}>
                            <span>{t('news.sixth_text_awars')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <br />
                            {t('news.sixth_text_comper')}
                        </div>
                        <img src={unit}/>
                        <div className={style.text_center_red}>
                            <span>{t('news.sixth_text_over')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <br />
                            {t('news.sixth_text_prize')}
                        </div>
                        <img src={annual}/>
                        <div className={style.text_center_red}>
                            <span>{t('news.sixth_text_grade')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <br />
                            {t('news.sixth_text_time')}
                        </div>
                        <img src={also}/>
                        <div className={style.text_center_red}>
                            <span>{t('news.sixth_text_winer')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <br />
                            {t('news.sixth_text_recevied')}
                        </div>
                        <img src={found}/>
                        <div className={style.text_center_red}>
                            <span>{t('news.sixth_text_yuth')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <br />
                            {t('news.sixth_text_stage')}
                        </div>
                        <img src={music}/>
                        <div className={style.text_center_red}>
                            <span>{t('news.sixth_text_free')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <br />
                            {t('news.sixth_text_college')}
                            {t('news.sixth_text_wish')}
                        </div>
                    </div>
                </div>

                <div className={style.footer}>
                    <div onClick={this.changeLast.bind(this)}>LAST</div>
                    <div onClick={this.changeNext.bind(this)}>NEXT</div>
                </div>

                <div className="detailFooter">
                    <Tail></Tail>
                </div>

            </div>
        )
    }
}
export default withTranslation()(NewsDetail5)
