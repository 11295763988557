import React, {Component} from "react";
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import style from "./NewsDetail-phone-5.module.scss";


import {withTranslation} from "react-i18next";

class NewsDetailPhone9 extends Component {
    constructor() {
        window.scrollTo(0, 0);
        super();
    }

    click_last() {
        this.props.history.push("/news-phone-detail-ten");
    }

    click_next() {
        this.props.history.push("/news-phone-detail-eight");
    }

    render() {
        const {t} = this.props;
        const language = localStorage.getItem("Language");
        return (
            <div>
                <Header_phone></Header_phone>
                <div className={style.newDetail}>
                    <div className={style.main}>
                        <div className={style.top_date}>
                            <div className={style.icon}></div>
                            <div>3/7/2023</div>
                        </div>
                        <div className={style.mbody}>
                            <div className={style.top_text}>
                                <span>{t("news.news_7_2023_3_title")}</span>
                            </div>
                            <div>
								<span className={style.detail_text}>
								  {t("news.news_7_2023_3_text")}
								</span>
							</div>
                        </div>
                        <div className={style.detail_btn}>
                            <div
                                className={style.detail_btn_last}
                                onTouchEnd={() => this.click_last()}
                            >
                                LAST
                            </div>
                            <div
                                className={style.detail_btn_next}
                                onTouchEnd={() => this.click_next()}
                            >
                                NEXT
                            </div>
                        </div>
                    </div>
                    <Tail_phone></Tail_phone>
                </div>
            </div>
        );
    }
}

export default withTranslation()(NewsDetailPhone9);
