import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import React, {Component} from "react";
import '../musicCourse/MusicCourse.scss'
import './MusicProduction.scss'
import ground from '../musicCourse/img/ground.png'
import line from '../musicCourse/img/line.png'
import longLine from '../musicCourse/img/long-line.png'
import production from './img/production.png'
import $ from "jquery";
import {withTranslation} from "react-i18next"

class MusicProduction extends Component {
    constructor() {
        super()
        this.state = ({
            clickState: true
        })
    }

    clickActive() {
        if (this.state.clickState) {
            $("#courseImg").animate({
                top: '112px',
                left: '1200px',
                width: "501px",
                height: "316px",
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#word").animate({
                top: '-100px',
                left: '0',
                fontSize: "60px",
                letterSpacing: "3px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#line-course").animate({
                top: '-100px',
                left: '140px'
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#course-ground").css('left', "")
            $("#course-ground").animate({
                height: "316px",
                width: "500px",
                left: "1240px",
                top: "340px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#word-sp").animate({
                left: "330px",
                top: "-25px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#rightCircle").animate({
                left: "1346px",
                top: "469px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#course-ground").css('cursor', "default")
            $("#courseImg").css('cursor', "default")
            $("#word").css('cursor', "default")
            this.setState({
                clickState: false
            })
        }
    }

    render() {
        const {t} = this.props
        return (
            <div className="music-production">
                <Header></Header>
                <div className="MusicCourse" style={{height: '1600px'}}>
                    <div>
                        <img src={production} className="img-production" id="courseImg"
                             onClick={this.clickActive.bind(this)}/>
                        <img src={ground} className="img-production-ground" id="course-ground"
                             onClick={this.clickActive.bind(this)}/>
                    </div>
                    <div className="flex-row">
                        <div className="left-middle-circle"></div>
                        <div className="flex-col">
                            <img className="line-course" src={line} id="line-course"
                                 style={{position: 'absolute', top: '-180px', left: '600px'}}/>
                            <div className="title" style={{"marginTop": '414px'}}>
                                <div className="word" id="word"
                                     style={{width: "800px", left: '30px', top: '-120px'}}
                                     onClick={this.clickActive.bind(this)}>Music Production:
                                </div>
                                <div className="word-sp" style={{left: '760px', top: '280px'}} id="word-sp">音乐制作
                                </div>
                            </div>
                        </div>
                        <div className="small-circle-course">
                            <div className="circleDiv" style={{marginTop: "560px", marginLeft: "-60px"}}></div>
                        </div>
                        <div className="right-img">
                            <div className="right-middle-circle" style={{top: "700px", left: "1480px"}}
                                 id="rightCircle"></div>
                        </div>
                    </div>
                    <div className="course-split" style={localStorage.getItem('Language') == 'en' ? {
                        letterSpacing: '1px',
                        width: '888px'
                    } : {}}>{t('musicProduction.musicProduction')}</div>
                    <div className="point"></div>
                    <img src={longLine} className="longLine"/>
                    <div className="introduce">
                        {t('musicProduction.detail')}
                    </div>
                </div>
                <Tail></Tail>
            </div>
        )
    }

}


export default withTranslation()(MusicProduction)
