import React, { Component } from "react";
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import style from './NewsDetail-phone-5.module.scss';

import { withTranslation } from "react-i18next"

class NewsDetailPhone6 extends Component {
    constructor() {
        window.scrollTo(0, 0)
        super();
    }

    click_last() {
        this.props.history.push("/news-phone-detail-seven")
    }
    click_next() {
        this.props.history.push("/news-phone-detail-five")
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Header_phone></Header_phone>
                <div className={style.newDetail}>
                    <div className={style.main}>
                        <div className={style.top_date}>
                            <div className={style.icon}></div>
                            <div>27/07/2022</div>
                        </div>
                        <div className={style.mbody}>
                            <div className={style.top_text}>
                                <span>{t('news.second_title_July_27_2022')}</span>
                            </div>
                            <div>
                                <span className={style.text_green}>▋</span>
                                <span className={style.text_weight_black}>{t('news.second_July_27_2022_1')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_2')}</span>
                                <span className={style.text_green}>{t('news.second_July_27_2022_3')}</span>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_4')}</span>
                                <span className={style.text_green}>{t('news.second_July_27_2022_5')}</span>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_6')}</span>
                            </div>
                            <div>
                                <span className={style.text_weight_black}>{t('news.second_July_27_2022_7')}</span>
                            </div>
                            <div>
                                <span className={style.text_green}>▼</span>
                                <span className={style.text_weight_black}>{t('news.second_July_27_2022_8')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_9')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_10')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_11')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_12')}</span>
                            </div>
                            <div>
                                <span className={style.text_green}>▼</span>
                                <span className={style.text_weight_black}>{t('news.second_July_27_2022_13')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_14')}</span>
                            </div>
                            <div>
                                <span className={style.text_green}>▼</span>
                                <span className={style.text_weight_black}>{t('news.second_July_27_2022_15')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_16')}</span>
                                <span className={style.text_green}>{t('news.second_July_27_2022_17')}</span>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_18')}</span>
                                <span className={style.text_green}>{t('news.second_July_27_2022_19')}</span>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_20')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_21')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_22')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_23')}</span>
                            </div>
                            <div>
                                <span className={style.text_green}>▼</span>
                                <span className={style.text_weight_black}>{t('news.second_July_27_2022_24')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_25')}</span>
                                <span className={style.text_green}>{t('news.second_July_27_2022_26')}</span>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_27')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_28')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.second_July_27_2022_29')}</span>
                            </div>
                        </div>
                        <div className={style.detail_btn}>
                            <div className={style.detail_btn_last} onTouchEnd={()=>this.click_last()}>LAST</div>
                            <div className={style.detail_btn_next} onTouchEnd={()=>this.click_next()}>NEXT</div>
                        </div>
                    </div>
                    <Tail_phone></Tail_phone>
                </div>

            </div>
        )
    }
}
export default withTranslation()(NewsDetailPhone6)
