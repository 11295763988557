import React, {Component} from "react"

import {withTranslation} from "react-i18next";
import "./MusicCourse_phone.scss"
import line from "../../img/line.png"
import courseImg from "../img/course.png";
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import pianoBackground from "../../../about/img/pianoBackground.png";
import longLine from "../img/long-line.png";

class MusicCourse_phone extends Component {


    render() {
        const {t} = this.props;
        return (
            <div className="musicCourse-phone-main">
                <Header_phone></Header_phone>
                <div className="musicCourse-phone-content">

                    <div className="musicCourse-title">
                        <img src={line}/>
                        <div className="text1">
                            Music Courses:
                        </div>
                        <div className="text2">
                            音乐课程
                        </div>
                    </div>

                    <div className="img-div">
                        <img src={pianoBackground} className="course-ground"/>
                        <img src={courseImg} className="course-img"/>
                    </div>
                    <div className="circular"></div>

                    <div className="course-content">
                        <div className="line">
                            <div className="point"></div>
                            <div className="course-split"
                                 style={localStorage.getItem('Language') == 'en' ? {letterSpacing: '1px'} : {}}>{t('musicCourse.classification')}</div>
                        </div>
                        <img src={longLine} className="longLine"/>

                        <div className="classify">
                            <div className="item1">
                                <a className="left" href="/composing-phone">
                                    <span className="span1">作曲</span>
                                    <span className="span2">Composing</span>
                                </a>
                                <a className="right" href="/theory-phone">
                                    <span className="span1">音乐理论</span>
                                    <span className="span2">Music Theory</span>
                                </a>
                            </div>
                            <div className="item2">
                                <a className="left" href="/history-phone">
                                    <span className="span1">音乐素养</span>
                                    <span className="span2">Musicianship</span>
                                </a>
                                <a className="right" href="/skills-phone">
                                    <span className="span1">视唱练耳</span>
                                    <span className="span2">Aural Skills</span>
                                </a>
                            </div>
                            <div className="item2">
                                <a className="left" href="/media-composition-phone">
                                    <span className="span1">电子音乐</span>
                                    <span className="span2">Media Composition</span>
                                </a>
                                <a className="right" href="/guzheng-phone">
                                    <span className="span1">古筝</span>
                                    <span className="span2">Guzheng</span>
                                </a>
                            </div>

                            <div className="item2">
                                <a className="left" href="/volin-phone">
                                    <span className="span1">小提琴/中提琴</span>
                                    <span className="span2">Violin & Viola</span>
                                </a>
                                <a className="right" href="/basson-phone">
                                    <span className="span1">巴松</span>
                                    <span className="span2">Bassoon</span>
                                </a>
                            </div>
                            <div className="item2">
                                <a className="left" href="/piano-phone">
                                    <span className="span1">钢琴</span>
                                    <span className="span2">Piano</span>
                                </a>
                                <a className="right" href="/pipa-phone">
                                    <span className="span1">琵琶</span>
                                    <span className="span2">Pipa</span>
                                </a>
                            </div>
                            <div className="item2" >
                                <a className="left" href="/clarinet-phone">
                                    <span className="span1">单簧管</span>
                                    <span className="span2">Clarinet</span>
                                </a>
                                <a className="right" href="/accordion-phone">
                                    <span className="span1">古典手风琴</span>
                                    <span className="span2">Classical Accordion</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        );
    }
}

export default withTranslation()(MusicCourse_phone);

