import React, {Component} from "react";
import './detailPhoneCommon.scss'
import line from '../../img/thin-line.png'
import historyImg from './img/historyImg.png'
import {withTranslation} from "react-i18next"
import Header_phone from "../../../../../components/header/Header_phone";
import Tail_phone from "../../../../../components/tail/Tail_phone";

class History_phone extends Component {

    linkToClick(val) {
        this.props.history.push("/" + val);
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Header_phone></Header_phone>
                <div className="group">
                    <div className="title">
                        <div className="text1">
                            Musicianship
                        </div>
                        <div className="text2" style={{marginLeft: "6.6rem"}}>
                            音乐素养
                        </div>
                    </div>
                    <img className="thin-line" src={line}/>

                    <img className="right-img" src={historyImg}/>
                    <div className="group-detail"
                         style={localStorage.getItem('Language') == 'en' ? {letterSpacing: "0rem"} : {letterSpacing: "0.05rem"}}>
                        {localStorage.getItem('Language') == 'zh' ? (
                        <div className="detail_composing">
                            <h1>音乐理论</h1>
                            <span>课程介绍</span><br/>
                            <span>如果你想深入地学好音乐，无论是演奏、作曲或指挥，对于音乐基本素质的培养都是至关重要的。May Flower Art Center 音乐理论教研室自主开发的最系统、 最专业的音乐理论 (Music Theory) / 视唱练耳 (Ear-Training) 课程，将会带领学生从零基础直达专业水平。与 RCM、ABRSM 等其他音乐理论系列不同，我们除了涵盖必要的音乐理论知识和相关主题外，还将包含初级作曲技巧，为后期的专业作曲学习及作品分析等课程(配合中心的课程) 奠定必要基础。</span>
                            <br/>
                            <br/>
                            <br/>
                            <span>◆基础音乐理论、视唱练耳预备课程</span><br/>
                            <span>适合人群：零基础</span><br/>
                            <span>授课语言：中文与英文</span><br/>
                            <span>班级人数：12-15人／班</span><br/>
                            <span>学费标准：$45 / Class, total $540.00每个季度(约3500人民币) ；部分教材资料提供电子版，纸质版需要自行购买</span><br/>
                            <span>课时安排：60分钟／课时，共12课时每个季度，每周1课时(逢法定节假日放假) </span><br/>
                            <span>课时安排：60分钟／课时，共12课时每个季度，每周1课时(逢法定节假日放假) </span><br/>
                            <br/>
                            <br/>
                            <br/>

                            <span>◆ 音乐理论</span><br/>
                            <span>适合人群：拥有一定乐器演奏及作曲经验，或已完成预备课程的学生</span><br/>
                            <span>授课语言：中文与英文</span><br/>
                            <span>班级人数：10人／班；A3级别不超过8人</span><br/>
                            <span>学费标准：A1 Beginner level $50.00/Class, total $750.00/ Review Class $500</span><br/>
                            <span>A2 Intermediate level $65/Class, total $975.00/ Review Class $650</span><br/>
                            <span>A3 Advanced level $80/Class, total $1200.00/ Review Class $800</span><br/>
                            <span>课时安排：60分钟／课时，共15课时，每周1课时(逢法定节假日放假) </span><br/>
                            <span>学期时长：一年</span><br/>
                            <span>该课程包括分级考试及结课考试，通过考试的学生将被安排进入下一级别的学习。每个级别都配备相应的复习、巩固班 (Review Class) 。</span><br/>
                            <br/>
                            <br/>
                            <h1>经典作品分析</h1>
                            <span>课程介绍</span><br/>
                            <span>本课程是专业作曲学习必修课程。课程将依托大量的音响及乐谱资料培养学生的作品分析能力，提高作品鉴赏水平。</span><br/>
                            <br/>

                            <span>适合人群：拥有一年以上作曲经验，且已有独立原创作品的学生。 </span><br/>
                            <span>授课语言：中文与英文 </span><br/>
                            <span>班级人数：5人／班 </span><br/>
                            <span>学费标准：$100/ Class </span><br/>
                            <span>课时安排：60分钟／课时(逢法定节假日放假)  </span><br/>
                            <span>学期时长：一年 </span><br/>
                            <br/>
                            <br/>

                            <h1>大师研讨课</h1>
                            <br/>
                            <p>课程介绍</p>
                            <span>本课程会定期邀请当下著名作曲家或演奏家及乐团莅临，进行基于作曲的任何议题的讨论，或特定主题的演讲等。</span><br/>
                            <span>适合人群：拥有一定乐器演奏及作曲经验，且已有独立原创作品的学生。</span><br/>
                            <span>授课语言：中文与英文</span><br/>
                            <span>班级人数：30人／班</span><br/>
                            <span>学费标准：$30/ Class</span><br/>
                            <span>课时安排：60分钟／课时(逢法定节假日放假) </span><br/>
                            <span>学期时长：不限</span><br/>

                            <br/>
                            <br/>
                            <h1>西方音乐史</h1>
                            <p>课程介绍</p>
                            <span>这是最专业的西方音乐史课程， 属于美国音乐学院或大学的预科课程。本课程将探索17世纪至21世纪的西方古典音乐。( 专注于巴洛克时代，古典时代，浪漫主义时代， 20世纪和现当代。)  此外，音乐的创作发展，作曲家，音乐实例分析都将是这10周课程的一部分。</span><br/>
                            <span>适合人群：零基础。</span><br/>
                            <span>授课语言：英文</span><br/>
                            <span>班级人数：10人／班</span><br/>
                            <span>学费标准：400美元(约人民币2800元) ，部分教材资料提供电子版，纸质版需要自行购买</span><br/>
                            <span>课时安排：60分钟／课时，共10课时，每周1课时。</span><br/>
                            <span>学期时长：不限</span><br/>

                            <br/>
                            <br/>
                            <h1>作曲</h1>
                            <p>课程介绍</p>
                            <span>本课程主要目的为激发青少年对作曲的兴趣，培养青少年对声音、音响的敏感度，提高青少年对内心音乐形象的塑造、组织能力。
                                        入学要求：基本的音乐理论知识、视唱练耳训练(配合中心的课程) ；推荐拥有一定乐器演奏经验。</span><br/>
                            <br/>
                            <br/>
                            <span>◆线上作曲大课 兴趣班</span><br/>
                            <span>适合人群：10周岁以上</span><br/>
                            <span>  授课语言：中文与英文</span><br/>
                            <span> 班级人数：12-15人／班</span><br/>
                            <span>  学费标准：1000美元(约人民币6350元) ，部分教材资料提供电子版，纸质版需要自行购买</span><br/>
                            <span>  课时安排：50分钟／课时，共16课时(约四个月) ，每周1课时(逢法定节假日放假) </span><br/>
                            <span>  学期时长：2022年2月到5月，6月到9月，10月到2023年1月</span><br/>
                            <br/>
                            <br/>
                            <span>◆线上作曲大课 强化班</span><br/>
                            <span>适合人群：10周岁以上(推荐14周岁以上) </span><br/>
                            <span>授课语言：中文与英文</span><br/>
                            <span>班级人数：12-15人／班</span><br/>
                            <span>学费标准：2000美元(约人民币12700元) ，部分教材资料提供电子版，纸质版需要自行购买</span><br/>
                            <span>课时安排：50分钟／课时，共32课时(约四个月) ，每周2课时(逢法定节假日放假) </span><br/>
                            <span>学期时长：2022年2月到5月，6月到9月，10月到2023年1月</span><br/>
                            <br/>
                            <br/>
                            <span>◆线上作曲小课 精品兴趣班</span><br/>
                            <span>适合人群：适合人群：10周岁以上</span><br/>
                            <span>授课语言：中文与英文</span><br/>
                            <span>班级人数：5-7人／班</span><br/>
                            <span>学费标准：2000美元(约人民币12700元) ，部分教材资料提供电子版，纸质版需要自行购买</span><br/>
                            <span>课时安排：50分钟／课时，共16课时(约四个月) ，每周1课时(逢法定节假日放假) </span><br/>
                            <span>学期时长：2022年2月到5月，6月到9月，10月到2023年1月</span><br/>
                            <br/>
                            <br/>
                            <span>◆线上作曲小课 精品强化班</span><br/>
                            <span>适合人群：10周岁以上(推荐14周岁以上) </span><br/>
                            <span>授课语言：中文与英文</span><br/>
                            <span>班级人数：5-7人／班</span><br/>
                            <span>学费标准：4000美元(约人民币25400元) ，部分教材资料提供电子版，纸质版需要自行购买</span><br/>
                            <span>课时安排：50分钟／课时，共32课时(约四个月) ，每周2课时(逢法定节假日放假) </span><br/>
                            <span>学期时长：2022年2月到5月，6月到9月，10月到2023年1月</span><br/>
                        </div>
                        ) : (
                        <div className="detail_composing">
                            <h1>Music Theory</h1>
                            <span>Course Description</span><br/>
                            <span>Musicality cultivation is extremely essential for studying music thoroughly, whether to instrumental playing, composing or conducting. The most systematic and professional Music Theory/Ear-Training course at May Flower Art Center will lead students from zero foundation to a professional level. Different from other music theory series such as RCM and ABRSM, in addition to covering the fundamental music theory and related topics, we will also cover introductory composition skills, which are necessary for the future professional composition and music analysis courses (formed by MFAC).</span>
                            <br/>
                            <br/>
                            <br/>
                            <span>◆Music Theory Fundamental / Ear-Training Class</span><br/>
                            <span>Recommended group: zero-based foundation</span><br/>
                            <span>Languages: Mandarin and English</span><br/>
                            <span>Class size: 12-15 students/class</span><br/>
                            <span>Tuition Fee: $45/class, $540/semester, (approx. 3500 RMB); e-textbook may be provided, other textbook hard copies may be purchased additionally. </span><br/>
                            <span>Class time: 60 minutes/class, 12 classes/semester, one class per week (except holidays.)</span><br/>
                            <span>Semesters: one-year span</span><br/>
                            <br/>
                            <br/>
                            <br/>

                            <span>◆Music Theory</span><br/>
                            <span>Recommended group: basic experience on instrumental playing or composition; or has completed Music Theory Fundamental / Ear-Training Class</span><br/>
                            <span>Languages: Mandarin and English</span><br/>
                            <span>Class size: 10 students/class; A3 no more than 8 students/class</span><br/>
                            <span>Tuition Fee: A1 Beginner level $50.00/Class, total $750.00/ Review Class $500
                                        A2 Intermediate level $65/Class, total $975.00/ Review Class $650
                                        A3 Advanced level $80/Class, total $1200.00/ Review Class $800</span><br/>
                            <span>Class time: 60 minutes/class, 15 classes/semester, one class per week (except holidays.)</span><br/>
                            <span>Semesters: one-year span</span><br/>
                            <span>*This course contains entrance exam and final exam. Students who have passed the final exam can enter the next level of study. Each level</span><br/>
                            <br/>
                            <br/>
                            <h1>Music Analysis</h1>
                            <span>Course Description</span><br/>
                            <span>This course is a required course for professional composition study. The course will introduce a large number of recordings and musical scores to cultivate students' ability of analyzing scores and improve the level of musical appreciation.</span><br/>
                            <br/>
                            <span>Recommended group: one year of composition study experience, with own original composition. </span><br/>
                            <span>Languages: Mandarin and English </span><br/>
                            <span>Class size: 5 students/class </span><br/>
                            <span>Tuition Fee: $100/class </span><br/>
                            <span>Class time: 60 minutes/class (except holidays.) </span><br/>
                            <span>Semesters: one-year span</span><br/>
                            <br/>
                            <br/>

                            <h1>Composition Symposium</h1>
                            <br/>
                            <p>Course Description</p>
                            <span>This course regularly invites famous composers/performers/ensembles to composition, masterclass, seminar or lecture.</span><br/>
                            <span>Recommended group: basic experience on instrumental playing or composition with own original work.</span><br/>
                            <span>Languages: Mandarin and English</span><br/>
                            <span>Class size: 30 students/class</span><br/>
                            <span>Tuition Fee: $30/class</span><br/>
                            <span>Class time: 60 minutes/class (except holidays.)</span><br/>
                            <span>Semesters: any</span><br/>

                            <br/>
                            <br/>
                            <h1>Western Music History</h1>
                            <p>Course Description</p>
                            <span>This course is the most professional course in Western music history, a college prep course in American Academy of Music.The course explores the music of the 17th Century through the 21st Century. Focusing on the Baroque era, Classical era, Romantic era, 20th Century, and 21 st Century of Western music. Musical concepts and
                                        developments, composers, musical examples, and class discussions will all be part of the 10-week course</span><br/>
                            <span>Recommended group: zero-based foundation</span><br/>
                            <span>Languages: English</span><br/>
                            <span>Class size: 10-15students/class</span><br/>
                            <span>Tuition Fee: $400 (approx. 2800 RMB); e-textbook may be provided, other textbook hard copies may be purchased additionally.</span><br/>
                            <span>Class time: 60 minutes/class, 10 classes/semester, one class per week (except holidays.)</span><br/>
                            <span>Semesters: any</span><br/>

                            <br/>
                            <br/>
                            <h1>Composition</h1>
                            <p>Course Description</p>
                            <span>Online Composition Class Guidelines
                                     irements: music theory basics, ear-training (within MFAC whole package); some instrumental experience is recommended</span><br/>
                            <br/>
                            <br/>
                            <span>◆Online Collective Course Introductory Classes</span><br/>
                            <span>Recommended age: 10 years old and up</span><br/>
                            <span>Languages: Mandarin and English</span><br/>
                            <span>Class size: 12-15 students/class</span><br/>
                            <span>Tuition Fee: $1000 (or 6350 RMB), e-textbook may be provided, other textbook hard copies may be purchased additionally. </span><br/>
                            <span>Class time: 50 minutes/class, 16 classes in total (4-month period), one class per week (except holidays.)</span><br/>
                            <span>Semester: February - May 2022; June - September 2022; October 2022 - January 2023</span><br/>
                            <br/>
                            <br/>
                            <span>◆Online Collective Course Intensive Classes</span><br/>
                            <span>Recommended age: 10 years old and up (recommended 14 years old and up)</span><br/>
                            <span>Languages: Mandarin and English</span><br/>
                            <span>Class size: 12-15 students/class</span><br/>
                            <span>Tuition Fee: $2000 (or 12700 RMB), e-textbook may be provided, other textbook hard copies may be purchased additionally. </span><br/>
                            <span>Class time: 50 minutes/class, 32 classes in total (4-month period), two classes per week (except holidays.)</span><br/>
                            <span>Semester: February - May 2022; June - September 2022; October 2022 - January 2023</span><br/>
                            <br/>
                            <br/>
                            <span>◆Online Seminar Introductory Classes</span><br/>
                            <span>Recommended age: 10 years old and up</span><br/>
                            <span>Languages: Mandarin and English</span><br/>
                            <span>Class size: 5-7 students/class</span><br/>
                            <span>Tuition Fee: $2000 (or 12700 RMB), e-textbook may be provided, other textbook hard copies may be purchased additionally. </span><br/>
                            <span>Class time: 50 minutes/class, 16 classes in total (4-month period), one class per week (except holidays.)</span><br/>
                            <span>Semester: February - May 2022; June - September 2022; October 2022 - January 2023</span><br/>
                            <br/>
                            <br/>
                            <span>◆Online Seminar Intensive Classes</span><br/>
                            <span>Recommended age: 10 years old and up</span><br/>
                            <span>Languages: Mandarin and English</span><br/>
                            <span>Class size: 5-7 students/class</span><br/>
                            <span>Tuition Fee: $4000 (or 25400 RMB), e-textbook may be provided, other textbook hard copies may be purchased additionally. </span><br/>
                            <span>Class time: 50 minutes/class, 32 classes in total (4-month period), two classes per week (except holidays.)</span><br/>
                            <span>Semester: February - May 2022; June - September 2022; October 2022 - January 2023</span><br/>
                        </div>
                        )
                        }
                    </div>

                    <a href={"musicCourse-phone"}>
                        <div className="btn-phone-border">
                            {t('musicCourse.moreCourse')}
                        </div>
                    </a>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        )
    }
}

export default withTranslation()(History_phone)
