//师资力量
import React, {Component} from "react";
import "./TeacherDetail.scss"
import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import line1 from "./img/line1.png";
import rectangle from "./img/rectangle.png";
import picture from "./img/picture14.jpg";
import line from "./img/line.png";
import {withTranslation} from "react-i18next";

class TeacherDetail_BoDai extends Component {
    constructor(prop) {
        super(prop);
    }

    linkToClick(val) {
        localStorage.setItem("route", val)
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Header></Header>
                <div className="teacherDetail">
                    <div className="upperPart">
                        <div className="content1">
                            <img className="line1" id="line1" src={line1}/>
                            <div className="text1">Bo Dai:</div>
                            <div className="text2">代博</div>
                            <div className="circular1"></div>
                        </div>
                        <div>
                            <div className="circular2"></div>
                            <img className="rectangle" src={rectangle}/>
                            <img className="picture" src={picture}/>
                        </div>
                    </div>
                    <div className="middlePart">
                        <div className="content2">
                            <div className="circular3"></div>
                            <div className={localStorage.getItem("Language") == "zh" ? "text3_zh":"text3_en"}>{t("teacher_details.text")}</div>
                        </div>
                        <img className="line2" src={line}/>
                        <div className="circular4"></div>
                    </div>
                    <div className="lowerPart">
                        <div className={localStorage.getItem("Language") == "zh" ? "text4_zh":"text4_en"} style={{whiteSpace: 'pre-wrap'}}>
                            {t("teacher_details.detail_14.describe_text1")}<br/><br/>
                            {t("teacher_details.detail_14.describe_text2")}<br/><br/>
                            {t("teacher_details.detail_14.describe_text3")}<br/><br/>
                            {t("teacher_details.detail_14.describe_text4")}<br/><br/>
                            {t("teacher_details.detail_14.describe_text5")}<br/><br/>
                            {t("teacher_details.detail_14.describe_text6")}<br/><br/>
                        </div>
                    </div>
                    <a href="/teacher" onClick={this.linkToClick.bind(this, "teacher")}>
                        <div className="more_teachers">
                            <span className="text5">{t('teacher_details.more_teachers')}</span>
                        </div>
                    </a>
                </div>
                <Tail></Tail>
            </div>

        );
    }
}

export default withTranslation()(TeacherDetail_BoDai);
