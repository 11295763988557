import React, {Component} from "react";
import './detailPhoneCommon.scss'
import line from '../../img/thin-line.png'
import gulei from '../../img/gulei.png'
import guzhengImg from './img/guzhengImg.png'
import {withTranslation} from "react-i18next"
import Header_phone from "../../../../../components/header/Header_phone";
import Tail_phone from "../../../../../components/tail/Tail_phone";

class Guzheng_phone extends Component {

    linkToClick(val) {
        this.props.history.push("/" + val);
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Header_phone></Header_phone>
                <div className="group">
                    <div className="title">
                        <div className="text1">
                            Guzheng
                        </div>
                        <div className="text2" style={{marginLeft: "3.6rem"}}>
                            古筝课程
                        </div>
                    </div>
                    <img className="thin-line" src={line}/>

                    <img className="right-img" src={guzhengImg}/>
                    <div className="group-detail"
                         style={localStorage.getItem('Language') == 'en' ? {letterSpacing: "0rem"} : {letterSpacing: "0.05rem"}}>
                        {t('musicCourse.guzheng_detail')}
                    </div>
                    <div className="teacher-title">{t('musicCourse.responsibleTeacher')}</div>
                    <div className="teacher-list">
                        <div onTouchEnd={this.linkToClick.bind(this, "detail-phone-LeiGu")}>
                            <img src={gulei} className="teacher-img"/>
                            <div className="teacher-text">
                                <div className="teacher-name">顾磊</div>
                                <div className="teacher-name-en">Lei Gu</div>
                            </div>
                        </div>
                    </div>

                    <a href={"musicCourse-phone"}>
                        <div className="btn-phone-border">
                            {t('musicCourse.moreCourse')}
                        </div>
                    </a>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        )
    }
}

export default withTranslation()(Guzheng_phone)
