import React, { Component } from "react";
import "./App.scss";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//    PC
import Home from "./app/pages/home/Home";
import About from "./app/pages/about/About";
import StudyAbroad from "./app/pages/projectDescription/studyAbroad/StudyAbroad";
import MusicCourse from "./app/pages/projectDescription/musicCourse/MusicCourse";
import MusicProduction from "./app/pages/projectDescription/musicProduction/MusicProduction";
import Teacher from "./app/pages/teacher/Teacher";
import Roster from "./app/pages/honors/roster/Roster";
import Award from "./app/pages/honors/award/Award";
import News from "./app/pages/news/News";
import Contact from "./app/pages/contact/Contact";
import composing from "./app/pages/projectDescription/musicCourse/detail/Composing";
import NewsDetail1 from "./app/pages/news/detail/NewsDetail-1";
import NewsDetail2 from "./app/pages/news/detail/NewsDetail-2";
import NewsDetail3 from "./app/pages/news/detail/NewsDetail-3";
import NewsDetail4 from "./app/pages/news/detail/NewsDetail-4";
import NewsDetail5 from "./app/pages/news/detail/NewsDetail-5";
import NewsDetail6 from "./app/pages/news/detail/NewsDetail-6";
import NewsDetail7 from "./app/pages/news/detail/NewsDetail-7";
import NewsDetail8 from "./app/pages/news/detail/NewsDetail-8";
import NewsDetail9 from "./app/pages/news/detail/NewsDetail-9";
import NewsDetail10 from "./app/pages/news/detail/NewsDetail-10";
import NewsDetail11 from "./app/pages/news/detail/NewsDetail-11";
import NewsDetail12 from "./app/pages/news/detail/NewsDetail-12";
import NewsDetail13 from "./app/pages/news/detail/NewsDetail-13";

import CompositionCompetition from "./app/pages/musiCON/compositionCompetition/compositionCompetition";

import theory from "./app/pages/projectDescription/musicCourse/detail/Theory";
import history from "./app/pages/projectDescription/musicCourse/detail/History";
import skills from "./app/pages/projectDescription/musicCourse/detail/Skills";
import MediaComposition from "./app/pages/projectDescription/musicCourse/detail/MediaComposition";
import guzheng from "./app/pages/projectDescription/musicCourse/detail/Guzheng";
import violin from "./app/pages/projectDescription/musicCourse/detail/Volin";
import basson from "./app/pages/projectDescription/musicCourse/detail/Basson";
import piano from "./app/pages/projectDescription/musicCourse/detail/Piano";
import pipa from "./app/pages/projectDescription/musicCourse/detail/Pipa";
import clarinet from "./app/pages/projectDescription/musicCourse/detail/Clarinet";
import accordion from "./app/pages/projectDescription/musicCourse/detail/Accordion";
import studyAbroad from "./app/pages/projectDescription/studyAbroad/StudyAbroad";
import TeacherDetail_YimingWu from "./app/pages/teacher/details/TeacherDetail_YimingWu";
import TeacherDetail_HaibeiWang from "./app/pages/teacher/details/TeacherDetail_HaibeiWang";
import TeacherDetail_Yikeshan from "./app/pages/teacher/details/TeacherDetail_Yikeshan";
import TeacherDetail_LiJunlang from "./app/pages/teacher/details/TeacherDetail_LiJunlang";
import TeacherDetail_RenheJiao from "./app/pages/teacher/details/TeacherDetail_RenheJiao";
import TeacherDetail_SunhuimeiXia from "./app/pages/teacher/details/TeacherDetail_SunhuimeiXia";
import TeacherDetail_HanzhiWang from "./app/pages/teacher/details/TeacherDetail_HanzhiWang";
import TeacherDetail_YutingZheng from "./app/pages/teacher/details/TeacherDetail_YutingZheng";
import TeacherDetail_JinYang from "./app/pages/teacher/details/TeacherDetail_JinYang";
import TeacherDetail_DongniXie from "./app/pages/teacher/details/TeacherDetail_DongniXie";
import TeacherDetail_ZhuoDiao from "./app/pages/teacher/details/TeacherDetail_ZhuoDiao";
import TeacherDetail_LeiGu from "./app/pages/teacher/details/TeacherDetail_LeiGu";
import TeacherDetail_CarolineSackleh from "./app/pages/teacher/details/TeacherDetail_CarolineSackleh";
import TeacherDetail_BoDai from "./app/pages/teacher/details/TeacherDetail_BoDai";
import TeacherDetail_ShenyingQian from "./app/pages/teacher/details/TeacherDetail_ShenyingQian";

//    PHONE
import Navigation_phone from "./app/pages/navigation_phone/Navigation_phone";
import Home_phone from "./app/pages/home/phone/Home_phone";
import About_phone from "./app/pages/about/phone/About_phone";
import Project_phone from "./app/pages/projectDescription/Project_phone";
import MusicCourse_phone from "./app/pages/projectDescription/musicCourse/phone/MusicCourse_phone";

import Accordion_phone from "./app/pages/projectDescription/musicCourse/detail/phone/Accordion_phone";
import Basson_phone from "./app/pages/projectDescription/musicCourse/detail/phone/Basson_phone";
import Clarinet_phone from "./app/pages/projectDescription/musicCourse/detail/phone/Clarinet_phone";
import Composing_phone from "./app/pages/projectDescription/musicCourse/detail/phone/Composing_phone";
import Guzheng_phone from "./app/pages/projectDescription/musicCourse/detail/phone/Guzheng_phone";
import History_phone from "./app/pages/projectDescription/musicCourse/detail/phone/History_phone";
import Piano_phone from "./app/pages/projectDescription/musicCourse/detail/phone/Piano_phone";
import Pipa_phone from "./app/pages/projectDescription/musicCourse/detail/phone/Pipa_phone";
import Skills_phone from "./app/pages/projectDescription/musicCourse/detail/phone/Skills_phone";
import Theory_phone from "./app/pages/projectDescription/musicCourse/detail/phone/Theory_phone";
import MediaComposition_phone from "./app/pages/projectDescription/musicCourse/detail/phone/MediaComposition_phone";
import Volin_phone from "./app/pages/projectDescription/musicCourse/detail/phone/Volin_phone";

import MusicProduction_phone from "./app/pages/projectDescription/musicProduction/phone/MusicProduction_phone";
import StudyAbroad_phone from "./app/pages/projectDescription/studyAbroad/phone/StudyAbroad_phone";
import Teacher_phone from "./app/pages/teacher/phone/Teacher_phone";

import TeacherDetail_phone_YimingWu from "./app/pages/teacher/details/phone/TeacherDetail_phone_YimingWu";
import TeacherDetail_phone_HaibeiWang from "./app/pages/teacher/details/phone/TeacherDetail_phone_HaibeiWang";
import TeacherDetail_phone_Yikeshan from "./app/pages/teacher/details/phone/TeacherDetail_phone_Yikeshan";
import TeacherDetail_phone_ShenyingQian from "./app/pages/teacher/details/phone/TeacherDetail_phone_ShenyingQian";
import TeacherDetail_phone_BoDai from "./app/pages/teacher/details/phone/TeacherDetail_phone_BoDai";
import TeacherDetail_phone_SunhuimeiXia from "./app/pages/teacher/details/phone/TeacherDetail_phone_SunhuimeiXia";
import TeacherDetail_phone_HanzhiWang from "./app/pages/teacher/details/phone/TeacherDetail_phone_HanzhiWang";
import TeacherDetail_phone_YutingZheng from "./app/pages/teacher/details/phone/TeacherDetail_phone_YutingZheng";
import TeacherDetail_phone_RenheJiao from "./app/pages/teacher/details/phone/TeacherDetail_phone_RenheJiao";
import TeacherDetail_phone_LiJunlang from "./app/pages/teacher/details/phone/TeacherDetail_phone_LiJunlang";
import TeacherDetail_phone_ZhuoDiao from "./app/pages/teacher/details/phone/TeacherDetail_phone_ZhuoDiao";
import TeacherDetail_phone_LeiGu from "./app/pages/teacher/details/phone/TeacherDetail_phone_LeiGu";
import TeacherDetail_phone_JinYang from "./app/pages/teacher/details/phone/TeacherDetail_phone_JinYang";
import TeacherDetail_phone_DongniXie from "./app/pages/teacher/details/phone/TeacherDetail_phone_DongniXie";
import TeacherDetail_phone_CarolineSackleh from "./app/pages/teacher/details/phone/TeacherDetail_phone_CarolineSackleh";

import Honors_phone from "./app/pages/honors/Honors_phone";
import Roster_phone from "./app/pages/honors/roster/phone/Roster_phone";
import Award_phone from "./app/pages/honors/award/phone/Award_phone";

import News_phone from "./app/pages/news/phone/News_phone";
import NewsDetailPhone1 from "./app/pages/news/detail/phone/NewsDetail-phone-1";
import NewsDetailPhone2 from "./app/pages/news/detail/phone/NewsDetail-phone-2";
import NewsDetailPhone3 from "./app/pages/news/detail/phone/NewsDetail-phone-3";
import NewsDetailPhone4 from "./app/pages/news/detail/phone/NewsDetail-phone-4";
import NewsDetailPhone5 from "./app/pages/news/detail/phone/NewsDetail-phone-5";
import NewsDetailPhone6 from "./app/pages/news/detail/phone/NewsDetail-phone-6";
import NewsDetailPhone7 from "./app/pages/news/detail/phone/NewsDetail-phone-7";
import NewsDetailPhone8 from "./app/pages/news/detail/phone/NewsDetail-phone-8";
import NewsDetailPhone9 from "./app/pages/news/detail/phone/NewsDetail-phone-9";
import NewsDetailPhone10 from "./app/pages/news/detail/phone/NewsDetail-phone-10";
import NewsDetailPhone11 from "./app/pages/news/detail/phone/NewsDetail-phone-11";
import NewsDetailPhone12 from "./app/pages/news/detail/phone/NewsDetail-phone-12";
import NewsDetailPhone13 from "./app/pages/news/detail/phone/NewsDetail-phone-13";

import MusiON_phone from "./app/pages/musiCON/phone/MusiON_phone";
import CompositionCompetition_phone from "./app/pages/musiCON/compositionCompetition/phone/compositionCompetition_phone";

import Contact_phone from "./app/pages/contact/phone/Contact_phone";

import ScrollToTop from "./ScrollToTop";


class App extends Component {
  constructor() {
    super();

    let sUserAgent = navigator.userAgent.toLowerCase();
    let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    let bIsMidp = sUserAgent.match(/midp/i) == "midp";
    let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    let bIsAndroid = sUserAgent.match(/android/i) == "android";
    let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    // console.log("您的浏览设备为：");

    let type;
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      // console.log("phone");
      type = "phone";

      let offWidth = window.screen.width / 20; //这里用宽度/30表示1rem取得的px
      document.getElementsByTagName("html")[0].style.fontSize = offWidth + "px"; //把rem的值复制给顶级标签html的font-size
    } else {
      document.getElementsByTagName("html")[0].style.minWidth = "1903px";
      type = "pc";
      // console.log("pc");
    }

    this.state = {
      type: type,
    };
  }

  starY = 0;
  endY = 0;
  start(e) {
    // this.startY=e.touches[0].clientY;
    this.endY = 0;
  }
  move(e) {
    this.endY = e.touches[0].clientY;
  }
  end(e) {
    if (this.endY != 0) {
      //说明是滑动事件
      e.stopPropagation(); //阻止事件传播,
    }
  }

  render() {
    return (
      <div>
        {this.state.type == "pc" && (
          <div className="App-Main">
            <div className="App-Width">
              <Router onUpdate={() => window.scrollTo(0, 0)}>
                <ScrollToTop>
                  <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/homepage-preview" exact component={Home} />
                    <Route path="/about-us" exact component={About} />
                    <Route path="/music-course" exact component={MusicCourse} />
                    <Route path="/study-abroad" exact component={StudyAbroad} />
                    <Route path="/music-production" exact  component={MusicProduction} />
                    <Route path="/teacher" exact component={Teacher} />
                    <Route path="/roster" exact component={Roster} />
                    <Route path="/award" exact component={Award} />
                    <Route path="/news" exact component={News} />
                    <Route path="/contact" exact component={Contact} />
                    <Route path="/detail-one" exact component={NewsDetail1} />
                    <Route path="/detail-two" exact component={NewsDetail2} />
                    <Route path="/detail-three" exact component={NewsDetail3} />
                    <Route path="/detail-four" exact component={NewsDetail4} />
                    <Route path="/detail-five" exact component={NewsDetail5} />
                    <Route path="/1st-musicon-composition-competition" exact component={NewsDetail6} />
                    <Route path="/detail-seven" exact component={NewsDetail7} />
                    <Route path="/detail-eight" exact component={NewsDetail8} />
                    <Route path="/detail-nine" exact component={NewsDetail9} />
                    <Route path="/detail-ten" exact component={NewsDetail10} />
                    <Route path="/detail-eleven" exact component={NewsDetail11} />
                    <Route path="/2nd-musicon-composition-competition" exact component={NewsDetail12} />
                    <Route path="/2nd-musicon-composition-competition-finalists" exact component={NewsDetail13} />




                    <Route path="/composing" exact component={composing} />
                    <Route path="/theory" exact component={theory} />
                    <Route path="/history" exact component={history} />
                    <Route path="/skills" exact component={skills} />
                    <Route path="/media-composition" exact component={MediaComposition} />
                    <Route path="/guzheng" exact component={guzheng} />
                    <Route path="/violin" exact component={violin} />
                    <Route path="/basson" exact component={basson} />
                    <Route path="/piano" exact component={piano} />
                    <Route path="/pipa" exact component={pipa} />
                    <Route path="/clarinet" exact component={clarinet} />
                    <Route path="/accordion" exact component={accordion} />
                    <Route path="/studyAbroad" exact component={studyAbroad} />
                    <Route path="/music-production" exact component={studyAbroad} />
                    <Route path="/detail-YimingWu" exact component={TeacherDetail_YimingWu} />
                    <Route path="/detail-HaibeiWang" exact component={TeacherDetail_HaibeiWang} />
                    <Route path="/detail-Yikeshan" exact component={TeacherDetail_Yikeshan} />
                    <Route path="/detail-LiJunlang" exact component={TeacherDetail_LiJunlang} />
                    <Route path="/detail-RenheJiao" exact component={TeacherDetail_RenheJiao} />
                    <Route path="/detail-SunhuimeiXia" exact component={TeacherDetail_SunhuimeiXia} />
                    <Route path="/detail-HanzhiWang" exact component={TeacherDetail_HanzhiWang} />
                    <Route path="/detail-YutingZheng" exact component={TeacherDetail_YutingZheng} />
                    <Route path="/detail-JinYang" exact component={TeacherDetail_JinYang} />
                    <Route path="/detail-DongniXie" exact component={TeacherDetail_DongniXie} />
                    <Route path="/detail-ZhuoDiao" exact component={TeacherDetail_ZhuoDiao} />
                    <Route path="/detail-LeiGu" exact component={TeacherDetail_LeiGu} />
                    <Route path="/detail-CarolineSackleh" exact component={TeacherDetail_CarolineSackleh} />
                    <Route path="/detail-BoDai" exact component={TeacherDetail_BoDai} />
                    <Route path="/detail-ShenyingQian" exact component={TeacherDetail_ShenyingQian} />

                    <Route path="/musiCON-International-Composition-Competition" exact component={CompositionCompetition} />

                  </Switch>
                </ScrollToTop>
              </Router>
            </div>
          </div>
        )}
        {this.state.type == "phone" && (
          <div className="App-phone-Main"
            onTouchStartCapture={(e) => { this.start(e); }}
            onTouchMoveCapture={(e) => { this.move(e); }}
            onTouchEndCapture={(e) => this.end(e)} >
            <div className="App-phone-Width">
              <Router onUpdate={() => window.scrollTo(0, 0)}>
                <ScrollToTop>
                  <Switch>
                    <Route path="/" exact component={Home_phone} />
                    <Route path="/navigation-phone" exact component={Navigation_phone} />
                    <Route path="/home-phone" exact component={Home_phone} />
                    <Route path="/about-phone" exact component={About_phone} />
                    <Route path="/project-phone" exact component={Project_phone} />
                    <Route path="/musicCourse-phone" exact component={MusicCourse_phone} />
                    <Route path="/accordion-phone" exact component={Accordion_phone} />
                    <Route path="/basson-phone" exact component={Basson_phone} />
                    <Route path="/clarinet-phone" exact component={Clarinet_phone} />
                    <Route path="/composing-phone" exact component={Composing_phone} />
                    <Route path="/guzheng-phone" exact component={Guzheng_phone} />
                    <Route path="/history-phone" exact component={History_phone} />
                    <Route path="/piano-phone" exact component={Piano_phone} />
                    <Route path="/pipa-phone" exact component={Pipa_phone} />
                    <Route path="/skills-phone" exact component={Skills_phone} />
                    <Route path="/theory-phone" exact component={Theory_phone} />
                    <Route path="/media-composition-phone" exact component={MediaComposition_phone} />
                    <Route path="/volin-phone" exact component={Volin_phone} />
                    <Route path="/musicProduction-phone" exact component={MusicProduction_phone} />
                    <Route path="/studyAbroad-phone" exact component={StudyAbroad_phone} />
                    <Route path="/teacher-phone" exact component={Teacher_phone} />
                    <Route path="/detail-phone-YimingWu" exact component={TeacherDetail_phone_YimingWu} />
                    <Route path="/detail-phone-HaibeiWang" exact component={TeacherDetail_phone_HaibeiWang} />
                    <Route path="/detail-phone-Yikeshan" exact component={TeacherDetail_phone_Yikeshan} />
                    <Route path="/detail-phone-ShenyingQian" exact component={TeacherDetail_phone_ShenyingQian} />
                    <Route path="/detail-phone-BoDai" exact component={TeacherDetail_phone_BoDai} />
                    <Route path="/detail-phone-SunhuimeiXia" exact component={TeacherDetail_phone_SunhuimeiXia} />
                    <Route path="/detail-phone-HanzhiWang" exact component={TeacherDetail_phone_HanzhiWang} />
                    <Route path="/detail-phone-YutingZheng" exact component={TeacherDetail_phone_YutingZheng} />
                    <Route path="/detail-phone-RenheJiao" exact component={TeacherDetail_phone_RenheJiao} />
                    <Route path="/detail-phone-LiJunlang" exact component={TeacherDetail_phone_LiJunlang} />
                    <Route path="/detail-phone-ZhuoDiao" exact component={TeacherDetail_phone_ZhuoDiao} />
                    <Route path="/detail-phone-LeiGu" exact component={TeacherDetail_phone_LeiGu} />
                    <Route path="/detail-phone-JinYang" exact component={TeacherDetail_phone_JinYang} />
                    <Route path="/detail-phone-DongniXie" exact component={TeacherDetail_phone_DongniXie} />
                    <Route path="/detail-phone-CarolineSackleh" exact component={TeacherDetail_phone_CarolineSackleh} />
                    <Route path="/honors-phone" exact component={Honors_phone} />
                    <Route path="/roster-phone" exact component={Roster_phone} />
                    <Route path="/award-phone" exact component={Award_phone} />
                    <Route path="/news-phone" exact component={News_phone} />
                    <Route path="/news-phone-detail-one" exact component={NewsDetailPhone1} />
                    <Route path="/news-phone-detail-two" exact component={NewsDetailPhone2} />
                    <Route path="/news-phone-detail-three" exact component={NewsDetailPhone3} />
                    <Route path="/news-phone-detail-four" exact component={NewsDetailPhone4} />
                    <Route path="/news-phone-detail-five" exact component={NewsDetailPhone5} />
                    <Route path="/1st-musicon-composition-competition" exact component={NewsDetailPhone6} />
                    <Route path="/news-phone-detail-seven" exact component={NewsDetailPhone7} />
                    <Route path="/news-phone-detail-eight" exact component={NewsDetailPhone8} />
                    <Route path="/news-phone-detail-nine" exact component={NewsDetailPhone9} />
                    <Route path="/news-phone-detail-ten" exact component={NewsDetailPhone10} />
                    <Route path="/news-phone-detail-eleven" exact component={NewsDetailPhone11} />
                    <Route path="/2nd-musicon-composition-competition" exact component={NewsDetailPhone12} />
                    <Route path="/2nd-musicon-composition-competition-finalists" exact component={NewsDetailPhone13} />


                    <Route path="/contact-phone" exact component={Contact_phone} />

                    <Route path="/musiON-phone" exact component={MusiON_phone} />
                    <Route path="/musiCON-International-Composition-Competition" exact component={CompositionCompetition_phone} />
                  </Switch>
                </ScrollToTop>
              </Router>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default App;
