import React, {Component} from 'react';
import medal from './img/medal.png'
import './Award.scss'
import line from './img/line.png'
import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import $ from 'jquery'

//作曲奖项
export default class Award extends Component {
    constructor() {
        super();
        this.state = ({
            clickState: true
        })
    }

    awardAcitve() {
        if (this.state.clickState) {
            $("#jiangPai").animate({
                width: "619px",
                height: "926px",
                top: "140px",
                left: "1180px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#pastHonors").animate({
                top: "0px",
                left: '362px'
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#Awards_before").animate({
                'opacity': '0.0'
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#Awards").animate({
                'opacity': '1'
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#rightCircle").animate({
                left: "33px",
                top: "520px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#cards").animate({
                'margin-top': "100px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#line_before").animate({
                'top': '301px',
                'left': '278px'
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#award-bottom").css('cursor', 'default')

            $("#jiangPai").css('cursor', "default")
            $("#Awards_before").css('cursor', "default")

            this.setState({
                clickState: false
            })
        }
    }


    render() {
        return (
            <div>
                <Header></Header>
                <div className="award-bottom" id="award-bottom">

                    <div className="line" src={line}></div>
                    <img className="line-before" src={line} id="line_before"></img>
                    <div className="overlap-group8-13">
                        <img className="jiangPai" src={medal} id="jiangPai" onClick={this.awardAcitve.bind(this)}/>
                        <div className="honors-awards" id="Awards">Awards</div>
                        <div className="honors-awards-before" id="Awards_before"
                             onClick={this.awardAcitve.bind(this)}>Awards
                        </div>
                        <div className="text-274" id="pastHonors">作曲奖项</div>
                    </div>
                    <div className="x16-7"></div>
                    <div className="x14-7" id="rightCircle"></div>
                    {/*往期荣誉----1--------start*/}

                    <div className="x187-13" style={{marginTop: "460px"}} id="cards">
                        <div className="x268"></div>
                        <div className="x185-1">
                            <div className="flex-row-30">
                                <div className="x2-12"></div>
                                <div className="text-2-5 helveticaneue-regular-normal-ship-gray-22px">2024 - 2022</div>
                            </div>
                            <hr className="dateline"/>
                            <div className="conList">
                                {/*<span>Franz Schubert Music Conervatoty</span>*/}
                                {/*<br/>*/}
                                {/*<span>International Composition Award</span>*/}
                                <p>2024 Tribeca Young composer competition, Honorable Mention</p>
                                <p>2023 National Young Composers Challenge, Winner</p>
                                <p>2023 National Young Arts Composition, Winner</p>
                                <p>2023 BMI Student Composer Award, Winner</p>
                                <p>2023 The National Youth Orchestra of the United States of America,<br/> Apprentice composer</p>
                                <p>2023 MTNA Composition Competition, National 2nd place</p>
                                <p>2022 National Young Arts Composition, Honorable Mention</p>
                                <p>2022 BMI student composer award, Honorable Mention</p>
                                <p>2022 Luna Compositon Lab, Winner</p>
                                <p>2022 Moton Gould Young composer Awards, Winner</p>
                                <p>2022 MTNA Composition Competition, National 3rd place</p>
                                <p>2022 Luna Compositon Lab, Honorable Mention</p>
                            </div>
                        </div>
                    </div>
                    {/*往期荣誉----1--------end*/}
                    {/*往期荣誉----2--------start*/}
                    <div className="x187-13">
                        <div className="x268"></div>
                        {/*secondList*/}
                        <div className="x185-1">
                            <div className="flex-row-30">
                                <div className="x2-12"></div>
                                <div className="text-2-5 helveticaneue-regular-normal-ship-gray-22px">2021 - 2019</div>
                            </div>
                            <hr className="dateline"/>
                            <div className="conList">
                                {/*<span>Franz Schubert Music Conervatoty</span>*/}
                                {/*<br/>*/}
                                {/*<span>International Composition Award</span>*/}
                                <p>2021 Moton Gould Young composer Awards, Honorable Mention</p>
                                <p>2021 BMI student composer award, Honorable Mention</p>
                                <p>2021 The American Prize, The Ernst Bacon Memorial Award and Winner</p>
                                <p>2020 The National Youth Orchestra of the United States of America,<br/> Apprentice composer</p>
                                <p>2019 Composition Competition “Sounds of Children’s Rights”, Winner</p>
                                {/*<p>Joanna Qian Mo — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Grace Ma — second classified in Cat.GIOVANI</p>*/}
                                {/*<p>Grace Ma — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Grace Ma — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>David Lu — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Albert LU — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Emily Bai — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Charles Chen — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Nicole Liang — second classified in Cat.GIOVANI</p>*/}
                                {/*<p>LI Yixuan — Third classified in Cat.Cat.GIOVANI</p>*/}
                                {/*<p>Li Qingran — Fourth classified in Cat.A</p>*/}
                                {/*<p>Jenny Wei — Fourth classified in Cat.A</p>*/}
                                {/*<p>Austine Zhang — Fourth classified in Cat.A</p>*/}
                                {/*<p>Cassi Chen — Fourth classified in Cat.A</p>*/}
                                {/*<p>Julia Lu — Third classified in Cat.B</p>*/}
                                {/*<p>Cassi Chen — Third classified in Cat.B</p>*/}
                                {/*<p>Cassi Chen — Second classified in Cat.C</p>*/}
                                {/*<p>Yihong Song — First prize in Cat. A</p>*/}
                                {/*<p>Lucy Chen — First prize in Cat. GIOVANI</p>*/}
                                {/*<p>Nicole Liang — 1st place in Class 4 for Maryland</p>*/}
                            </div>
                        </div>
                    </div>
                    {/*往期荣誉----2--------end*/}
                    {/*往期荣誉----3--------start*/}
                    {/*<div className="x187-13 thirdList">*/}
                    {/*    <div className="x268"></div>*/}
                    {/*    <div className="x185-1">*/}
                    {/*        <div className="flex-row-30">*/}
                    {/*            <div className="x2-12"></div>*/}
                    {/*            <div className="text-2-5 helveticaneue-regular-normal-ship-gray-22px">02 / 2021</div>*/}
                    {/*        </div>*/}
                    {/*        <hr className="dateline"/>*/}
                    {/*        <div className="conList">*/}
                    {/*            <span>2021 Golden Key International Composition</span>*/}
                    {/*            <br/>*/}
                    {/*            <span>Competition</span>*/}
                    {/*            <p>Elementary International —Aria Song —Third —Yiming Wu</p>*/}
                    {/*            <p>Elementary National —Melinda Meihua Liu —First —Meng Wang</p>*/}
                    {/*            <p>Joanna Qian Mo —Third —Yiming Wu</p>*/}
                    {/*            <p>Jenny Yu —Fourth —Yiming Wu</p>*/}
                    {/*            <p>Junior International —Li Yixuan —Fourth —Yiming Wu</p>*/}
                    {/*            <p>Julia Wang Lu —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Junior National —Qingyang Zhang —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Charles Chen —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Albert Lu —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Senior International —David Li —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Yihong Song —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Qingran Li —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Austin Zhang —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Ammy Yuan —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Senior National —David Lu Wang —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Lucy Liu Chen —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Taohan Wang —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Sean Wang —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Cassi Chen —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Ammy Yuan —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Grace Ma —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Andrew Liu —Honorable Mention —Meng Wang</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*往期荣誉----3--------end*/}

                    <div className="x15-10"></div>
                </div>
                <Tail></Tail>
            </div>
        )
    }
}
