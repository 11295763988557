import React, {Component} from "react";

import {withTranslation} from "react-i18next"
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";

import "./NewDetail-phone-1.scss"
import volin from "../../img/violin.png";
import line from "../img/NewsDetailPhoneLine.png";


class NewsDetailPhone3 extends Component {
    constructor() {
        window.scrollTo(0, 0)
        super();
    }

    click_last() {
        this.props.history.push("/news-phone-detail-four")
    }

    click_next() {
        this.props.history.push("/news-phone-detail-two");
    }

    render() {
        const {t} = this.props
        return (
            <div className="news-detail-phone-main">
                <Header_phone></Header_phone>
                <div className="news-detail-phone-content">
                    <div className="date">
                        <div className="icon"></div>
                        <span>03/08/2021</span>
                    </div>
                    <div className="content-detail">
                        <img src={volin} className="picture"/>
                        <div className="detail-text">
                            <div className="text1">{t('news.first_text_title')}</div>
                            <img src={line}/>
                            <div className="text2">
                                {t('news.first_text_detail')}
                                <br/>
                                <br/>
                                {t('news.detail_page_one')}
                            </div>
                        </div>
                    </div>

                    <div className="detail-btn">
                        <div className="detail-btn-last" onTouchEnd={() => this.click_last()}>LAST</div>
                        <div className="detail-btn-next" onTouchEnd={() => this.click_next()}>NEXT</div>
                    </div>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        )
    }
}

export default withTranslation()(NewsDetailPhone3)
