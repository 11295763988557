import Header from "../../../../components/header/Header";
import Tail from "../../../../components/tail/Tail";
import React, {Component} from "react";
import composingImg from '../img/composing.png'
import './detailCommon.scss'
import line from '../img/thin-line.png'
import TeacherWu from '../img/wuyiming.png'
import WangMeng from '../img/wangmeng.png'
import yikeshan from '../img/yikeshan.png'
import {withTranslation} from "react-i18next"

class Composing extends Component {

    linkToClick(val) {
        localStorage.setItem("route", val);
        this.props.history.push("/" + val);
        if (val == "music-course") {
            localStorage.setItem("object_state", "1");
        }
    }

    render() {
        const {t}=this.props
        return (
            <div>
                <Header></Header>
                <div className="composing">
                    <img src={composingImg} className="composingImg"/>
                    <div className="detail_text">
                        <div className="main_title">
                            <div className="top_title">Composing</div>
                            <div className="foot_title">作曲课程</div>
                        </div>
                        <img src={line} alt="" className="thin-line"/>
                        <div className="detail_composing">
                            {t('musicCourse.detail_composing')}
                        </div>
                        <div className="teacher_title" style={localStorage.getItem('Language')=='en'?{marginTop:'188px',letterSpacing:"1px"}:{marginTop:'188px'}}>{t('musicCourse.responsibleTeacher')}</div>

                            <div className="teacher_list">
                                <div className="first_teacher" onClick={this.linkToClick.bind(this, "detail-YimingWu")}>
                                    <img src={TeacherWu} alt="" className="teacher-img"/>
                                    <div className="teacher_detail">
                                        <div className="teacher_name">吴羿明</div>
                                        <div className="teacher_name_en">Yiming Wu</div>
                                    </div>
                                </div>
                                {/*<div className="second-teacher" onClick={this.linkToClick.bind(this, "detail-MengWang")}>*/}
                                {/*    <img src={WangMeng} alt="" className="teacher-img"/>*/}
                                {/*    <div className="teacher_detail">*/}
                                {/*        <div className="teacher_name">王 萌</div>*/}
                                {/*        <div className="teacher_name_en">Meng Wang</div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="second-teacher" onClick={this.linkToClick.bind(this, "detail-Yikeshan")}>
                                    <img src={yikeshan} alt="" className="teacher-img"/>
                                    <div className="teacher_detail">
                                        <div className="teacher_name">依 克 山</div>
                                        <div className="teacher_name_en">YiKeShan</div>
                                    </div>
                                </div>

                            </div>
                        <a href="/music-course" onClick={this.linkToClick.bind(this, "music-course")}>
                            <div className="footer_btn" style={localStorage.getItem('Language')=='en'?{width:'165px'}:{width:'135px'}}>
                                <div className="btn-border"><span>{t('musicCourse.moreCourse')}</span></div>
                            </div>
                        </a>
                    </div>
                </div>
                <Tail></Tail>

            </div>

        )
    }
}
export default withTranslation()(Composing)
