import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './MusiCON.css';
import file_black from './img/file-black.png'
import file_white from './img/file-white.png'
import $ from 'jquery'
import {withTranslation} from "react-i18next"

class Project extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            type: true,
            status: false,
            object_class_state: true,
            news_class_state: true
        })
        if (props.onRef) {//如果父组件传来该方法 则调用方法将子组件this指针传过去
            props.onRef(this)
        }
    }

    componentWillMount() {
        if (localStorage.getItem("musiCON_state") == "1") {
            this.setState({
                type: false,
                status: true
            })
        }
        if (localStorage.getItem("route") == "music-course" || localStorage.getItem("route") == "study-abroad") {
            this.setState({
                object_class_state: false
            })
        }

        if (localStorage.getItem("route") == "news") {
            this.setState({
                object_class_state: false,
                news_class_state: false
            })
        }
        if (localStorage.getItem("route") == "musiCON-International-Composition-Competition") {
            this.setState({
                object_class_state: false,
                news_class_state: false
            })
        }
    }

    changeClassState() {
        this.setState({
            object_class_state: true,
            news_class_state: true
        })
        if (localStorage.getItem("route") == "musiCON-International-Composition-Competition") {
            $("#musiCON_active").css('color', "#968745")
        }

    }

    show() {

        if (this.state.type) {
            this.setState({
                status: true
            })
        }
        if (this.state.news_class_state == false) {
            $("#musiCON_active").css('color', "#ffffff")
        }
    }

    hide() {
        if (this.state.type) {
            this.setState({
                status: false
            })
        }
        if (this.state.news_class_state == false) {
            $("#musiCON_active").css('color', "#333333")
        }
    }

    click(val) {
        localStorage.setItem("route", val)
        localStorage.setItem("musiCON_state", "1")
        this.setState({
            type: false
        })
        this.show()
        if (val != "music-course" && val != "study-abroad" && val != "music-production") {
            localStorage.setItem("object_state", "0")
        }

        if (val != "roster" && val != "award") {
            localStorage.setItem("honors_state", "0")
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <div className="Introdece"
                     onMouseOver={this.show.bind(this)}
                     onMouseLeave={this.hide.bind(this)}
                >
                    <span id="musiCON_active"
                          className={this.state.type ? "cursor-active" : (this.state.news_class_state ? "active" : "active-news")}
                    >
                        {t("header.musiCON.name")}
                    </span>

                    {
                        this.state.status &&
                        <div className='overlap-group7'>
                            <Link to='musiCON-International-Composition-Competition' style={{textDecoration: 'none'}}
                                  onClick={this.click.bind(this, "musiCON-International-Composition-Competition")}>
                                <div className={this.state.object_class_state ?
                                    (localStorage.getItem("Language") == "zh" ? "text-24-zh" : "text-24-en") :
                                    (localStorage.getItem("Language") == "zh" ? "text-24-zh text-white" : "text-24-en text-white")}>
                                    {/*音乐课程*/}

                                    {t("header.musiCON.musiCON_a")}
                                </div>
                            </Link>
                            <Link to='' style={{textDecoration: 'none', pointerEvents: 'none'}}
                                  // onClick={this.click.bind(this, "study-abroad")}
                            >
                                <div className={this.state.object_class_state ?
                                    (localStorage.getItem("Language") == "zh" ? "text-25-zh" : "text-25-en") :
                                    (localStorage.getItem("Language") == "zh" ? "text-25-zh text-white" : "text-25-en text-white")}>
                                    {/*音乐留学*/}
                                    {t("header.musiCON.musiCON_b")}
                                </div>
                            </Link>
                            <Link to='' style={{textDecoration: 'none', pointerEvents: 'none'}}
                                  // onClick={this.click.bind(this, "music-production")}
                            >
                                <div className={this.state.object_class_state ?
                                    (localStorage.getItem("Language") == "zh" ? "text-26-zh" : "text-26-en") :
                                    (localStorage.getItem("Language") == "zh" ? "text-26-zh text-white" : "text-26-en text-white")}>
                                    {/*音乐制作*/}
                                    {t("header.musiCON.musiCON_c")}
                                </div>
                            </Link>
                            <img className={this.state.object_class_state ? "file-black" : "file-white"}
                                 src={this.state.object_class_state ? file_black : file_white}/>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withTranslation()(Project);
