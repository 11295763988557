import React, {Component} from "react";
import './detailPhoneCommon.scss'
import line from '../../img/thin-line.png'
import xiedongni from "../../img/谢冬妮.png"
import jiaorenhe from "../../img/焦人和.png"
import pianoImg from "./img/pianoImg.png"
import {withTranslation} from "react-i18next"
import xuyifei from "../../img/许艺菲.png";
import Header_phone from "../../../../../components/header/Header_phone";
import Tail_phone from "../../../../../components/tail/Tail_phone";

 class Piano_phone extends Component {

     linkToClick(val) {
         this.props.history.push("/" + val);
     }

    render() {
        const {t} = this.props
        return (
            <div>
                <Header_phone></Header_phone>
                <div className="group">
                    <div className="title">
                        <div className="text1">
                            Piano
                        </div>
                        <div className="text2" style={{marginLeft: "3.6rem"}}>
                            钢琴课程
                        </div>
                    </div>
                    <img className="thin-line" src={line}/>

                    <img className="right-img" src={pianoImg}/>
                    <div className="group-detail"
                         style={localStorage.getItem('Language') == 'en' ? {letterSpacing: "0rem"} : {letterSpacing: "0.05rem"}}>
                        {t('musicCourse.piano_detail_first')}
                        <br/><br/>
                        <span>{t('musicCourse.violin_first_span')}</span>
                        {t('musicCourse.piano_detail_second')}
                        <br/><br/>
                        <span>{t('musicCourse.piano_second_span')}</span>
                        {t('musicCourse.piano_detail_third')}
                    </div>
                    <div className="teacher-title">{t('musicCourse.responsibleTeacher')}</div>
                    <div className="teacher-list">
                        <div onTouchEnd={this.linkToClick.bind(this, "detail-phone-DongniXie")}>
                            <img src={xiedongni} className="teacher-img"/>
                            <div className="teacher-text">
                                <div className="teacher-name">谢冬妮</div>
                                <div className="teacher-name-en">Dongni Xie</div>
                            </div>
                        </div>
                        {/*<div className="many" onTouchEnd={this.linkToClick.bind(this, "detail-phone-YifeiXu")}>*/}
                        {/*    <img src={xuyifei} className="teacher-img"/>*/}
                        {/*    <div className="teacher-text">*/}
                        {/*        <div className="teacher-name">许艺菲</div>*/}
                        {/*        <div className="teacher-name-en">Yifei Xu</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="many" onTouchEnd={this.linkToClick.bind(this, "detail-phone-RenheJiao")}>
                            <img src={jiaorenhe} className="teacher-img"/>
                            <div className="teacher-text">
                                <div className="teacher-name">焦人和</div>
                                <div className="teacher-name-en">Renhe Jiao</div>
                            </div>
                        </div>
                    </div>

                    <a href={"musicCourse-phone"}>
                        <div className="btn-phone-border">
                            {t('musicCourse.moreCourse')}
                        </div>
                    </a>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        )
    }
}
export default withTranslation()(Piano_phone)
