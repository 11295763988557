import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import React, {Component} from "react";
import './MusicCourse.scss'
import courseImg from './img/course.png'
import ground from './img/ground.png'
import line from './img/line.png'
import longLine from './img/long-line.png'
import $ from 'jquery'
import 'jquery.easing'
import {withTranslation} from "react-i18next"

class MusicCourse extends Component {
    constructor() {
        super();
        this.state = ({
            clickState: true
        })
    }

    clickActive() {
        if (this.state.clickState) {
            $("#courseImg").animate({
                top: '0',
                left: '0',
                width: "501px",
                height: "316px",
                "z-indx": "100",
                "cursor": "unset"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#word").animate({
                top: '0',
                left: '0',
                fontSize: "60px",
                letterSpacing: "3px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#line-course").animate({
                marginRight: "173.16px",
                marginTop: "231px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#course-ground").animate({
                height: "316px",
                width: "500px",
                left: "40px",
                top: "36px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#word-sp").animate({
                left: "240px",
                top: "75px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#rightCircle").animate({
                left: "1346px",
                top: "509px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#course-ground").css('cursor', "default")
            $("#courseImg").css('cursor', "default")
            $("#word").css('cursor', "default")

            this.changeHeader();
            this.setState({
                clickState: false
            })

        }
    }

    changeHeader() {
        this.ChildPage.changeObject();
    }

    linkToClick(val) {
        localStorage.setItem("route", val);
        localStorage.setItem("object_state", "0")
        localStorage.setItem("honors_state", "0")
        this.props.history.push("/" + val);
    }

    render() {
        const {t} = this.props;
        return (
            <div className="music">
                <Header onRef={ref => this.ChildPage = ref}></Header>
                <div className="MusicCourse" style={{height: '2624px'}}>
                    <div className="flex-row">
                        <div className="left-middle-circle"></div>
                        <div className="flex-col">
                            <img className="line-course" src={line} id="line-course"/>
                            <div className="title">
                                <div className="word" id="word" style={{top: "220px", left: "800px"}}
                                     onClick={this.clickActive.bind(this)}>Music Courses:
                                </div>
                                <div className="word-sp" id="word-sp">音乐课程</div>
                            </div>
                        </div>
                        <div className="small-circle-course">
                            <div className="circleDiv"></div>
                        </div>
                        <div className="right-img">
                            <div className="right-middle-circle" style={{top: "600px"}} id="rightCircle"></div>
                            <div className="right-course-img">
                                <img src={courseImg}
                                     className="course-img-before"
                                     id="courseImg" onClick={this.clickActive.bind(this)}/>
                                <img src={ground} className="course-ground" id="course-ground"
                                     onClick={this.clickActive.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="course-split"
                         style={localStorage.getItem('Language') == 'en' ? {letterSpacing: '1px'} : {}}>{t('musicCourse.classification')}</div>
                    <div className="point"></div>
                    <img src={longLine} className="longLine"/>
                    <a id="composing" onClick={this.linkToClick.bind(this, "composing")}>
                        <div className="x1">
                            <div className="x1-1">
                                <div className="composing">
                                    <div className="composing-text">
                                        <div className="composing-text-1">作曲</div>
                                        <div className="composing-text-2">Composing</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                    <div className="flex-col-5">
                        <a onClick={this.linkToClick.bind(this, "skills")}>
                            <div className="x101">
                                <div className="overlap-group-5">
                                    <div className="circle-title">视唱练耳</div>
                                    <div className="circle-text">Aural Skills</div>
                                </div>
                            </div>
                        </a>
                        <a onClick={this.linkToClick.bind(this, "violin")}>
                            <div className="circle-3-1">
                                <div className="violin-title">中提琴/<br/>小提琴</div>
                                <div className="violin-text">Viola & Violin</div>
                            </div>
                        </a>
                        <a onClick={this.linkToClick.bind(this, "pipa")}>
                            <div className="circle-4-1">
                                <div className="circle-title">琵琶</div>
                                <div className="circle-text">Pipa</div>
                            </div>
                        </a>
                    </div>
                    <div className="flex-4-2">
                        <a onClick={this.linkToClick.bind(this, "theory")}>
                            <div className="circle-1-2">
                                <div className="circle-title">音乐理论</div>
                                <div className="circle-text">Music Theory</div>
                            </div>
                        </a>
                        <a onClick={this.linkToClick.bind(this, "media-composition")}>
                            <div className="circle-2-2">
                                <div className="circle-title">电子音乐</div>
                                <div className="circle-text">Media Composition</div>
                            </div>
                        </a>
                        <a onClick={this.linkToClick.bind(this, "basson")}>
                            <div className="circle-2-3">
                                <div className="circle-title">巴&nbsp;松</div>
                                <div className="circle-text">Bassoon</div>
                            </div>
                        </a>
                        <a onClick={this.linkToClick.bind(this, "clarinet")}>
                            <div className="circle-2-4">
                                <div className="circle-title">单簧管</div>
                                <div className="circle-text">Clarinet</div>
                            </div>
                        </a>
                    </div>
                    <div className="flex-4-3">
                        <a onClick={this.linkToClick.bind(this, "history")}>
                            <div className="circle-1-3">
                                <div className="circle-title">音乐素养</div>
                                <div className="circle-text">Musicianship</div>
                            </div>
                        </a>
                        <a onClick={this.linkToClick.bind(this, "guzheng")}>
                            <div className="guZheng">
                                <div className="circle-title">古筝</div>
                                <div className="circle-text">Guzheng</div>
                            </div>
                        </a>
                        <a onClick={this.linkToClick.bind(this, "piano")}>
                            <div className="piano">
                                <div className="circle-title">钢琴</div>
                                <div className="circle-text">Piano</div>
                            </div>
                        </a>
                        <a onClick={this.linkToClick.bind(this, "accordion")}>
                            <div className="accordion">
                                <div className="circle-title">古典手风琴</div>
                                <div className="circle-text">Classical Accordion</div>
                            </div>
                        </a>
                    </div>
                </div>
                <Tail></Tail>
            </div>
        )
    }

}

export default withTranslation()(MusicCourse);
