import React, { Component } from "react";

import "./Contact_phone.scss"
import { withTranslation } from "react-i18next";
import Header_phone from "../../../components/header/Header_phone";
import line from "../../projectDescription/img/line.png";
import contact_line from "../img/contact-line.png"
import note from "../img/note.png";
import QRcode1 from "../img/QRcode1.png";
import QRcode2 from "../img/QRcode2.png";
import contact_email from "../img/contact-email.png"
import icon_phone_1 from "../../../components/homeTail/img/icon_phone_1.png";
import icon_phone_2 from "../../../components/homeTail/img/icon_phone_2.png";
import icon_phone_3 from "../../../components/homeTail/img/icon_phone_3.png";
import icon_phone_4 from "../../../components/homeTail/img/icon_phone_4.png";
import scenery from "../img/scenery.png"

//联系我们
class Contact_phone extends Component {

    render() {
        const { t } = this.props;
        return (
            <div className="contact-phone-main">
                <Header_phone></Header_phone>
                <div className="max-circle"></div>
                <img src={scenery} className="scenery" />
                <div className="contact-phone-content">
                    <div className="contact-title">
                        <img src={line} />
                        <div className="text1">
                            Contact Us
                        </div>
                        <div className="text2">
                            联系我们
                        </div>
                    </div>

                    <div className="contact-content">
                        <div className="paragraph1">
                            <img src={note} className="note" />
                            <div>
                                <div className="text1">MAYFLOWER ART CENTER</div>
                                <div className="text2">美好艺术中心</div>
                            </div>
                        </div>
                        <img src={contact_line} className="contact-line" />
                        <div className="paragraph2">
                            <div className="item1">
                                <div className="item1-small-circle"></div>
                                <div className="text">{t("contact.describe_text1")}</div>
                            </div>
                            <div className="detail">{t("contact.describe_text2")}</div>
                        </div>
                        <div className="paragraph2">
                            <div className="item1">
                                <div className="item1-small-circle"></div>
                                <div className="text">{t("contact.describe_text3")}</div>
                            </div>
                            <div className="detail">
                                {t("contact.describe_text4_1")? <span><span>{t("contact.describe_text4_1")}</span><br/></span>: ''}
                                {t("contact.describe_text4_2")? <span><span>{t("contact.describe_text4_2")}</span><br/></span>: ''}
                                <span>{t("contact.describe_text4_3")}</span>
                            </div>
                        </div>
                        <div className="paragraph2">
                            <div className="item1">
                                <div className="item1-small-circle"></div>
                                <div className="text">{t("contact.describe_text5")}</div>
                            </div>
                            <div className="detail">
                                {t("contact.describe_text6")}
                            </div>
                        </div>
                        <div className="paragraph2">
                            <div className="item1">
                                <div className="item1-small-circle"></div>
                                <div className="text">{t("contact.describe_text7")}</div>
                            </div>
                            <div className="detail">
                                {t("contact.describe_text8")}
                            </div>
                        </div>
                        <div className="paragraph3">
                            <div className="item1">
                                <div className="item1-small-circle"></div>
                                <div className="text">{t("contact.describe_text9")}</div>
                            </div>
                            <div className="detail">
                                {/*<div className="QRcode1">*/}
                                {/*    <img src={QRcode1} />*/}
                                {/*    <div> {t("home.contact.course_consultation")}</div>*/}
                                {/*</div>*/}
                                <div className="QRcode1">
                                    <img src={QRcode2} />
                                    {/*<div> {t("home.contact.study_abroad_consultation")}</div>*/}
                                </div>
                            </div>
                        </div>

                        {/*<img src={contact_email} className="contact-email" />*/}
                    </div>
                </div>
                <div className="Tail">
                    <div className="div-img">
                        <img src={icon_phone_1} />
                        <img src={icon_phone_2} />
                        <img src={icon_phone_3} />
                        <img src={icon_phone_4} />
                    </div>
                    <div className="text">
                        © MAYFLOWER ART CENTER 2021. All Rights Reserved.
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Contact_phone);


