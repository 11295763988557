import React, { Component } from "react";
import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import style from './NewsDetail-5.module.scss';
import news_detail_5_1 from '../img/news_detail_5_1.png';
import news_detail_5_2 from './img/news_detail_5_2.png';
import news_detail_5_3 from './img/news_detail_5_3.png';

import { withTranslation } from "react-i18next"

class NewsDetail4 extends Component {
    constructor() {
        window.scrollTo(0, 0)
        super();
    }

    changeLast() {
        this.props.history.push("/detail-five")
    }

    changeNext() {
        this.props.history.push("/detail-three")
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Header></Header>

                <div className={style.leftCircle}></div>
                <div className={style.rightCircle}></div>

                <div className={style.main}>
                    <div className={style.top_date}>
                        <div></div>
                        <div>07/11/2022</div>
                    </div>
                    <div className={style.mbody}>
                        <div className={style.top_text}>
                            <span>{t('news.first_title_July_27_2022')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.first_July_27_2022_1')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.first_July_27_2022_2')}</span>
                        </div>
                        <img src={news_detail_5_1} />
                        <div className={style.text_center_red}>
                            <span>{t('news.first_July_27_2022_3')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.first_July_27_2022_4')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.first_July_27_2022_5')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.first_July_27_2022_6')}</span>
                        </div>
                        <img src={news_detail_5_2} />
                        <div className={style.text_center_red}>
                            <span>{t('news.first_July_27_2022_7')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.first_July_27_2022_8')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.first_July_27_2022_9')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.first_July_27_2022_10')}</span>
                        </div>
                        <img src={news_detail_5_3} />
                        <div className={style.text_center_red}>
                            <span>{t('news.first_July_27_2022_11')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.first_July_27_2022_12')}</span>
                        </div>
                    </div>
                </div>

                <div className={style.footer}>
                    <div onClick={this.changeLast.bind(this)}>LAST</div>
                    <div onClick={this.changeNext.bind(this)}>NEXT</div>
                </div>

                <div className={style.detailFooter}>
                    <Tail></Tail>
                </div>
            </div>
        )
    }
}
export default withTranslation()(NewsDetail4)
