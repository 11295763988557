import React, {Component} from "react"

import {withTranslation} from "react-i18next";
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import line from "../../../projectDescription/img/line.png";

import "./Award_phone.scss"
import crown from "../../img/crown.png";
import medal from "../img/medal.png"

class Award_phone extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className="award-phone-main">
                <Header_phone></Header_phone>
                <div className="award-phone-content">
                    <div className="award-title">
                        <img src={line}/>
                        <div className="text1">
                            Awards：
                        </div>
                        <div className="text2">
                            作曲奖项
                        </div>
                    </div>

                    <div className="circular"></div>
                    <div className="cards" style={{marginTop: "19rem"}}>
                        <div className="left"></div>
                        <div className="right">
                            <div className="right-title">
                                <div className="right-circular"></div>
                                <div className="school-year">
                                    2024 - 2022
                                </div>
                            </div>
                            <hr className="dateline"/>
                            <div className="conList">
                                {/*<span>Franz Schubert Music Conervatoty</span>*/}
                                {/*<br/>*/}
                                {/*<span>International Composition Award</span>*/}
                                <p>2024 Tribeca Young composer competition, Honorable Mention</p>
                                <p>2023 National Young Composers Challenge, Winner</p>
                                <p>2023 National Young Arts Composition, Winner</p>
                                <p>2023 BMI Student Composer Award, Winner</p>
                                <p>2023 The National Youth Orchestra of the United States of America, Apprentice composer</p>
                                <p>2023 MTNA Composition Competition, National 2nd place</p>
                                <p>2022 National Young Arts Composition, Honorable Mention</p>
                                <p>2022 BMI student composer award, Honorable Mention</p>
                                <p>2022 Luna Compositon Lab, Winner</p>
                                <p>2022 Moton Gould Young composer Awards, Winner</p>
                                <p>2022 MTNA Composition Competition, National 3rd place</p>
                                <p>2022 Luna Compositon Lab, Honorable Mention</p>
                            </div>
                        </div>
                    </div>
                    <div className="cards" style={{marginTop: "2rem"}}>
                        <div className="left"></div>
                        <div className="right">
                            <div className="right-title">
                                <div className="right-circular"></div>
                                <div className="school-year">
                                    2021 - 2019
                                </div>
                            </div>
                            <hr className="dateline"/>
                            <div className="conList">
                                {/*<span>Franz Schubert Music Conervatoty</span>*/}
                                {/*<br/>*/}
                                {/*<span>International Composition Award</span>*/}
                                <p>2021 Moton Gould Young composer Awards, Honorable Mention</p>
                                <p>2021 BMI student composer award, Honorable Mention</p>
                                <p>2021 The American Prize, The Ernst Bacon Memorial Award and Winner</p>
                                <p>2020 The National Youth Orchestra of the United States of America,<br/> Apprentice composer</p>
                                <p>2019 Composition Competition “Sounds of Children’s Rights”, Winner</p>


                                {/*<p>Qingyang Zhang — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Jenny Yu — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Taohan Wang — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Aria Song — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Christopher Pan — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Joanna Qian Mo — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Grace Ma — second classified in Cat.GIOVANI</p>*/}
                                {/*<p>Grace Ma — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Grace Ma — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>David Lu — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Albert LU — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Emily Bai — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Charles Chen — Third classified in Cat.GIOVANI</p>*/}
                                {/*<p>Nicole Liang — second classified in Cat.GIOVANI</p>*/}
                                {/*<p>LI Yixuan — Third classified in Cat.Cat.GIOVANI</p>*/}
                                {/*<p>Li Qingran — Fourth classified in Cat.A</p>*/}
                                {/*<p>Jenny Wei — Fourth classified in Cat.A</p>*/}
                                {/*<p>Austine Zhang — Fourth classified in Cat.A</p>*/}
                                {/*<p>Cassi Chen — Fourth classified in Cat.A</p>*/}
                                {/*<p>Julia Lu — Third classified in Cat.B</p>*/}
                                {/*<p>Cassi Chen — Third classified in Cat.B</p>*/}
                                {/*<p>Cassi Chen — Second classified in Cat.C</p>*/}
                                {/*<p>Yihong Song — First prize in Cat. A</p>*/}
                                {/*<p>Lucy Chen — First prize in Cat. GIOVANI</p>*/}
                                {/*<p>Nicole Liang — 1st place in Class 4 for Maryland</p>*/}
                            </div>
                        </div>
                    </div>
                    {/*<div className="cards" style={{marginTop: "2rem"}}>*/}
                    {/*    <div className="left"></div>*/}
                    {/*    <div className="right">*/}
                    {/*        <div className="right-title">*/}
                    {/*            <div className="right-circular"></div>*/}
                    {/*            <div className="school-year">*/}
                    {/*                02 / 2021*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <hr className="dateline"/>*/}
                    {/*        <div className="conList">*/}
                    {/*            <span>2021 Golden Key International</span>*/}
                    {/*            <br/>*/}
                    {/*            <span>Composition Competition</span>*/}
                    {/*            <p>Elementary International —Aria Song —Third —Yiming Wu</p>*/}
                    {/*            <p>Elementary National —Melinda Meihua Liu —First —Meng Wang</p>*/}
                    {/*            <p>Joanna Qian Mo —Third —Yiming Wu</p>*/}
                    {/*            <p>Jenny Yu —Fourth —Yiming Wu</p>*/}
                    {/*            <p>Junior International —Li Yixuan —Fourth —Yiming Wu</p>*/}
                    {/*            <p>Julia Wang Lu —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Junior National —Qingyang Zhang —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Charles Chen —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Albert Lu —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Senior International —David Li —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Yihong Song —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Qingran Li —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Austin Zhang —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Ammy Yuan —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Senior National —David Lu Wang —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Lucy Liu Chen —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Taohan Wang —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Sean Wang —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Cassi Chen —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Ammy Yuan —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Grace Ma —Honorable Mention —Yiming Wu</p>*/}
                    {/*            <p>Andrew Liu —Honorable Mention —Meng Wang</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <img src={medal} className="award-img"/>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        );
    }

}

export default withTranslation()(Award_phone)
