//师资力量
import React, {Component} from "react";
import "./Teacher.scss"
import Header from "../../components/header/Header";
import Tail from "../../components/tail/Tail";
import blackboard from './img/blackboard.png'
import yellowBackground from './img/yellowBackground.png'
import xiasunhuimei from './img/xiasunhuimei.jpg'
import CarolineSackleh from './img/CarolineSackleh.png'
import diaozhuo from './img/diaozhuo.png'
import gulei from './img/gulei.png'
import jiaorenhe from './img/jiaorenhe.png'
import wanghanzhi from './img/wanghanzhi.png'
import wanghaibei from './img/wanghaibei.png'
import wuyiming from './img/wuyiming.png'
import xiedongni from './img/xiedongni.png'
import lijunlang from './img/lijunlang.png'
import yangjin from './img/yangjin.png'
import yikeshan from './img/yikeshan.png'
import zhengyuting from './img/zhengyuting.png'
import line from './img/line.png'
import line1 from './img/line1.png'
import rectangle1 from './img/rectangle1.png'
import rectangle2 from './img/rectangle2.png'
import daibo from './img/daibo.jpg'
import qianshenying from './img/qianshenying.jpg'
import $ from 'jquery'
import {withTranslation} from "react-i18next";

class Teacher extends Component {
    constructor(prop) {
        super(prop);
        this.state = ({
            clickState: true
        })
    }

    teacherClick() {
        if (this.state.clickState) {
            $("#blackboard").animate({
                width: "501px",
                height: "316px",
                top: "320px",
                left: "1122px"
            }, 1000)
            $("#yellowBackground").animate({
                width: "500px",
                height: "316px",
                top: "165px",
                left: "1160px"
            }, 1000)
            $("#line1").animate({
                marginTop: "230px"
            }, 1000)
            $("#faculties").animate({
                marginTop: "120px",
                marginLeft: "120px",
                fontSize: "60px",
            }, 1000)
            $("#faculties_span").animate({
                letterSpacing: "0px",
            }, 1000)


            $("#circular1").animate({
                marginTop: "240px",
                marginLeft: "1240px"
            }, 1000)
            $("#text1").animate({
                marginTop: "-260px",
                marginLeft: "280px"
            }, 1000)
            $("#paragraph").animate({
                top: "100px",
                left: "140px"
            }, 1000)
            $("#gray-circle1").animate({
                top: "490px",
                right: "125px"
            }, 1000)
            $("#gray-circle2").animate({
                top: "650px",
                left: "750px"
            }, 1000)


            $("#blackboard").css('cursor', "default")
            $("#yellowBackground").css('cursor', "default")
            $("#faculties_span").css('cursor', "default")

            this.setState({
                clickState: false
            })
        }
    }

    jumpDetail(index) {
        switch (index) {
            case 1:
                localStorage.setItem("route", "detail-YimingWu");
                console.log(localStorage.getItem("route"))
                this.props.history.push("/detail-YimingWu");
                break;
            case 2:
                localStorage.setItem("route", "detail-HaibeiWang");
                this.props.history.push("/detail-HaibeiWang");
                break;
            case 3:
                localStorage.setItem("route", "detail-Yikeshan");
                this.props.history.push("/detail-Yikeshan");
                break;
            case 4:
                localStorage.setItem("route", "detail-LiJunlang");
                this.props.history.push("/detail-LiJunlang");
                break;
            case 5:
                localStorage.setItem("route", "detail-RenheJiao");
                this.props.history.push("/detail-RenheJiao");
                break;
            case 6:
                localStorage.setItem("route", "detail-SunhuimeiXia");
                this.props.history.push("/detail-SunhuimeiXia");
                break;
            case 7:
                localStorage.setItem("route", "detail-HanzhiWang");
                this.props.history.push("/detail-HanzhiWang");
                break;
            case 8:
                localStorage.setItem("route", "detail-YutingZheng");
                this.props.history.push("/detail-YutingZheng");
                break;
            case 9:
                localStorage.setItem("route", "detail-JinYang");
                this.props.history.push("/detail-JinYang");
                break;
            case 10:
                localStorage.setItem("route", "detail-DongniXie");
                this.props.history.push("/detail-DongniXie");
                break;
            case 11:
                localStorage.setItem("route", "detail-ZhuoDiao");
                this.props.history.push("/detail-ZhuoDiao");
                break;
            case 12:
                localStorage.setItem("route", "detail-LeiGu");
                this.props.history.push("/detail-LeiGu");
                break;
            case 13:
                localStorage.setItem("route", "detail-CarolineSackleh");
                this.props.history.push("/detail-CarolineSackleh");
                break;
            case 14:
                localStorage.setItem("route", "detail-BoDai");
                this.props.history.push("/detail-BoDai");
                break;
            case 15:
                localStorage.setItem("route", "detail-ShenyingQian");
                this.props.history.push("/detail-ShenyingQian");
                break;

        }

    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Header></Header>
                <div className="teacher" id="teacher">
                    <div className="teacher-top">
                        <div className="gray-circle1" id="gray-circle1"></div>

                        <div className="gray-circle2" id="gray-circle2"></div>
                        <div className="paragraph" id="paragraph">
                            <img className="line1" id="line1" src={line1}/>
                            <div className="faculties" id="faculties">
                                <span id="faculties_span" onClick={this.teacherClick.bind(this)}>Faculty<span
                                    className="span-text"> ：</span></span>
                            </div>
                            <div className="circular1" id="circular1"></div>
                            <div className="text1" id="text1">师资力量</div>
                        </div>
                        <div>
                            <img className="blackboard" id="blackboard" onClick={this.teacherClick.bind(this)}
                                 src={blackboard}/>
                            <img className="yellowBackground" id="yellowBackground"
                                 onClick={this.teacherClick.bind(this)}
                                 src={yellowBackground}/>
                        </div>
                    </div>
                    <div className="teacher-main" id="teacher-main">
                        <div className="paragraph1">
                            <div className="paragraph1-item">
                                <div className="circular2"></div>
                                <div className={localStorage.getItem("Language") == "zh" ? "text2_zh":"text2_en"}>{t("teacher_details.text")}</div>
                            </div>
                            <img className="line2" src={line}/>
                            <div className="gray-circle3"></div>
                        </div>
                        <div className="paragraph2">
                            <div className="row1-picture1">
                                <img className="rectangle1" src={rectangle1}/>
                                <img className="wuyiming" src={wuyiming} onClick={this.jumpDetail.bind(this, 1)}/>
                                <div className="text3" id="text3">吴羿明</div>
                                <div className="text4" id="text4">Yiming Wu</div>
                                <div className="text7" id="text7" style={{whiteSpace: 'pre-wrap'}}>
                                    {t("teacher.describe_1")}
                                </div>
                            </div>
                            <div className="row1-picture2">
                                <img className="rectangle1" src={rectangle1}/>
                                <img className="wanghaibei" src={wanghaibei} onClick={this.jumpDetail.bind(this, 2)}/>
                                <div className="text3" id="text3">汪海蓓</div>
                                <div className="text4" id="text4">Haibei Wang</div>
                                <div className="text7" id="text7">
                                    {t("teacher.describe_2")}
                                </div>
                            </div>
                            <div className="row1-picture3">
                                <img className="rectangle1" src={rectangle1}/>
                                <img className="yikeshan" src={yikeshan} onClick={this.jumpDetail.bind(this, 3)}/>
                                <div className="text3" id="text3">依克山</div>
                                <div className="text4" id="text4">Yikeshan</div>
                                <div className="text7" id="text7">
                                    {t("teacher.describe_3")}
                                </div>
                            </div>
                        </div>
                        <div className="circular3"></div>
                        <div className="paragraph3">
                            <div className="row2-picture1">
                                <img className="rectangle2" src={rectangle2}/>
                                <img className="common" src={qianshenying} onClick={this.jumpDetail.bind(this, 15)}/>
                                <div className="text5" id="text5">钱慎瀛</div>
                                <div className="text6" id="text6">Shenying Qian</div>
                                <div className="text8">
                                    {t("teacher.describe_4")}
                                </div>
                            </div>
                            <div className="row2-picture2">
                                <img className="rectangle2" src={rectangle2}/>
                                <img className="common" src={daibo} onClick={this.jumpDetail.bind(this, 14)}/>
                                <div className="text5" id="text5">代博</div>
                                <div className="text6" id="text6">Bo Dai</div>
                                <div className="text8">
                                    {t("teacher.describe_5")}
                                </div>
                            </div>
                            <div className="row2-picture3">
                                <img className="rectangle2" src={rectangle2}/>
                                <img className="common" src={xiasunhuimei} onClick={this.jumpDetail.bind(this, 6)}/>
                                <div className="text5" id="text5">夏孙惠美</div>
                                <div className="text6" id="text6">Sunhuimei Xia</div>
                                <div className="text8">
                                    {t("teacher.describe_6")}
                                </div>
                            </div>
                            <div className="row2-picture4">
                                <img className="rectangle2" src={rectangle2}/>
                                <img className="common" src={wanghanzhi} onClick={this.jumpDetail.bind(this, 7)}/>
                                <div className="text5" id="text5">王寒之</div>
                                <div className="text6" id="text6">Hanzhi Wang</div>
                                <div className="text8" style={{whiteSpace: 'pre-wrap'}}>
                                    {t("teacher.describe_7")}
                                </div>
                            </div>

                        </div>
                        <div className="paragraph4">
                            <div className="row3-picture1">
                                <img className="rectangle2" src={rectangle2}/>
                                <img className="common" src={zhengyuting} onClick={this.jumpDetail.bind(this, 8)}/>
                                <div className="text5" id="text5">郑育婷</div>
                                <div className="text6" id="text6">Yuting Zheng</div>
                                <div className="text9" style={{whiteSpace: 'pre-wrap'}}>
                                    {t("teacher.describe_8")}
                                </div>
                            </div>
                            <div className="row3-picture2">
                                <img className="rectangle2" src={rectangle2}/>
                                <img className="common" src={jiaorenhe} onClick={this.jumpDetail.bind(this, 5)}/>
                                <div className="text5" id="text5">焦人和</div>
                                <div className="text6" id="text6">Renhe Jiao</div>
                                <div className="text9" style={{whiteSpace: 'pre-wrap'}}>
                                    {t("teacher.describe_9")}
                                </div>
                            </div>
                            {/*<div className="row3-picture3">*/}
                            {/*    <img className="rectangle2" src={rectangle2}/>*/}
                            {/*    <img className="common" src={lijunlang} onClick={this.jumpDetail.bind(this, 4)}/>*/}
                            {/*    <div className="text5" id="text5">李俊朗</div>*/}
                            {/*    <div className="text6" id="text6">Li Junlang</div>*/}
                            {/*    <div className="text9" style={{whiteSpace: 'pre-wrap'}}>*/}
                            {/*        {t("teacher.describe_10")}*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="row3-picture4">
                                <img className="rectangle2" src={rectangle2}/>
                                <img className="common" src={diaozhuo} onClick={this.jumpDetail.bind(this, 11)}/>
                                <div className="text5" id="text5">刁卓</div>
                                <div className="text6" id="text6">Zhuo Diao</div>
                                <div className="text9" style={{whiteSpace: 'pre-wrap'}}>
                                    {t("teacher.describe_11")}
                                </div>
                            </div>
                            <div className="row3-picture5">
                                <img className="rectangle2" src={rectangle2}/>
                                <img className="common" src={gulei} onClick={this.jumpDetail.bind(this, 12)}/>
                                <div className="text5" id="text5">顾磊</div>
                                <div className="text6" id="text6">Lei Gu</div>
                                <div className="text10" style={{whiteSpace: 'pre-wrap'}}>
                                    {t("teacher.describe_12")}
                                </div>
                            </div>


                        </div>
                        <div className="paragraph5">
                            {/*<div className="row4-picture1">*/}
                            {/*    <img className="rectangle2" src={rectangle2}/>*/}
                            {/*    <img className="common" src={gulei} onClick={this.jumpDetail.bind(this, 12)}/>*/}
                            {/*    <div className="text5" id="text5">顾磊</div>*/}
                            {/*    <div className="text6" id="text6">Lei Gu</div>*/}
                            {/*    <div className="text10" style={{whiteSpace: 'pre-wrap'}}>*/}
                            {/*        {t("teacher.describe_12")}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="row4-picture2">
                                <img className="rectangle2" src={rectangle2}/>
                                <img className="common" src={yangjin} onClick={this.jumpDetail.bind(this, 9)}/>
                                <div className="text5" id="text5">杨瑾</div>
                                <div className="text6" id="text6">Jin Yang</div>
                                <div className="text10" style={{whiteSpace: 'pre-wrap'}}>
                                    {t("teacher.describe_13")}
                                </div>
                            </div>
                            <div className="row4-picture3">
                                <img className="rectangle2" src={rectangle2}/>
                                <img className="common" src={xiedongni} onClick={this.jumpDetail.bind(this, 10)}/>
                                <div className="text5" id="text5">谢冬妮</div>
                                <div className="text6" id="text6">Dongni Xie</div>
                                <div className="text10" style={{whiteSpace: 'pre-wrap'}}>
                                    {t("teacher.describe_14")}
                                </div>
                            </div>
                            <div className="row4-picture4">
                                <img className="rectangle2" src={rectangle2}/>
                                <img className="common" src={CarolineSackleh} onClick={this.jumpDetail.bind(this, 13)}/>
                                <div className="text5" id="text5">Caroline<br/>Sackleh</div>
                                <div className="text10">
                                    {t("teacher.describe_15")}
                                </div>
                            </div>
                        </div>
                        <div className="circular456">
                            {/*<div className="circular4"></div>*/}
                            <div className="circular5"></div>
                            <div className="circular6"></div>
                        </div>
                    </div>
                </div>
                <Tail></Tail>
            </div>

        );
    }
}

export default withTranslation()(Teacher);
