import React, {Component} from "react"
import "./HomeTail.scss"

import icon_1 from "./img/icon_1.png"
import icon_2 from "./img/icon_2.png"
import icon_3 from "./img/icon_3.png"
import icon_4 from "./img/icon_4.png"

import $ from 'jquery'
import contact_main_img from "../../pages/home/img/content/contact-main-img.png";
import {withTranslation} from "react-i18next";

class HomeTail extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            home_tail_state: 1
        })

        if (props.onRef) {//如果父组件传来该方法 则调用方法将子组件this指针传过去
            props.onRef(this)
        }
    }

    contact_home_tail_change() {
        $("#tail_bottom_two").css('background', "none")
        $("#tail_bottom_two").css('position', "absolute")


        $("#tail_home_tail").css('position', "absolute")
        $("#tail_home_tail").animate({
            height: "160px",
            width: "100%",
            bottom: "-50px",
            paddingTop: "40px"
        }, 1000)


        // align-items: center;
        $("#home_tail_one").css('align-items', "top")
        $("#home_tail_one").animate({
            paddingLeft: "240px"
        }, 1000)

    }

    contact_home_tail_revert() {
        $("#tail_bottom_two").css('background', "#ffffff")
        $("#tail_bottom_two").css('position', "static")

        $("#tail_home_tail").css('position', "static")
        $("#tail_home_tail").animate({
            height: "220px",
            bottom: "0px",
            paddingTop: "0px"
        }, 1000)

        $("#home_tail_one").animate({
            paddingLeft: "314px"
        }, 1000)
    }


    render() {
        const {t} = this.props;
        return (
            <div className="HomeTail-main">
                <div id="tail_home_tail" className="tail-home-tail">
                    <div id="home_tail_one" className="HomeTail-one">
                        <div className="item1">
                            <div className="phone">
                                <div className="x5"></div>
                                <div className="bottom-text3">{t("tail.describe_text1")}</div>
                            </div>
                            <div className="phone">
                                {/*<div className="text1" style={localStorage.getItem("Language") == "en" ? {width:'70px'}:{width:'40px'}}>{t("tail.describe_text2")}</div>*/}
                                {localStorage.getItem("Language") == "en" ?
                                    <div>{t("tail.describe_text2")} {t("tail.describe_text3")}</div>
                                    : <div>{t("tail.describe_text2")}{t("tail.describe_text3")}</div>}
                            </div>
                            {/*<div className="phone">*/}
                            {/*    <div className="text1">&nbsp;</div>*/}
                            {/*    <div>{t("tail.describe_text4")}</div>*/}
                            {/*</div>*/}
                            <div className="phone">
                                {/*<div className="text1" style={localStorage.getItem("Language") == "en" ? {width:'70px'}:{width:'40px'}}>{t("tail.describe_text5")}</div>*/}
                                {localStorage.getItem("Language") == "en" ?
                                    <div>{t("tail.describe_text5")} {t("tail.describe_text6")}</div>
                                    : <div>{t("tail.describe_text5")}{t("tail.describe_text6")}</div>}
                            </div>
                            {/*<div className="phone">*/}
                            {/*    /!*<div className="text1" style={localStorage.getItem("Language") == "en" ? {width:'70px'}:{width:'40px'}}>{t("tail.describe_text7")}</div>*!/*/}
                            {/*    {localStorage.getItem("Language") == "en" ?*/}
                            {/*        <div>{t("tail.describe_text7")} {t("tail.describe_text8")}</div>*/}
                            {/*        : <div>{t("tail.describe_text7")}{t("tail.describe_text8")}</div>}*/}
                            {/*</div>*/}
                            {/*<div className="phone">*/}
                            {/*    <div className="text1">&nbsp;</div>*/}
                            {/*    <div>&nbsp;</div>*/}
                            {/*</div>*/}
                        </div>

                        <div className="item1">
                            <div className="company">
                                <div className="x5"></div>
                                <div className="bottom-text3">{t("tail.describe_text9")}</div>
                            </div>
                            <div className="company">
                                {t("tail.describe_text10")}
                            </div>
                            <div className="company">
                                &nbsp;
                            </div>
                            {/*<div className="company">*/}
                            {/*    {t("tail.describe_text11")}*/}
                            {/*</div>*/}
                            {/*<div className="company">*/}
                            {/*    {t("tail.describe_text12")}*/}
                            {/*</div>*/}
                            {/*<div className="company">*/}
                            {/*    {t("tail.describe_text13")}*/}
                            {/*</div>*/}
                        </div>

                    </div>
                </div>
                <div style={{height: "150px"}}>
                    <a onClick={this.props.contactClick}>
                        <img id="contact_main_img" className="main-img" src={contact_main_img}/>
                    </a>
                    <div id="tail_bottom_two" className="tail-bottom-two">
                        <div className="div-div-img">
                            <div className="div-img">
                                <img src={icon_1}/>
                                <img src={icon_2}/>
                                <img src={icon_3}/>
                                <img src={icon_4}/>
                            </div>
                        </div>
                        <div className="text">
                            © MAYFLOWER ART CENTER 2021. All Rights Reserved.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(HomeTail);
