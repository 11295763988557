import React, {Component} from "react";
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import style from "./NewsDetail-phone-5.module.scss";


import {withTranslation} from "react-i18next";
import musiCON from "../../detail/img/musiCON.png";
import Jury from "../../detail/img/Jury.png";
import Finalists1 from "../../detail/img/Finalists1.png";
import Finalists2 from "../../detail/img/Finalists2.png";
import Finalists_bottom from "../../detail/img/Finalists_bottom.png";

class NewsDetailPhone13 extends Component {
    constructor() {
        window.scrollTo(0, 0);
        super();
    }

    click_last() {
        this.props.history.push("/news-phone-detail-one");
    }

    click_next() {
        this.props.history.push("/2nd-musicon-composition-competition");
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Header_phone></Header_phone>
                <div className={style.newDetail}>
                    <div className={style.main}>
                        <div className={style.top_date}>
                            <div className={style.icon}></div>
                            <div>09/10/2024</div>
                        </div>
                        <div className={style.mbody}>
                            <div className={style.top_text} style={{ textAlign: 'center' }}><span>{t("news.news_9_2024_10_title")}</span></div>
                            <div><span className={style.detail_text}>{t("news.news_9_2024_10_text")}</span></div>

                            <div><img src={musiCON} className={style.align_left} style={{ display: "inline", width: "100%" }} alt="" /></div>

                            <div><span className={style.detail_text} style={{whiteSpace:'pre-wrap'}}>{t("news.news_9_2024_10_text_1")}</span></div>
                            <div><span className={style.chapter_text} >{t("news.news_9_2024_10_text_chapter_1")}</span></div>
                            <div><img src={Jury} className={style.align_left} style={{ display: "inline", width: "100%" }} alt="" /></div>

                            <div><span className={style.detail_text} style={{whiteSpace:'pre-wrap'}}>{t("news.news_9_2024_10_text_1_1")}</span></div>

                            <div><span className={style.chapter_text}>{t("news.news_9_2024_10_text_chapter_2")}</span></div>
                            <div><img src={Finalists1} className={style.align_left} style={{ display: "inline", width: "100%" }} alt="" /></div>
                            <div><img src={Finalists2} className={style.align_left} style={{ display: "inline", width: "100%" }} alt="" /></div>
                            <div><span className={style.chapter_text}>{t("news.news_9_2024_10_text_chapter_3")}</span></div>
                            <div><span className={style.detail_text} style={{whiteSpace:'pre-wrap'}}>{t("news.news_9_2024_10_text_3_1")}</span></div>

                            <div><img src={Finalists_bottom} className={style.align_left} style={{ display: "inline", width: "100%" }} alt="" /></div>






                            {/*<div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_1")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_1")}</span></div>*/}
                            {/*<div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_2")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_2_1")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_2_2")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_2_3")}</span></div>*/}
                            {/*<div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_3")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_1")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_2")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_3")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_4")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_5")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_6")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_7")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_8")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_9")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_10")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_11")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_12")}</span></div>*/}
                            {/*<div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_4")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_1")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_2")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_3")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_4")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_5")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_6")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_7")}</span></div>*/}
                            {/*<div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_5")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_5_1")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_5_2")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_5_3")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_5_4")}</span></div>*/}
                            {/*<div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_6")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_6_1")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_6_2")}</span></div>*/}
                            {/*<div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_7")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_1")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_2")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_3")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_4")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_5")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_6")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_7")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_8")}</span></div>*/}
                            {/*<div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_9")}</span></div>*/}
                            <br/>
                            <br/>
                            <div style={{textAlign: 'right'}}><span className={style.detail_text}>{t("news.news_9_2024_10_text_4")}</span></div>
                            <div style={{textAlign: 'right'}}><span className={style.detail_text}>{t("news.news_9_2024_10_text_5")}</span></div>
                        </div>
                        <div className={style.detail_btn}>
                            <div
                                className={style.detail_btn_last}
                                onTouchEnd={() => this.click_last()}
                            >
                                LAST
                            </div>
                            <div
                                className={style.detail_btn_next}
                                onTouchEnd={() => this.click_next()}
                            >
                                NEXT
                            </div>
                        </div>
                    </div>
                    <Tail_phone></Tail_phone>
                </div>
            </div>
        );
    }
}

export default withTranslation()(NewsDetailPhone13);
