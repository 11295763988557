import React, {Component} from "react";
import {withTranslation} from "react-i18next"
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import "./NewDetail-phone-1.scss"
import line from "../img/NewsDetailPhoneLine.png";
import paper from "../img/paper.png";

class NewsDetailPhone1 extends Component {
    constructor() {
        window.scrollTo(0, 0)
        super();
    }

    click_last() {
        this.props.history.push("/news-phone-detail-two")
    }

    click_next() {
        this.props.history.push("/2nd-musicon-composition-competition-finalists")
    }

    render() {
        const { t } = this.props;
        return (
            <div className="news-detail-phone-main">
                <Header_phone></Header_phone>
                <div className="news-detail-phone-content">
                    <div className="date">
                        <div className="icon"></div>
                        <span>02/22/2021</span>
                    </div>
                    <div className="content-detail">
                        <img src={paper} className="picture" />
                        <div className="detail-text">
                            <div className="text1">{t("news.third_text_title")}</div>
                            <img src={line} />
                            <div className="text2">
                                {t("news.third_text_detail")}
                                <br />
                                <br />
                                {t("news.detail_page_three_1")}
                                <br />
                                <br />
                                {t("news.detail_page_three_2")}
                                <br />
                                <br />
                                {t("news.detail_page_three_3")}
                                <br />
                                <br />
                                {t("news.detail_page_three_4")}
                            </div>
                        </div>
                    </div>
                    <div className="detail-btn">
                        <div
                            className="detail-btn-last"
                            onTouchEnd={() => this.click_last()}
                        >
                            LAST
                        </div>
                        <div
                            className="detail-btn-next"
                            onTouchEnd={() => this.click_next()}
                        >
                            NEXT
                        </div>
                    </div>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        );
    }
}

export default withTranslation()(NewsDetailPhone1);
