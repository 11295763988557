import React, {Component} from "react"
import "./Tail.scss"
import file from './img/file---47@1x.png'

import icon_1 from "./img/icon_1.png"
import icon_2 from "./img/icon_2.png"
import icon_3 from "./img/icon_3.png"
import icon_4 from "./img/icon_4.png"
import note from "./img/note.png"
import {withTranslation} from "react-i18next";

class Tail extends Component {

    constructor(prop) {
        super(prop);
    }

    render() {
        const {t} = this.props;
        return (
            <div className="tail-bottom-main">
                <div className="bottom-one">
                    <div className="item1">
                        <div className="bottom-text1">MAYFLOWER ART CENTER</div>
                        <div className="bottom-text1">美好艺术中心</div>

                        <div className="div-top bottom-text2">Email: office@mayflowerartcenter.org</div>
                        <div className="bottom-text2">Address: Andover, Massachusetts, USA</div>
                    </div>
                    <img className="x47" src={file}/>
                    <div className="item1">
                        <div className="phone bottom-text3">
                            <div className="x5"></div>
                            <div>{t("tail.describe_text1")}</div>
                        </div>
                        <div className="phone">
                            {/*<div className="text1" style={localStorage.getItem("Language") == "en" ? {width:'70px'}:{width:'40px'}}>{t("tail.describe_text2")}</div>*/}
                            {localStorage.getItem("Language") == "en" ?
                                <div>{t("tail.describe_text2")} {t("tail.describe_text3")}</div>
                                : <div>{t("tail.describe_text2")}{t("tail.describe_text3")}</div>}
                        </div>
                        {/*<div className="phone">*/}
                        {/*    <div className="text1">&nbsp;</div>*/}
                        {/*    <div>{t("tail.describe_text4")}</div>*/}
                        {/*</div>*/}
                        <div className="phone">
                            {/*<div className="text1" style={localStorage.getItem("Language") == "en" ? {width:'70px'}:{width:'40px'}}>{t("tail.describe_text5")}</div>*/}
                            {localStorage.getItem("Language") == "en" ?
                                <div>{t("tail.describe_text5")} {t("tail.describe_text6")}</div>
                                : <div>{t("tail.describe_text5")}{t("tail.describe_text6")}</div>}
                        </div>
                        {/*<div className="phone">*/}
                        {/*    /!*<div className="text1" style={localStorage.getItem("Language") == "en" ? {width:'70px'}:{width:'40px'}}>{t("tail.describe_text7")}</div>*!/*/}
                        {/*    {localStorage.getItem("Language") == "en" ?*/}
                        {/*        <div>{t("tail.describe_text7")} {t("tail.describe_text8")}</div>*/}
                        {/*        : <div>{t("tail.describe_text7")}{t("tail.describe_text8")}</div>}*/}
                        {/*</div>*/}

                    </div>

                    <div className="item1">
                        <div className="company bottom-text3">
                            <div className="x5"></div>
                            <div >{t("tail.describe_text9")}</div>
                        </div>
                        <div className="company">
                            {t("tail.describe_text10")}
                        </div>
                        <div className="company">
                            &nbsp;
                        </div>
                        {/*<div className="company">*/}
                        {/*    {t("tail.describe_text11")}*/}
                        {/*</div>*/}
                        {/*<div className="company">*/}
                        {/*    {t("tail.describe_text12")}*/}
                        {/*</div>*/}
                        {/*<div className="company">*/}
                        {/*    {t("tail.describe_text13")}*/}
                        {/*</div>*/}
                    </div>
                    <img className="note" src={note}/>

                </div>
                <div className="bottom-two">
                    <div>
                        <img src={icon_1}/>
                        <img src={icon_2}/>
                        <img src={icon_3}/>
                        <img src={icon_4}/>
                    </div>
                    <div className="text">
                        © MAYFLOWER ART CENTER 2021. All Rights Reserved.
                    </div>

                </div>

            </div>
        );
    }
}
export default withTranslation()(Tail);
