import React, { Component } from "react";
import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import style from "./NewsDetail-5.module.scss";

import { withTranslation } from "react-i18next";
import musiCON from "./img/musiCON.png";
import Jury from "./img/Jury.png";
import Finalists1 from "./img/Finalists1.png";
import Finalists2 from "./img/Finalists2.png";
import Finalists_bottom from "./img/Finalists_bottom.png";


class NewsDetail12 extends Component {
    constructor() {
        window.scrollTo(0, 0);
        super();
    }

    changeLast() {
        this.props.history.push("/detail-one");
    }

    changeNext() {
        this.props.history.push("/2nd-musicon-composition-competition");
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Header></Header>

                <div className={style.leftCircle}></div>
                <div className={style.rightCircle}></div>
                <div className={style.main}>
                    <div className={style.top_date}>
                        <div></div>
                        <div>09/10/2024</div>
                    </div>
                    <div className={style.mbody}>
                        <div className={style.top_text} style={{ textAlign: 'center' }}><span>{t("news.news_9_2024_10_title")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_9_2024_10_text")}</span></div>

                        <div><img src={musiCON} className={style.align_left} style={{ display: "inline", width: "100%" }} alt="" /></div>
                        <div><span className={style.detail_text} style={{whiteSpace:'pre-wrap'}}>{t("news.news_9_2024_10_text_1")}</span></div>
                        <div><span className={style.chapter_text} >{t("news.news_9_2024_10_text_chapter_1")}</span></div>
                        <div><img src={Jury} className={style.align_left} style={{ display: "inline", width: "100%" }} alt="" /></div>

                        <div><span className={style.detail_text} style={{whiteSpace:'pre-wrap'}}>{t("news.news_9_2024_10_text_1_1")}</span></div>

                        <div><span className={style.chapter_text}>{t("news.news_9_2024_10_text_chapter_2")}</span></div>
                        <div><img src={Finalists1} className={style.align_left} style={{ display: "inline", width: "100%" }} alt="" /></div>
                        <div><img src={Finalists2} className={style.align_left} style={{ display: "inline", width: "100%" }} alt="" /></div>
                        <div><span className={style.chapter_text}>{t("news.news_9_2024_10_text_chapter_3")}</span></div>
                        <div><span className={style.detail_text} style={{whiteSpace:'pre-wrap'}}>{t("news.news_9_2024_10_text_3_1")}</span></div>

                        <div><img src={Finalists_bottom} className={style.align_left} style={{ display: "inline", width: "100%" }} alt="" /></div>

                        <br/>
                        <br/>
                        <div style={{textAlign: 'right'}}><span className={style.detail_text}>{t("news.news_9_2024_10_text_4")}</span></div>
                        <div style={{textAlign: 'right'}}><span className={style.detail_text}>{t("news.news_9_2024_10_text_5")}</span></div>
                    </div>
                </div>
                <div className={style.footer}>
                    <div onClick={this.changeLast.bind(this)}>
                        LAST
                    </div>
                    <div onClick={this.changeNext.bind(this)}>
                        NEXT
                    </div>
                </div>

                <div className={style.detailFooter}>
                    <Tail></Tail>
                </div>
            </div>
        );
    }
}

export default withTranslation()(NewsDetail12);
