import React, {Component} from "react";
import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import './NewsDetail-1.scss'
import volin from '../img/violin.png'
import line from './img/line.png'
import {withTranslation} from "react-i18next"


class NewsDetail3 extends Component {
    constructor() {
        window.scrollTo(0,0)
        super();
    }

    changeLast() {
        this.props.history.push("/detail-four")
    }

    changeNext() {
        this.props.history.push("/detail-two");
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Header></Header>
                <div className="newDetail">
                    <div className="leftCircle"></div>
                    <div className="rightCircle"></div>
                    <div className="main" id="main">
                        <div className="top-date">
                            <div></div>
                            <div>03/08/2021</div>
                        </div>
                        <div className="mbody">
                            <img className="img" src={volin}/>
                            <div className="top-text">
                                <span>{t('news.first_text_title')}</span>
                            </div>
                            <img src={line} className="line"/>
                            <div className="detail-text">
                                {t('news.first_text_detail')}
                                <br/>
                                <br/>
                                {t('news.detail_page_one')}
                            </div>
                        </div>
                    </div>
                    <div className="footer-next">
                        <div className="footer-next-div">
                            <div className="btn-round-next">
                                <div className="round-border" onClick={this.changeLast.bind(this)}>
                                    <div className="btn-inner" style={localStorage.getItem('Language')=='en'?{top:'100px'}:{}}>LAST</div>
                                </div>
                                <div className="round-border" onClick={this.changeNext.bind(this)}>
                                    <div className="btn-inner-next" style={localStorage.getItem('Language')=='en'?{top:'100px'}:{}}>NEXT</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="detailFooter">
                        <Tail></Tail>
                    </div>
                </div>

            </div>
        )
    }
}
export default withTranslation()(NewsDetail3)
