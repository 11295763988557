import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import line from "../../../projectDescription/img/line.png";

import "./compositionCompetition_phone.scss";
import cup from "../img/cup.png";
import violin from "../img/violin.png";
import piano from "../img/piano.png";
import paper from "../img/paper.png";
import volin2 from "../img/violin-2.png";
import paper2 from "../img/paper-2.png";
import city from "../img/victory.png";
import detail5 from "../img/news_detail_5_1.png";
import detail7 from "../img/news_detail_7_1.png";

class CompositionCompetition_phone extends Component {
  linkToClick(url) {
    this.props.history.push("/" + url);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="news-phone-main">
        <Header_phone></Header_phone>
        <div className="max-circle"></div>

        <div className="news-phone-content">
          <div className="news-title">
            <img src={line} />
            <div className="text1" style={{fontSize: '1.05rem'}}>MusiCON International Composition Competition：</div>
            <div className="text2">MusiCON作曲比赛 </div>
          </div>

          <div className="news-content">

            <div
                className="item-content"
                onTouchEnd={() => this.linkToClick("2nd-musicon-composition-competition-finalists")}
            >
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>09/10/2024</span>
                </div>
                <div className="text1">{t("news.news_9_2024_10_title")}</div>
                <div className="text2">{t("news.news_9_2024_10_text")}</div>
              </div>
            </div>

            <div
                className="item-content"
                onTouchEnd={() => this.linkToClick("2nd-musicon-composition-competition")}
            >
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>04/08/2024</span>
                </div>
                <div className="text1">{t("news.news_4_2024_8_title")}</div>
                <div className="text2">{t("news.news_4_2024_8_text")}</div>
              </div>
            </div>
            <div
                className="item-content"
                onTouchEnd={() => this.linkToClick("news-phone-detail-eleven")}
            >
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>11/14/2023</span>
                </div>
                <div className="text1">{t("news.news_11_2023_14_title")}</div>
                <div className="text2">{t("news.news_11_2023_14_text")}</div>
              </div>
            </div>

            <div
              className="item-content"
              onTouchEnd={() => this.linkToClick("news-phone-detail-eight")}
            >
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>12/10/2022</span>
                </div>
                <div className="text1">{t("news.news_21_2022_12_title")}</div>
                <div className="text2">{t("news.news_21_2022_12_text")}</div>
              </div>
            </div>
            <div
              className="item-content"
              onTouchEnd={() => this.linkToClick("news-phone-detail-seven")}
            >
              <div className="img-item">
                <img src={detail7} />
              </div>
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>09/30/2022</span>
                </div>
                <div className="text1">{t("news.seven_title_Sep_30_2022")}</div>
                <div className="text2">
                  {t("news.seven_detail_Sep_30_2022")}
                </div>
              </div>
            </div>
            <div
              className="item-content"
              onTouchEnd={() => this.linkToClick("1st-musicon-composition-competition")}
            >
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>08/01/2022</span>
                </div>
                <div className="text1">
                  {t("news.second_title_July_27_2022")}
                </div>
                <div className="text2">
                  {t("news.second_detail_July_27_2022")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Tail_phone></Tail_phone>
      </div>
    );
  }
}

export default withTranslation()(CompositionCompetition_phone);
