import React, {Component} from "react";
import "./TeacherDetail_phone.scss"
import line from "../../../projectDescription/img/line.png";
import {withTranslation} from "react-i18next";
import Header_phone from "../../../../components/header/Header_phone";
import rectangle1 from "../../img/rectangle1.png";
import Tail_phone from "../../../../components/tail/Tail_phone";
import yikeshan2 from "../../img/yikeshan2.png";
class TeacherDetail_phone_Yikeshan extends Component {
    constructor(prop) {
        super(prop);
    }

    linkToClick(val){
        localStorage.setItem("route", val)
    }

    render() {
        const {t} = this.props;
        return (
            <div className="TeacherDetail-phone-main">
                <Header_phone></Header_phone>
                <div className="TeacherDetail-phone-content">
                    <div className="TeacherDetail-title">
                        <img src={line}/>
                        <div className="text1">
                            Yikeshan :
                        </div>
                        <div className="text2">
                            {/*style={{marginLeft: "3.3rem"}}*/}
                            依克山
                        </div>
                    </div>
                    <div className="img-div">
                        <img src={rectangle1} className="ground"/>
                        <img src={yikeshan2} className="img"/>
                    </div>
                    <div className="teacher-introduce">
                        {t("teacher_details.detail_3.describe_text1")}<br/>
                        {t("teacher_details.detail_3.describe_text2")}<br/>
                        {t("teacher_details.detail_3.describe_text3")}<br/>
                        {t("teacher_details.detail_3.describe_text4")}<br/>
                        {t("teacher_details.detail_3.describe_text5")}<br/>
                        {t("teacher_details.detail_3.describe_text6")}<br/>
                        {t("teacher_details.detail_3.describe_text7")}<br/>
                        {t("teacher_details.detail_3.describe_text8")}<br/>
                        {t("teacher_details.detail_3.describe_text9")}<br/>
                    </div>
                    <a href="/teacher-phone" >
                        <div className="more_teachers">
                            <span className="text5">{t('teacher_details.more_teachers')}</span>
                        </div>
                    </a>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        );
    }
}
export default withTranslation()(TeacherDetail_phone_Yikeshan);
