import React, {Component} from "react";
import "./Header.scss"
import {Link} from 'react-router-dom';
import Project from "./children/Project"
import MusiCON from "./children/MusiCON"

import Honors from "./children/Honors";
import i18n from 'i18next';
import {withTranslation} from "react-i18next"
import $ from 'jquery'

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            route: localStorage.getItem("route"),
            news_class_state: true
        })
        if (props.onRef) {//如果父组件传来该方法 则调用方法将子组件this指针传过去
            props.onRef(this)
        }
    }

    componentDidMount() {
        if (localStorage.getItem("Language") == 'en') {
            $(".header-text-news").css("letter-spacing", "0px")
            $(".header-text").css("letter-spacing", "0px")
        }else{
            $(".header-text-news").css("letter-spacing", "5px")
            $(".header-text").css("letter-spacing", "5px")
        }
    }


    linkToClick(val) {
        localStorage.setItem("route", val);
        if (val != "music-course" && val != "study-abroad" && val != "music-production") {
            localStorage.setItem("object_state", "0")
        }
        if (val != "roster" && val != "award") {
            localStorage.setItem("honors_state", "0")
        }
        if(val != "musiCON-International-Composition-Competition"){
            localStorage.setItem("musiCON_state", "0")
        }
        this.setState({
            route: val
        })

    }

    changeLanguage(val) {
        localStorage.setItem("Language", val)
        i18n.changeLanguage(val).then(() => {
            this.setState({})
        })
        if (val == 'en') {
            $(".header-text-news").css("letter-spacing", "0px")
            $(".header-text").css("letter-spacing", "0px")
        }else{
            $(".header-text-news").css("letter-spacing", "5px")
            $(".header-text").css("letter-spacing", "5px")
        }
    }

    changeObject() {
        this.setState({
            news_class_state: false
        })
        this.object_ChildPage.changeClassState()
        this.musiCON_ChildPage.changeClassState()
        this.honors_ChildPage.changeClassState()
    }

    render() {
        const {t} = this.props;
        return (
            <div className="header-Width">
                <div className="header-Logo">
                    <div className="header-Logo-Text">
                        <span>M</span>
                        <span>a</span>
                        <span>y</span>
                        <span>f</span>
                        <span>l</span>
                        <span>o</span>
                        <span>w</span>
                        <span>e</span>
                        <span>r</span>
                    </div>
                    <div className="header-Logo-Name">美好艺术中心</div>
                </div>
                <div className="header-Link">
                    <Link to={'/homepage-preview'} onClick={this.linkToClick.bind(this, 'homepage-preview')}>
                        <div
                            className={this.state.route == "homepage-preview" ? "header-text header-text-active" : 'header-text'}>
                            {t("header.home")}
                            {/*中心主页*/}
                        </div>
                    </Link>
                    <Link to={'/about-us'} onClick={this.linkToClick.bind(this, 'about-us')}>
                        <div
                            className={this.state.route == "about-us" ? "header-text header-text-active" : 'header-text'}>
                            {t("header.about_us")}
                            {/*关于我们*/}
                        </div>
                    </Link>

                    {/*项目介绍*/}
                    <Project onRef={ref => this.object_ChildPage = ref}></Project>
                    {/*项目介绍*/}

                    <Link to={'/teacher'} onClick={this.linkToClick.bind(this, 'teacher')}>
                        <div
                            className={this.state.route == "teacher" ? "header-text header-text-active" : (this.state.route == "news" && this.state.news_class_state ? "header-text-news" : 'header-text')}>
                            {t("header.faculties")}
                            {/*师资力量*/}
                        </div>
                    </Link>

                    {/*musiCON*/}
                    <MusiCON onRef={ref => this.musiCON_ChildPage = ref}></MusiCON>
                    {/*musiCON*/}

                    <Honors onRef={ref => this.honors_ChildPage = ref}></Honors>
                    <Link to={'/news'} onClick={this.linkToClick.bind(this, 'news')}>
                        <div
                            className={this.state.route == "news" ? (this.state.news_class_state ? "header-text-news header-text-news-active-a" : "header-text-news header-text-news-active-b") : 'header-text'}>
                            {t("header.news")}
                        </div>
                    </Link>
                    <Link to={'/contact'} onClick={this.linkToClick.bind(this, 'contact')}>
                        <div
                            className={this.state.route == "contact" ? "header-text header-text-active" : (this.state.route == "news" && this.state.news_class_state ? "header-text-news" : 'header-text')}>
                            {t("header.contract_us")}
                            {/*联系我们*/}
                        </div>
                    </Link>
                    <div className="language-button"
                         onClick={() => this.changeLanguage(i18n.language == 'en' ? 'zh' : 'en')}>{i18n.language == 'en' ? 'EN' : 'ZH'}</div>
                </div>
            </div>
        );
    }
}


export default withTranslation()(Header);
