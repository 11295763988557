import React, { Component } from "react";
import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import style from "./NewsDetail-5.module.scss";

import { withTranslation } from "react-i18next";

class NewsDetail10 extends Component {
  constructor() {
    window.scrollTo(0, 0);
    super();
  }

  changeLast() {
    this.props.history.push("/detail-eleven");
  }
  changeNext() {
    this.props.history.push("/detail-nine");
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Header></Header>

        <div className={style.leftCircle}></div>
        <div className={style.rightCircle}></div>

        <div className={style.main}>
          <div className={style.top_date}>
            <div></div>
            <div>5/31/2023</div>
          </div>
          <div className={style.mbody}>
            <div
              className={style.top_text}
            >
              <span>{t("news.news_5_2023_31_title")}</span>
            </div>
            <div>
              <span className={style.detail_text}>
                {t("news.news_5_2023_31_text")}
              </span>
            </div>
            <div>
              <span className={style.detail_text}>
                {t("news.news_5_2023_31_text2")}
              </span>
            </div>
          </div>
        </div>
        <div className={style.footer}>
          <div onClick={this.changeLast.bind(this)}>
            LAST
          </div>
          <div onClick={this.changeNext.bind(this)}>
            NEXT
          </div>
        </div>

        <div className={style.detailFooter}>
          <Tail></Tail>
        </div>
      </div>
    );
  }
}
export default withTranslation()(NewsDetail10);
