import React, {Component} from 'react';
import '../award/Award.scss'
import line from '../award/img/line.png'
import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import $ from 'jquery'
import schoolImg from './img/school.png'
import crown from '../img/crown.png'
import {withTranslation} from "react-i18next";

//录取名单
class Roster extends Component {
    constructor() {
        super();
        this.state = ({
            clickState: true
        })
    }

    awardAcitve() {
        if (this.state.clickState) {
            $("#jiangPai").animate({
                width: "619px",
                height: "926px",
                top: "168px",
                left: "1180px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#pastHonors").animate({
                top: "40px",
                left: '362px'
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#Awards_before").animate({
                'opacity': '0.0'
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#Awards").animate({
                'opacity': '1'
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#rightCircle").animate({
                left: "33px",
                top: "570px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#cards").animate({
                marginTop: "100px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#line_before").animate({
                'top': '301px',
                'left': '278px'
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#award-bottom").css('cursor', 'default')


            $("#jiangPai").css('cursor', "default")
            $("#Awards_before").css('cursor', "default")

            this.setState({
                clickState: false
            })
        }
    }


    render() {
        const {t} = this.props;
        return (
            <div>
                <Header></Header>
                <div className="award-bottom" id="award-bottom">

                    <div className="line"></div>
                    <img className="line-before" src={line} id="line_before"></img>
                    <div className="overlap-group8-13">
                        <img className="jiangPai" style={{width: "921px", left: "223px"}} src={schoolImg} id="jiangPai"
                             onClick={this.awardAcitve.bind(this)}/>
                        <div className="honors-awards" style={{top: "-40px"}} id="Awards">Admission List</div>
                        <div className="honors-awards-before" id="Awards_before"
                             onClick={this.awardAcitve.bind(this)}>Admission List
                        </div>
                        <div className="text-274" id="pastHonors">
                            录取名单
                        </div>
                    </div>
                    <div className="x16-7" style={{top: "560px"}}></div>
                    <div className="x14-7" id="rightCircle"></div>
                    {/*往期荣誉----1--------start*/}

                    <div className="cards" id="cards">
                        <div className="x268"></div>
                        <div className="x185-1">
                            <div className="flex-row-30">
                                <img src={crown}/>
                                <div className="school_year">
                                    2023
                                </div>
                            </div>
                            <hr className="dateline"/>
                            <div className="conList">
                                {t("roster.conList_2023_1")}
                                <br/>
                                <br/>
                                {t("roster.conList_2023_2")}
                                <br/>
                                <br/>
                                {t("roster.conList_2023_3")}
                                <br/>
                                <br/>
                                {t("roster.conList_2023_4")}
                            </div>
                        </div>
                    </div>

                    <div className="cards" id="cards" style={{marginTop: "54px"}}>
                        <div className="x268"></div>
                        <div className="x185-1">
                            <div className="flex-row-30">
                                <img src={crown}/>
                                <div className="school_year">
                                    2022
                                </div>
                            </div>
                            <hr className="dateline"/>
                            <div className="conList">
                                {t("roster.conList_2022_1")}
                                <br/>
                                <br/>
                                {t("roster.conList_2022_2")}
                                <br/>
                                <br/>
                                {t("roster.conList_2022_3")}
                                <br/>
                                <br/>
                                {t("roster.conList_2022_4")}
                                <br/>
                                <br/>
                                {t("roster.conList_2022_5")}
                            </div>
                        </div>
                    </div>
                    <div className="cards" id="cards" style={{marginTop: "54px"}}>
                        <div className="x268"></div>
                        <div className="x185-1">
                            <div className="flex-row-30">
                                <img src={crown}/>
                                <div className="school_year">
                                    2021
                                </div>
                            </div>
                            <hr className="dateline"/>
                            <div className="conList">
                                {t("roster.conList_2021_1")}
                                <br/><br/>
                                {t("roster.conList_2021_2")}
                            </div>
                        </div>
                    </div>
                    <div className="cards" id="cards" style={{marginTop: "54px"}}>
                        <div className="x268"></div>
                        <div className="x185-1">
                            <div className="flex-row-30">
                                <img src={crown}/>
                                <div className="school_year">
                                    2020
                                </div>
                            </div>
                            <hr className="dateline"/>
                            <div className="conList">
                                {t("roster.conList_2020_1")}
                            </div>
                        </div>
                    </div>
                </div>
                <Tail></Tail>
            </div>
        )
    }
}

export default withTranslation()(Roster);
