//师资力量
import React, { Component } from "react";
import "./Teacher_phone.scss"
import blackboard from '../img/blackboard.png'
import yellowBackground from '../img/yellowBackground.png'
import CarolineSackleh2 from '../img/CarolineSackleh2.png'
import diaozhuo2 from '../img/diaozhuo2.png'
import gulei2 from '../img/gulei2.png'
import jiaorenhe2 from '../img/jiaorenhe2.png'
import wanghanzhi2 from '../img/wanghanzhi2.png'
import wanghaibei from '../img/wanghaibei2.png'
import wuyiming2 from '../img/wuyiming2.png'
import xiedongni2 from '../img/xiedongni2.png'
import lijunlang from '../img/lijunlang2.jpg'
import yangjin2 from '../img/yangjin2.png'
import yikeshan2 from '../img/yikeshan2.png'
import zhengyuting2 from '../img/zhengyuting2.png'
import line from '../../projectDescription/img/line.png'
import rectangle1 from '../img/rectangle1.png'
import daibo2 from '../img/daibo2.jpg'
import qianshenying2 from '../img/qianshenying2.jpg'
import { withTranslation } from "react-i18next";
import Header_phone from "../../../components/header/Header_phone";
import longLine from "../../projectDescription/musicCourse/img/long-line.png";
import Tail_phone from "../../../components/tail/Tail_phone";
import xiasunhuimei from "../img/xiasunhuimei2.jpg";
import TeacherDetail_phone_LiJunlang from "../details/phone/TeacherDetail_phone_LiJunlang";

class Teacher_phone extends Component {

    render() {
        const { t } = this.props;
        return (
            <div className="teacher-phone-main">
                <Header_phone></Header_phone>

                <div className="teacher-phone-content">
                    <div className="teacher-title">
                        <img src={line} />
                        <div className="text1">
                            Faculty:
                        </div>
                        <div className="text2" style={{ marginLeft: "4rem" }}>
                            师资力量
                        </div>
                    </div>
                    <div className="img-div">
                        <img src={yellowBackground} className="course-ground" />
                        <img src={blackboard} className="course-img" />
                    </div>
                    <div className="circular"></div>

                    <div className="course-content">
                        <div className="line">
                            <div className="point"></div>
                            <div className="course-split"
                                style={localStorage.getItem('Language') == 'en' ? { letterSpacing: '1px' } : {}}>
                                {t("teacher_details.text")}
                            </div>
                        </div>
                        <img src={longLine} className="longLine" />
                        <div className="lecturer">
                            <div className="item">
                                <div>
                                    <div className="img-div">
                                        <a href="/detail-phone-YimingWu">
                                            <img src={rectangle1} className="ground" />
                                            <img src={wuyiming2} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">吴羿明</div>
                                    <div className="name-en">Yiming Wu</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_1")}
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="img-div">
                                        <a href="/detail-phone-HaibeiWang">
                                            <img src={rectangle1} className="ground" />
                                            <img src={wanghaibei} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">汪海蓓</div>
                                    <div className="name-en">Haibei Wang</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_2")}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    <div className="img-div">
                                        <a href="/detail-phone-Yikeshan">
                                            <img src={rectangle1} className="ground" />
                                            <img src={yikeshan2} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">依克山</div>
                                    <div className="name-en">Yikeshan</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_3")}
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="img-div">
                                        <a href="/detail-phone-ShenyingQian">
                                            <img src={rectangle1} className="ground" />
                                            <img src={qianshenying2} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">钱慎瀛</div>
                                    <div className="name-en">Shenying Qian</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_4")}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    <div className="img-div">
                                        <a href="/detail-phone-BoDai">
                                            <img src={rectangle1} className="ground" />
                                            <img src={daibo2} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">代博</div>
                                    <div className="name-en">Bo Dai</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_5")}
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="img-div">
                                        <a href="/detail-phone-SunhuimeiXia">
                                            <img src={rectangle1} className="ground" />
                                            <img src={xiasunhuimei} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">夏孙惠美</div>
                                    <div className="name-en">Sunhuimei Xia</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_6")}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    <div className="img-div">
                                        <a href="/detail-phone-HanzhiWang">
                                            <img src={rectangle1} className="ground" />
                                            <img src={wanghanzhi2} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">王寒之</div>
                                    <div className="name-en">Hanzhi Wang</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_7")}
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="img-div">
                                        <a href="/detail-phone-YutingZheng">
                                            <img src={rectangle1} className="ground" />
                                            <img src={zhengyuting2} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">郑育婷</div>
                                    <div className="name-en">Yuting Zheng</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_8")}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    <div className="img-div">
                                        <a href="/detail-phone-RenheJiao">
                                            <img src={rectangle1} className="ground" />
                                            <img src={jiaorenhe2} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">焦人和</div>
                                    <div className="name-en">Renhe Jiao</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_9")}
                                    </div>
                                </div>
                                {/*<div className="right">*/}
                                {/*    <div className="img-div">*/}
                                {/*        <a href="/detail-phone-LiJunlang">*/}
                                {/*            <img src={rectangle1} className="ground" />*/}
                                {/*            <img src={lijunlang} className="img" />*/}
                                {/*        </a>*/}
                                {/*    </div>*/}
                                {/*    <div className="name-zh">李俊朗</div>*/}
                                {/*    <div className="name-en">Li Junlang</div>*/}
                                {/*    <div className="teacher-introduce">*/}
                                {/*        {t("teacher.describe_10")}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="right">
                                    <div className="img-div">
                                        <a href="/detail-phone-ZhuoDiao">
                                            <img src={rectangle1} className="ground" />
                                            <img src={diaozhuo2} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">刁卓</div>
                                    <div className="name-en">Zhuo Diao</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_11")}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    <div className="img-div">
                                        <a href="detail-phone-LeiGu">
                                            <img src={rectangle1} className="ground" />
                                            <img src={gulei2} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">顾磊</div>
                                    <div className="name-en">Lei Gu</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_12")}
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="img-div">
                                        <a href="/detail-phone-JinYang">
                                            <img src={rectangle1} className="ground" />
                                            <img src={yangjin2} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">杨瑾</div>
                                    <div className="name-en">Jin Yang</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_13")}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    <div className="img-div">
                                        <a href="/detail-phone-DongniXie">
                                            <img src={rectangle1} className="ground" />
                                            <img src={xiedongni2} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">谢冬妮</div>
                                    <div className="name-en">Dongni Xie</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_14")}
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="img-div">
                                        <a href="/detail-phone-CarolineSackleh">
                                            <img src={rectangle1} className="ground" />
                                            <img src={CarolineSackleh2} className="img" />
                                        </a>
                                    </div>
                                    <div className="name-zh">Caroline<br />Sackleh</div>
                                    <div className="teacher-introduce">
                                        {t("teacher.describe_15")}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    &nbsp;
                                </div>
                                <div className="right">&nbsp;</div>
                            </div>
                        </div>
                        <div className="circle-one">

                        </div>
                        <div className="circle-two">

                        </div>
                    </div>
                </div>
                <Tail_phone></Tail_phone>
            </div>

        );
    }
}

export default withTranslation()(Teacher_phone);
