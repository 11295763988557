import Header from "../../../../components/header/Header";
import Tail from "../../../../components/tail/Tail";
import React, {Component} from "react";
import './detailCommon.scss'
import line from '../img/thin-line.png'
import Caroline from "../img/Caroline.png";
import bassonImg from '../img/basson.png'
import {withTranslation} from "react-i18next"

class Basson extends Component {

    linkToClick(val) {
        localStorage.setItem("route", val);
        this.props.history.push("/" + val);
        if (val == "music-course") {
            localStorage.setItem("object_state", "1");
        }
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Header></Header>
                <div className="composing">
                    <img src={bassonImg} className="composingImg"/>
                    <div className="detail_text">
                        <div className="main_title">
                            <div className="top_title">Bassoon</div>
                            <div className="foot_title">巴松课程</div>
                        </div>
                        <img src={line} alt="" className="thin-line"/>
                        <div className="detail_violin">
                            {t('musicCourse.basson_detail_first')}
                            <br/>
                            <br/>
                            {t('musicCourse.basson_detail_second')}
                        </div>
                        <div className="teacher_title"
                             style={localStorage.getItem('Language') == 'en' ? {letterSpacing: '1px'} : {}}>{t('musicCourse.responsibleTeacher')}</div>
                        <div className="teacher_list">
                            <div className="first_teacher" onClick={this.linkToClick.bind(this, "detail-CarolineSackleh")}>
                                <img src={Caroline} alt="" className="teacher-img"/>
                                <div className="teacher_detail">
                                    <div className="teacher_name_en">Caroline Sackleh</div>
                                </div>
                            </div>
                        </div>
                        <a href="/music-course" onClick={this.linkToClick.bind(this, "music-course")}>
                            <div className="footer_btn"
                                 style={localStorage.getItem('Language') == 'en' ? {width: '165px'} : {width: '135px'}}>

                                <div className="btn-border"><span>{t('musicCourse.moreCourse')}</span></div>
                            </div>
                        </a>
                    </div>
                </div>
                <Tail></Tail>

            </div>

        )
    }
}

export default withTranslation()(Basson)
