import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import "./Header_phone.scss"
import i18n from "i18next";

class Header_phone extends Component {

    constructor(props) {
        super(props);
    }

    changeLanguage(val) {
        localStorage.setItem("Language", val)
        i18n.changeLanguage(val).then(() => {
            this.setState({})
        })
    }

    linkToClick(url) {
        localStorage.setItem("route", url)
    }

    render() {
        const {t} = this.props;
        return (
            <div className="header-phone-main">
                <Link to={'/navigation-phone'} className="header-phone-menu" onTouchEnd={()=>{
                    this.linkToClick("navigation-phone")
                }}>
                    {t("header.menu")}
                </Link>
                <Link to={'/home-phone'} className="header-phone-title" onTouchEnd={() => {
                    this.linkToClick("home-phone")
                }}>
                    <span className="span1">
                        {localStorage.getItem("route") != "home-phone" ? "Mayflower" : " "}
                    </span>
                    <span className="span2">
                         {localStorage.getItem("route") != "home-phone" ? "美好艺术中心" : " "}
                    </span>
                </Link>
                <div className="header-phone-i18-btn"
                     onTouchEnd={() => this.changeLanguage(i18n.language == 'en' ? 'zh' : 'en')}>{i18n.language == 'en' ? 'EN' : 'ZH'}
                </div>
            </div>
        );
    }
}

export default withTranslation()(Header_phone);
