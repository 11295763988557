import React, {Component} from "react";

import "./About_phone.scss"
import piano from '../img/piano.png'
import pianoBackground from '../img/pianoBackground.png'
import line1 from '../img/line1.png'
import line2 from '../img/line2.png'
import pianoScore from '../img/pianoScore.png'
import piano2 from '../img/piano2.png'
import people from '../img/people.png'
import Header from "../../../components/header/Header_phone";
import {withTranslation} from "react-i18next";
import Tail_phone from "../../../components/tail/Tail_phone";

class About_phone extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className="about-phone-main">
                <Header></Header>
                <div className="about-phone-content">
                    <div className="about-phone-item1">
                        <div className="item1-text">
                            Welcome to<br/>Mayflower Art Center !
                        </div>
                        <div className="item1-img-div">
                            <img className="img-pianoBackground" src={pianoBackground}/>
                            <img className="img-piano" src={piano}/>
                        </div>
                        <div className="item1-describe">
                            {t("about.about_content1")}
                        </div>
                    </div>
                    <div className="about-phone-item2">
                        <div className="linePart">
                            <img className="lineleft" src={line1}/>
                            <div className="our-story">Our Story</div>
                            <img className="lineRight" src={line1}/>
                        </div>
                        <div className="paragraph">
                            <span className="">{t("about.paragraph1_text1")}</span>
                            <span className="weight">{t("about.paragraph1_text2")}</span>
                            <span className="">{t("about.paragraph1_text3")}</span>
                            <span className="weight">{t("about.paragraph1_text4")}</span>
                            <span className="" style={{whiteSpace: 'pre-wrap'}}>{t("about.paragraph1_text5")}</span>
                            <span className="weight">{t("about.paragraph1_text6")}</span>
                            <span
                                className="">{t("about.paragraph1_text7")}<br/><br/> {t("about.paragraph1_text8")} </span>

                        </div>
                        <div className="item2-img-div">
                            <img className="piano" src={pianoScore}/>
                            <img className="line" src={line2}/>
                        </div>
                    </div>
                    <div className="about-phone-item3">
                        <div className="item3-circular"></div>
                        <div className="paragraph">
                            <span>{t("about.paragraph2_text1")}</span>
                            <span className="weight">{t("about.paragraph2_text2")}</span>
                            <span>{t("about.paragraph2_text3")}<br/><br/>{t("about.paragraph2_text4")}</span>
                            <br/><br/>
                            <span className="weight">{t("about.paragraph2_text6")}</span>
                        </div>

                        <div className="item3-img-div">
                            <img className="piano" src={piano2}/>
                            <img className="line" src={line2}/>
                        </div>
                    </div>

                    <div className="about-phone-item4">
                        <div className="item4-circular"></div>
                        <div className="paragraph">
                            <span className=""> {t("about.paragraph3_text1")}</span>
                            <span style={{whiteSpace: 'pre-wrap'}}> <br/> <br/>
                                {t("about.paragraph3_text2")}<br/><br/>
                                {t("about.paragraph3_text3")}<br/><br/>
                                {t("about.paragraph3_text4")}</span>
                        </div>

                        <div className="item4-img-div">
                            <img className="people" src={people}/>
                            <img className="line" src={line2}/>
                        </div>
                    </div>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        );
    }
}

export default withTranslation()(About_phone);
