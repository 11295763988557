import React, {Component} from "react";
import "./Contact.scss"
import Header from "../../components/header/Header";
import scenery from './img/scenery.png'
import QRcode1 from './img/QRcode1.png'
import QRcode2 from './img/QRcode2.png'
import line1 from './img/line1.png'
import line2 from './img/line2.png'
import note from './img/note.png'
import bottom from './img/bottom.png'
import textBox from './img/textBox.png'
import $ from 'jquery'
import {withTranslation} from "react-i18next";

//联系我们
class Contact extends Component {
    constructor(prop) {
        super(prop);
    }

    circularClick() {
        $("#circular1").css('background-color', "#fafafa")
        $("#circular1").animate({
            left: "80%",
            top: "250px",
            width: "414px",
            height: "409px",

        }, 1000)
        $("#circular_2").animate({
            left: "80%",
            top: "250px",
            width: "414px",
            height: "409px",
        }, 1000)
        $("#circular2").css('background-color', "#fafafa")
        $("#circular2").animate({
            left: "32px",
            top: "620px",
            width: "414px",
            height: "409px",

        }, 1000)
        $("#circular3").css('background-color', "#fafafa")
        $("#circular3").animate({
            zIndex: "-1",
            right: "57%",
            top: "60%",

        }, 1000)
        $("#scenery").animate({
            top: "160px",
            width: "619px",
            height: "926px"

        }, 1000)
        $("#content1").animate({
            marginTop: "0px",
            marginLeft: "410px",
        }, 1000)
        $("#line1").animate({
            marginTop: "-230px",
            marginLeft: "260px",
        }, 1000)
        $("#contact-us").animate({
            marginLeft: "250px",
            marginTop: "280px",
            fontSize: "60px",
            letterSpacing: "0px"
        }, 1000)


        $("#circular_2").css('cursor', "default")
        $("#scenery").css('cursor', "default")
        $("#contact-us").css('cursor', "default")

    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Header></Header>
                <div className="contact">
                    <div className="circular1" id="circular1"></div>
                    <div className="circular_2" id="circular_2" onClick={this.circularClick.bind(this)}></div>
                    <img className="scenery" id="scenery" onClick={this.circularClick.bind(this)} src={scenery}/>
                    <div className="circular2" id="circular2" onClick={this.circularClick.bind(this)}></div>
                    <div className="circular3" id="circular3"></div>
                    <div className="contact-us" id="contact-us" onClick={this.circularClick.bind(this)}>Contact Us</div>
                    <div className="content1" id="content1">联系我们</div>
                    <img className="line1" id="line1" src={line1}/>
                    <div className="allText">

                        <div className="paragraph1">
                            <img className="note" id="note" src={note}/>
                            <div className="mayflower-art-center-23">
                            <span className="span0-33">
                                MAYFLOWER ART CENTER
                            </span><br/>
                                <span className="content2">美好艺术中心</span>
                            </div>
                            <img className="line2" src={line2}/>
                        </div>
                        <div className="paragraph2">
                            <div className="circular4"></div>
                            <div className="text1" style={{whiteSpace: 'pre-wrap'}}>
                                {t("contact.describe_text1")}
                            </div>
                            <div className="first1" style={{whiteSpace: 'pre-wrap'}}>
                                {t("contact.describe_text2")}
                            </div>
                        </div>
                        <div className="paragraph3">
                            <div className="circular4"></div>
                            <div className="text1">{t("contact.describe_text3")}</div>
                            <div className="first1" style={{whiteSpace: 'pre-wrap'}}>

                                {t("contact.describe_text4_1")? <span><span>{t("contact.describe_text4_1")}</span><br/></span>: ''}
                                {/*美国：*/}
                                {/*(+1) 4104020521 ( Overseas Music Education Agency and other business consultation)*/}
                                {t("contact.describe_text4_2")? <span><span>{t("contact.describe_text4_2")}</span><br/></span>: ''}
                                <span>{t("contact.describe_text4_3")}</span>
                            </div>
                        </div>
                        <div className="paragraph4">
                            <div className="circular4"></div>
                            <div className="text1">{t("contact.describe_text5")}</div>
                            <div className="first1">{t("contact.describe_text6")}</div>
                        </div>
                        <div className="paragraph5">
                            <div className="circular4"></div>
                            <div className="text1" style="width: 200px;" style={{whiteSpace: 'pre-wrap',width: '200px'}}>{t("contact.describe_text7")}</div>
                            <div className="first1">{t("contact.describe_text8")}</div>
                        </div>
                        <div className="paragraph6">
                            <div className="circular4"></div>
                            <div className="text1" style={{whiteSpace: 'pre-wrap'}}>{t("contact.describe_text9")}</div>
                            <div className="first1">
                                {/*<img className="QRcode1" src={QRcode1}/>*/}
                                {/*className="QRcode2"*/}
                                <img src={QRcode2}/>
                            </div>
                        </div>
                        <div>
                            {/*<img className="textBox" src={textBox}/>*/}
                        </div>
                        <div>
                            <img className="bottom" src={bottom}/>
                        </div>
                        <p>© MAYFLOWER ART CENTER 2021. All Rights Reserved.</p>
                    </div>
                </div>
            </div>

        );
    }

}

export default withTranslation()(Contact);


