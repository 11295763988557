import React, {Component} from "react";
import composingImg from './img/composingImg.png'
import './detailPhoneCommon.scss'
import line from '../../img/thin-line.png'
import TeacherWu from '../../img/wuyiming.png'
import WangMeng from '../../img/wangmeng.png'
import yikeshan from '../../img/yikeshan.png'
import {withTranslation} from "react-i18next"
import Header_phone from "../../../../../components/header/Header_phone";
import Tail_phone from "../../../../../components/tail/Tail_phone";

class Composing_phone extends Component {

    linkToClick(val) {
        this.props.history.push("/" + val);
    }

    render() {
        const {t}=this.props
        return (
            <div>
                <Header_phone></Header_phone>
                <div className="group">
                    <div className="title">
                        <div className="text1">
                            Composing
                        </div>
                        <div className="text2" style={{marginLeft: "3.4rem"}}>
                            作曲课程
                        </div>
                    </div>
                    <img className="thin-line" src={line}/>

                    <img className="right-img" src={composingImg}/>
                    <div className="group-detail"
                         style={localStorage.getItem('Language') == 'en' ? {letterSpacing: "0rem"} : {letterSpacing: "0.05rem"}}>
                        {t('musicCourse.detail_composing')}
                    </div>
                    <div className="teacher-title">{t('musicCourse.responsibleTeacher')}</div>
                    <div className="teacher-list">
                        <div onClick={this.linkToClick.bind(this, "detail-phone-YimingWu")}>
                            <img src={TeacherWu} className="teacher-img"/>
                            <div className="teacher-text">
                                <div className="teacher-name">吴羿明</div>
                                <div className="teacher-name-en">Yiming Wu</div>
                            </div>
                        </div>
                        {/*<div className="many" onClick={this.linkToClick.bind(this, "detail-phone-MengWang")}>*/}
                        {/*    <img src={WangMeng} className="teacher-img"/>*/}
                        {/*    <div className="teacher-text">*/}
                        {/*        <div className="teacher-name">王 萌</div>*/}
                        {/*        <div className="teacher-name-en">Meng Wang</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="many" onTouchEnd={this.linkToClick.bind(this, "detail-phone-Yikeshan")}>
                            <img src={yikeshan} className="teacher-img"/>
                            <div className="teacher-text">
                                <div className="teacher-name">依 克 山</div>
                                <div className="teacher-name-en">YiKeShan</div>
                            </div>
                        </div>
                    </div>

                    <a href={"musicCourse-phone"}>
                        <div className="btn-phone-border">
                            {t('musicCourse.moreCourse')}
                        </div>
                    </a>
                </div>
                <Tail_phone></Tail_phone>
            </div>

        )
    }
}
export default withTranslation()(Composing_phone)
