import React, {Component} from "react";
import "./TeacherDetail_phone.scss"
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import line from "../../../projectDescription/img/line.png";
import rectangle1 from "../../img/rectangle1.png";
import {withTranslation} from "react-i18next";
import wanghaibei from "../../img/wanghaibei2.png";


class TeacherDetail_phone_HaibeiWang extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className="TeacherDetail-phone-main">
                <Header_phone></Header_phone>
                <div className="TeacherDetail-phone-content">
                    <div className="TeacherDetail-title">
                        <img src={line}/>
                        <div className="text1">
                            Haibei Wang:
                        </div>
                        <div className="text2">
                            {/*style={{marginLeft: "5.6rem"}}*/}
                            汪海蓓
                        </div>
                    </div>
                    <div className="img-div">
                        <img src={rectangle1} className="ground"/>
                        <img src={wanghaibei} className="img"/>
                    </div>
                    <div className="teacher-introduce">
                        {t("teacher_details.detail_2.describe_text1")}<br/><br/>
                        {/*{t("teacher_details.detail_2.describe_text2")}<br/><br/>*/}
                        {/*{t("teacher_details.detail_2.describe_text3")}<br/><br/>*/}
                    </div>
                    <a href="/teacher-phone" >
                        <div className="more_teachers">
                            <span className="text5">{t('teacher_details.more_teachers')}</span>
                        </div>
                    </a>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        );
    }
}

export default withTranslation()(TeacherDetail_phone_HaibeiWang);
