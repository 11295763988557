import React, { Component } from "react";
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import style from "./NewsDetail-phone-5.module.scss";
import name1 from "../../detail/img/name1.png";
import name2 from "../../detail/img/name2.png";
import name3 from "../../detail/img/name3.png";

import { withTranslation } from "react-i18next";

class NewsDetailPhone7 extends Component {
  constructor() {
    window.scrollTo(0, 0);
    super();
  }

  click_last() {
    this.props.history.push("/news-phone-detail-nine");
  }

  click_next() {
    this.props.history.push("/news-phone-detail-seven");
  }

  render() {
    const { t } = this.props;
    const language = localStorage.getItem("Language");
    return (
      <div>
        <Header_phone></Header_phone>
        <div className={style.newDetail}>
          <div className={style.main}>
            <div className={style.top_date}>
              <div className={style.icon}></div>
              <div>12/10/2022</div>
            </div>
            <div className={style.mbody}>
              <div className={style.top_text}>
                <span>{t("news.news_21_2022_12_title")}</span>
              </div>
              <div>
                <span className={style.detail_text}>
                  {t("news.news_21_2022_12_text1")}
                </span>
              </div>
							<div>
							  <span className={style.detail_text}>
                  {t("news.news_21_2022_12_text2")}
								</span>
							</div>
              <div>
                <span className={style.detail_text}>
									{t("news.news_21_2022_12_text3")}
								</span>
              </div>
              <div>
                <span
                  className={style.text_weight_black}
                  style={{
                    color: "red",
                    fontFamily: "Times New Roman",
                    fontSize: "11",
                  }}
                >
                  {t("news.news_21_2022_12_name1")}
                </span>
              </div>
              <div>
                <img
                  src={name1}
                  className={style.align_left}
                  style={{ display: "inline", float: "left" }}
                  alt=""
                />
              </div>
              <div className={style.detail_text}></div>
              <div
                className={style.detail_text}
                style={{
                  fontFamily: "Arial Unicode MS"
                }}
              >
                <span>{t("news.news_21_2022_12_name_detail1")}</span>
              </div>
              <div>
                <span
                  className={style.text_weight_black}
                  style={{
                    color: "red",
                    fontFamily: "Times New Roman",
                    fontSize: "11",
                  }}
                >
                  {t("news.news_21_2022_12_name2")}
                </span>
              </div>
              <div>
                <img
                  src={name2}
                  className={style.align_left}
                  style={{ display: "inline", float: "left" }}
                  alt=""
                />
              </div>
              <div className={style.detail_text}></div>
              <div
                className={style.detail_text}
                style={{
                  fontFamily: "Arial Unicode MS"
                }}
              >
                <span>{t("news.news_21_2022_12_name_detail2")}</span>
              </div>
              <div>
                <span
                  className={style.text_weight_black}
                  style={{
                    color: "red",
                    fontFamily: "Times New Roman",
                    fontSize: "11",
                  }}
                >
                  {t("news.news_21_2022_12_name3")}
                </span>
              </div>
              <div>
                <img
                  src={name3}
                  className={style.align_left}
                  style={{ display: "inline", float: "left" }}
                  alt=""
                />
              </div>
              <div className={style.detail_text}></div>
              <div
                className={style.detail_text}
                style={{
                  fontFamily: "Arial Unicode MS"
                }}
              >
                <span>{t("news.news_21_2022_12_name_detail3")}</span>
              </div>
              <div
                className={style.detail_text}
              >
                <span>{t("news.news_21_2022_12_ending")}</span>
              </div>
              <div className={style.detail_text_right}>
                <span>{t("news.seven_Sep_30_2022_9")}</span>
              </div>
            </div>
            <div className={style.detail_btn}>
              <div
                className={style.detail_btn_last}
                onTouchEnd={() => this.click_last()}
              >
                LAST
              </div>
              <div
                className={style.detail_btn_next}
                onTouchEnd={() => this.click_next()}
              >
                NEXT
              </div>
            </div>
          </div>
          <Tail_phone></Tail_phone>
        </div>
      </div>
    );
  }
}
export default withTranslation()(NewsDetailPhone7);
