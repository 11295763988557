import React, {Component} from "react";
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import style from "./NewsDetail-phone-5.module.scss";


import {withTranslation} from "react-i18next";
import AidanGold from "../../detail/img/AidanGold.png";
import ClaireHu from "../../detail/img/ClaireHu.png";

class NewsDetailPhone11 extends Component {
    constructor() {
        window.scrollTo(0, 0);
        super();
    }

    click_last() {
        this.props.history.push("/2nd-musicon-composition-competition");
    }

    click_next() {
        this.props.history.push("/news-phone-detail-ten");
    }

    render() {
        const {t} = this.props;
        const language = localStorage.getItem("Language");
        return (
            <div>
                <Header_phone></Header_phone>
                <div className={style.newDetail}>
                    <div className={style.main}>
                        <div className={style.top_date}>
                            <div className={style.icon}></div>
                            <div>11/14/2023</div>
                        </div>
                        <div className={style.mbody}>
                            <div className={style.top_text}>
                                <span>{t("news.news_11_2023_14_title")}</span>
                            </div>
                            <div>
								<span className={style.detail_text}>
								  {t("news.news_11_2023_14_text1")}
								</span>
							</div>
                            <div>
								<span className={style.detail_text}>
								  {t("news.news_11_2023_14_text2")}
								</span>
                            </div>
                            <div>
								<span className={style.detail_text}>
								  {t("news.news_11_2023_14_text3")}
								</span>
                            </div>

                            <div>
                                <span className={style.text_weight_black} style={{ color: "red", fontFamily: "Times New Roman", fontSize: "11", }} >
                                  {t("news.news_11_2023_14_name1")}
                                </span>
                            </div>
                            <div>
                                <img src={AidanGold} className={style.align_left} style={{ display: "inline", float: "left" }} alt="" />
                            </div>
                            <div className={style.detail_text}></div>
                            <div className={style.detail_text} style={{ fontFamily: "Arial Unicode MS" }} >
                                <span>{t("news.news_11_2023_14_detail1")}</span>
                            </div>

                            <div>
                                <span className={style.text_weight_black} style={{ color: "red", fontFamily: "Times New Roman", fontSize: "11", }} >
                                  {t("news.news_11_2023_14_name2")}
                                </span>
                            </div>
                            <div>
                                <img src={ClaireHu} className={style.align_left} style={{ display: "inline", float: "left" }} alt="" />
                            </div>
                            <div className={style.detail_text}></div>
                            <div className={style.detail_text} style={{ fontFamily: "Arial Unicode MS" }} >
                                <span>{t("news.news_11_2023_14_detail2")}</span>
                            </div>

                        </div>
                        <div className={style.detail_btn}>
                            <div
                                className={style.detail_btn_last}
                                onTouchEnd={() => this.click_last()}
                            >
                                LAST
                            </div>
                            <div
                                className={style.detail_btn_next}
                                onTouchEnd={() => this.click_next()}
                            >
                                NEXT
                            </div>
                        </div>
                    </div>
                    <Tail_phone></Tail_phone>
                </div>
            </div>
        );
    }
}

export default withTranslation()(NewsDetailPhone11);
