import React, { Component } from "react";
import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import style from "./NewsDetail-5.module.scss";

import { withTranslation } from "react-i18next";
import AidanGold from "./img/AidanGold.png";
import ClaireHu from "./img/ClaireHu.png";

class NewsDetail11 extends Component {
  constructor() {
    window.scrollTo(0, 0);
    super();
  }

  changeLast() {
    this.props.history.push("/2nd-musicon-composition-competition");
  }
  changeNext() {
    this.props.history.push("/detail-ten");
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Header></Header>

        <div className={style.leftCircle}></div>
        <div className={style.rightCircle}></div>

        <div className={style.main}>
          <div className={style.top_date}>
            <div></div>
            <div>11/14/2023</div>
          </div>
          <div className={style.mbody}>
            <div
              className={style.top_text}
            >
              <span>{t("news.news_11_2023_14_title")}</span>
            </div>
            <div>
              <span className={style.detail_text}>
                {t("news.news_11_2023_14_text1")}
              </span>
            </div>
            <div>
              <span className={style.detail_text}>
                {t("news.news_11_2023_14_text2")}
              </span>
            </div>
            <div>
              <span className={style.detail_text}>
                {t("news.news_11_2023_14_text3")}
              </span>
            </div>
            <div>
              <span
                className={style.text_weight_black}
                style={{
                  color: "red",
                  fontFamily: "Times New Roman",
                  fontSize: "11",
                }}
              >
                {t("news.news_11_2023_14_name1")}
              </span>
            </div>
            <div>
              <img
                src={AidanGold}
                className={style.align_left}
                style={{ display: "inline", float: "left" }}
                alt=""
              />
            </div>
            <div className={style.detail_text}></div>
            <div
              className={style.detail_text}
              style={{
                fontFamily: "Arial Unicode MS",
              }}
            >
              <span>{t("news.news_11_2023_14_detail1")}</span>
            </div>

            <div>
              <span
                className={style.text_weight_black}
                style={{
                  color: "red",
                  fontFamily: "Times New Roman",
                  fontSize: "11",
                }}
              >
                {t("news.news_11_2023_14_name2")}
              </span>
            </div>
            <div>
              <img
                src={ClaireHu}
                className={style.align_left}
                style={{ display: "inline", float: "left" }}
                alt=""
              />
            </div>
            <div className={style.detail_text}></div>
            <div className={style.detail_text} style={{ fontFamily: "Arial Unicode MS", }}>
              <span>{t("news.news_11_2023_14_detail2")}</span>
            </div>
          </div>
        </div>
        <div className={style.footer}>
          <div onClick={this.changeLast.bind(this)}>
            LAST
          </div>
          <div onClick={this.changeNext.bind(this)}>
            NEXT
          </div>
        </div>
        <div className={style.detailFooter}>
          <Tail></Tail>
        </div>
      </div>
    );
  }
}
export default withTranslation()(NewsDetail11);
