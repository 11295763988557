import React, {Component} from "react"

import {withTranslation} from "react-i18next";
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import line from "../../../projectDescription/img/line.png";

import "./Roster_phone.scss"
import crown from "../../img/crown.png";
import school from "../img/school.png"

class Roster_phone extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className="roster-phone-main">
                <Header_phone></Header_phone>
                <div className="roster-phone-content">
                    <div className="roster-title">
                        <img src={line}/>
                        <div className="text1">
                            Admission List：
                        </div>
                        <div className="text2">
                            录取名单
                        </div>
                    </div>

                    <div className="circular"></div>
                    <div className="cards" style={{marginTop:"19rem"}}>
                        <div className="left"></div>
                        <div className="right">
                            <div className="right-title">
                                <img src={crown}/>
                                <div className="school-year">
                                    2023
                                </div>
                            </div>
                            <hr size={1} className="dateline"/>
                            <div className="conList">
                                {t("roster.conList_2023_1")}
                                <br/>
                                <br/>
                                {t("roster.conList_2023_2")}
                                <br/>
                                <br/>
                                {t("roster.conList_2023_3")}
                                <br/>
                                <br/>
                                {t("roster.conList_2023_4")}
                            </div>
                        </div>
                    </div>
                    <div className="cards" style={{marginTop:"2rem"}}>
                        <div className="left"></div>
                        <div className="right">
                            <div className="right-title">
                                <img src={crown}/>
                                <div className="school-year">
                                    2022
                                </div>
                            </div>
                            <hr size={1} className="dateline"/>
                            <div className="conList">
                                {t("roster.conList_2022_1")}
                                <br/>
                                <br/>
                                {t("roster.conList_2022_2")}
                                <br/>
                                <br/>
                                {t("roster.conList_2022_3")}
                                <br/>
                                <br/>
                                {t("roster.conList_2022_4")}
                                <br/>
                                <br/>
                                {t("roster.conList_2022_5")}
                            </div>
                        </div>
                    </div>
                    <div className="cards" style={{marginTop:"2rem"}}>
                        <div className="left"></div>
                        <div className="right">
                            <div className="right-title">
                                <img src={crown}/>
                                <div className="school-year">
                                    2022
                                </div>
                            </div>
                            <hr size={1} className="dateline"/>
                            <div className="conList">
                                {t("roster.conList_2021_1")}
                                <br/><br/>
                                {t("roster.conList_2021_2")}
                            </div>
                        </div>
                    </div>
                    <div className="cards" style={{marginTop:"2rem"}}>
                        <div className="left"></div>
                        <div className="right">
                            <div className="right-title">
                                <img src={crown}/>
                                <div className="school-year">
                                    2022
                                </div>
                            </div>
                            <hr size={1} className="dateline"/>
                            <div className="conList">
                                {t("roster.conList_2020_1")}
                            </div>
                        </div>
                    </div>


                    <img src={school} className="roster-img"/>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        );
    }

}

export default withTranslation()(Roster_phone)
