import Header from "../../../../components/header/Header";
import Tail from "../../../../components/tail/Tail";
import React, {Component} from "react";
import line from "../img/thin-line.png";
import './detailCommon.scss'
import skillsImg from '../img/skills.png'
import WangMeng from "../img/wangmeng.png";
import {withTranslation} from "react-i18next"
import jiaorenhe from "../img/焦人和.png";
import xuyifei from "../img/许艺菲.png";

class Skills extends Component {

    linkToClick(val) {
        localStorage.setItem("route", val);
        this.props.history.push("/" + val);
        if (val == "music-course") {
            localStorage.setItem("object_state", "1");
        }
    }

    render() {
        const {t} = this.props
        return (

            <div>
                <Header></Header>
                <div className="composing">
                    <img src={skillsImg} className="composingImg"/>
                    <div className="detail_text">
                        <div className="main_title">
                            <div className="top_title">Aural Skills</div>
                            <div className="foot_title">视唱练耳课程</div>
                        </div>
                        <img src={line} alt="" className="thin-line"/>
                        <div className="detail_composing">
                            {t('musicCourse.skills_detail')}
                        </div>
                        <div className="teacher_title" style={localStorage.getItem('Language') == 'en' ? {
                            marginTop: '313px',
                            letterSpacing: '1px'
                        } : {marginTop: '73px'}}>{t('musicCourse.responsibleTeacher')}</div>
                        <div className="teacher_list">
                            {/*<div className="first-teacher" onClick={this.linkToClick.bind(this, "detail-MengWang")}>*/}
                            {/*    <img src={WangMeng} alt="" className="teacher-img"/>*/}
                            {/*    <div className="teacher_detail">*/}
                            {/*        <div className="teacher_name">王 萌</div>*/}
                            {/*        <div className="teacher_name_en">Meng Wang</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="second-teacher" onClick={this.linkToClick.bind(this, "detail-YifeiXu")}>*/}
                            {/*    <img src={xuyifei} alt="" className="teacher-img"/>*/}
                            {/*    <div className="teacher_detail">*/}
                            {/*        <div className="teacher_name">许艺菲</div>*/}
                            {/*        <div className="teacher_name_en">Yifei Xu</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="second-teacher" onClick={this.linkToClick.bind(this, "detail-RenheJiao")}>
                                <img src={jiaorenhe} alt="" className="teacher-img"/>
                                <div className="teacher_detail">
                                    <div className="teacher_name">焦人和</div>
                                    <div className="teacher_name_en">Renhe Jiao</div>
                                </div>
                            </div>
                        </div>
                        <a href="/music-course" onClick={this.linkToClick.bind(this, "music-course")}>
                            <div className="footer_btn"
                                 style={localStorage.getItem('Language') == 'en' ? {width: '165px'} : {width: '135px'}}>
                                <div className="btn-border"><span>{t('musicCourse.moreCourse')}</span></div>
                            </div>
                        </a>
                    </div>
                </div>
                <Tail></Tail>

            </div>

        )
    }
}

export default withTranslation()(Skills)
