import React, {Component} from "react";
import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import style from './NewsDetail-5.module.scss'
import news_detail_5_1 from '../img/news_detail_5_1.png';
import news_detail_5_2 from './img/news_detail_5_2.png';
import news_detail_5_3 from './img/news_detail_5_3.png';
import news_detail_7_1 from '../img/news_detail_7_1.png';

import {withTranslation} from "react-i18next"

class NewsDetail7 extends Component {
    constructor() {
        window.scrollTo(0, 0)
        super();
    }

    changeLast() {
        this.props.history.push("/detail-eight")
    }

    changeNext() {
        this.props.history.push("/1st-musicon-composition-competition")
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Header></Header>

                <div className={style.leftCircle}></div>
                <div className={style.rightCircle}></div>

                <div className={style.main}>
                    <div className={style.top_date}>
                        <div></div>
                        <div>09/30/2022</div>
                    </div>
                    <div className={style.mbody}>
                        <div className={style.top_text}>
                            <span>{t('news.seven_title_Sep_30_2022')}</span>
                        </div>
                        <div>
                            <span className={style.detail_text}>{t('news.seven_Sep_30_2022_1')}</span>
                        </div>
                        <div>
                            <span className={style.detail_text}>{t('news.seven_Sep_30_2022_2')}</span>
                        </div>
                        <div>
                            <span className={style.detail_text}>{t('news.seven_Sep_30_2022_3')}</span>
                        </div>
                        <div className={style.text_weight_black}>
                            <span>{t('news.seven_Sep_30_2022_4')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.seven_Sep_30_2022_5')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.seven_Sep_30_2022_5_1')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.seven_Sep_30_2022_5_2')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.seven_Sep_30_2022_5_3')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.seven_Sep_30_2022_5_4')}</span>
                        </div>
                        <div>
                            <span className={style.text_weight_black}>{t('news.seven_Sep_30_2022_6')}</span>
                        </div>
                        <img src={news_detail_7_1}/>
                        <div className={style.detail_text}>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.seven_Sep_30_2022_7')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.seven_Sep_30_2022_8')}</span>
                        </div>
                        <div className={style.detail_text_right}>
                            <span>{t('news.seven_Sep_30_2022_9')}</span>
                        </div>
                    </div>
                </div>
                <div className={style.footer}>
                    <div onClick={this.changeLast.bind(this)}>LAST</div>
                    <div onClick={this.changeNext.bind(this)}>NEXT</div>
                </div>

                <div className={style.detailFooter}>
                    <Tail></Tail>
                </div>

            </div>
        )
    }
}

export default withTranslation()(NewsDetail7)
