import React, { Component } from "react";
import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import style from "./NewsDetail-5.module.scss";

import { withTranslation } from "react-i18next";
import musiCON from "./img/musiCON.png";

class NewsDetail12 extends Component {
    constructor() {
        window.scrollTo(0, 0);
        super();
    }

    changeLast() {
        this.props.history.push("/2nd-musicon-composition-competition-finalists");
    }

    changeNext() {
        this.props.history.push("/detail-eleven");
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Header></Header>

                <div className={style.leftCircle}></div>
                <div className={style.rightCircle}></div>
                <div className={style.main}>
                    <div className={style.top_date}>
                        <div></div>
                        <div>4/8/2024</div>
                    </div>
                    <div className={style.mbody}>
                        <div className={style.top_text} style={{ textAlign: 'center' }}><span>{t("news.news_4_2024_8_title")}</span></div>
                        <div><img src={musiCON} className={style.align_left} style={{ display: "inline", width: "100%" }} alt="" /></div>
                        <div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_1")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_1")}</span></div>
                        <div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_2")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_2_1")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_2_2")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_2_3")}</span></div>
                        <div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_3")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_1")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_2")}</span></div>
                        <div><span className={style.detail_text} style={{whiteSpace:'pre-wrap'}}>{t("news.news_4_2024_8_text_3_3")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_4")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_5")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_6")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_7")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_8")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_9")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_10")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_11")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_3_12")}</span></div>
                        <div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_4")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_1")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_2")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_3")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_4")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_5")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_6")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_4_7")}</span></div>
                        <div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_5")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_5_1")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_5_2")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_5_3")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_5_4")}</span></div>
                        <div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_6")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_6_1")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_6_2")}</span></div>
                        <div><span className={style.chapter_text}>{t("news.news_4_2024_8_text_chapter_7")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_1")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_2")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_3")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_4")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_5")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_6")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_7")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_8")}</span></div>
                        <div><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_9")}</span></div>
                        <br/>
                        <br/>
                        <div style={{textAlign: 'right'}}><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_10")}</span></div>
                        <div style={{textAlign: 'right'}}><span className={style.detail_text}>{t("news.news_4_2024_8_text_7_11")}</span></div>
                    </div>
                </div>
                <div className={style.footer}>
                    <div onClick={this.changeLast.bind(this)}>
                        LAST
                    </div>
                    <div onClick={this.changeNext.bind(this)}>
                        NEXT
                    </div>
                </div>

                <div className={style.detailFooter}>
                    <Tail></Tail>
                </div>
            </div>
        );
    }
}

export default withTranslation()(NewsDetail12);
