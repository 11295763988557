import React, {Component} from "react"

import {withTranslation} from "react-i18next";
import Header_phone from "../../components/header/Header_phone";
import Tail_phone from "../../components/tail/Tail_phone";

import "./Project_phone.scss"

import line from "./img/line.png"
import courseImg from "./musicCourse/img/course.png";
import production from "./musicProduction/img/production.png";
import studyAbroadImg from './studyAbroad/img/study-abroad.png'


class Project_phone extends Component {

    linkToClick(url) {
        this.props.history.push("/" + url);
    }

    render() {
        const {t} = this.props;
        return (
            <div className="project-phone-main">
                <Header_phone></Header_phone>
                <div className="project-phone-content">
                    <div className="project-title">
                        <img src={line}/>
                        <div className="text1">
                            Programs：
                        </div>
                        <div className="text2">
                            项目介绍
                        </div>
                    </div>
                    <div className="project-A">
                        <div className="project-bg-A" onTouchEnd={()=>this.linkToClick("musicCourse-phone")}>
                            <img src={courseImg} className="img" />
                            <span className="text-A">A</span>
                            <span className="text" style={localStorage.getItem('Language') == 'zh' ? {letterSpacing: '0.4rem'} : {}}>{t("header.project.project_a")}</span>
                        </div>
                    </div>
                    <div className="project-B">
                        <div className="project-bg-B"  onTouchEnd={()=>this.linkToClick("studyAbroad-phone")}>
                            <img src={production} className="img"/>
                            <span className="text-B">B</span>
                            <span className="text" style={localStorage.getItem('Language') == 'zh' ? {letterSpacing: '0.4rem'} : {}}>{t("header.project.project_b")}</span>
                        </div>
                    </div>
                    <div className="project-C">
                        <div className="project-bg-C" onTouchEnd={()=>this.linkToClick("musicProduction-phone")}>
                            <img src={studyAbroadImg} className="img"/>
                            <span className="text-C">C</span>
                            <span className="text" style={localStorage.getItem('Language') == 'zh' ? {letterSpacing: '0.4rem'} : {}}>{t("header.project.project_c")}</span>
                        </div>
                    </div>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        );
    }
}

export default withTranslation()(Project_phone);
