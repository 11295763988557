import header_zh from "./locales/zh/header_zh.json"
import header_en from "./locales/en/header_en.json"
import teacher_details_zh from "./locales/zh/teacher_details_zh.json"
import teacher_details_en from "./locales/en/teacher_details_en.json"
import about_zh from "./locales/zh/about_zh.json"
import about_en from "./locales/en/about_en.json"
import musicProduction_en from './locales/en/musicProduction_en.json'
import musicProduction_zh from './locales/zh/musicProduction_zh.json'
import musicCourse_zh from './locales/zh/musicCourse_zh.json'
import musicCourse_en from './locales/en/musicCourse_en.json'
import news_en from './locales/en/news_en.json'
import news_zh from './locales/zh/news_zh.json'
import studyAbroad_zh from './locales/zh/studyAbroad_zh.json'
import studyAbroad_en from './locales/en/studyAbroad_en.json'
import home_en from './locales/en/home_en'
import home_zh from './locales/zh/home_zh.json'
import teacher_en from './locales/en/teacher_en'
import teacher_zh from './locales/zh/teacher_zh.json'
import roster_en from './locales/en/roster_en'
import roster_zh from './locales/zh/roster_zh.json'
import contact_en from './locales/en/contact_en'
import contact_zh from './locales/zh/contact_zh.json'

import tail_en from './locales/en/tail_en'
import tail_zh from './locales/zh/tail_zh'

export default {
    zh: {
        translation: {
            header: header_zh,
            home: home_zh,
            teacher_details: teacher_details_zh,
            about: about_zh,
            musicProduction: musicProduction_zh,
            musicCourse: musicCourse_zh,
            news: news_zh,
            studyAbroad: studyAbroad_zh,
            teacher: teacher_zh,
            roster: roster_zh,
            contact: contact_zh,
            tail: tail_zh
        }
    },
    en: {
        translation: {
            header: header_en,
            home: home_en,
            teacher_details: teacher_details_en,
            about: about_en,
            musicProduction: musicProduction_en,
            musicCourse: musicCourse_en,
            news: news_en,
            studyAbroad: studyAbroad_en,
            teacher: teacher_en,
            roster: roster_en,
            contact: contact_en,
            tail: tail_en
        }
    },
};
