import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import Header_phone from "../../../components/header/Header_phone";
import Tail_phone from "../../../components/tail/Tail_phone";
import line from "../../projectDescription/img/line.png";

import "./News_phone.scss";
import cup from "../img/cup.png";
import violin from "../img/violin.png";
import piano from "../img/piano.png";
import paper from "../img/paper.png";
import volin2 from "../img/violin-2.png";
import paper2 from "../img/paper-2.png";
import city from "../img/victory.png";
import detail5 from "../img/news_detail_5_1.png";
import detail7 from "../img/news_detail_7_1.png";

class News_phone extends Component {
  linkToClick(url) {
    this.props.history.push("/" + url);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="news-phone-main">
        <Header_phone></Header_phone>
        <div className="max-circle"></div>

        <div className="news-phone-content">
          <div className="news-title">
            <img src={line} />
            <div className="text1">Latest News & Events：</div>
            <div className="text2">艺术中心新闻</div>
          </div>

          <div className="news-content">
            <div
                className="item-content"
                onTouchEnd={() => this.linkToClick("2nd-musicon-composition-competition-finalists")}
            >
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>09/10/2024</span>
                </div>
                <div className="text1">{t("news.news_9_2024_10_title")}</div>
                <div className="text2">{t("news.news_9_2024_10_text")}</div>
              </div>
            </div>

            <div
                className="item-content"
                onTouchEnd={() => this.linkToClick("2nd-musicon-composition-competition")}
            >
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>04/08/2024</span>
                </div>
                <div className="text1">{t("news.news_4_2024_8_title")}</div>
                <div className="text2">{t("news.news_4_2024_8_text")}</div>
              </div>
            </div>
            <div
                className="item-content"
                onTouchEnd={() => this.linkToClick("news-phone-detail-eleven")}
            >
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>11/14/2023</span>
                </div>
                <div className="text1">{t("news.news_11_2023_14_title")}</div>
                <div className="text2">{t("news.news_11_2023_14_text")}</div>
              </div>
            </div>

            <div
                className="item-content"
                onTouchEnd={() => this.linkToClick("news-phone-detail-ten")}
            >
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>05/31/2023</span>
                </div>
                <div className="text1">{t("news.news_5_2023_31_title")}</div>
                <div className="text2">{t("news.news_5_2023_31_text")}</div>
              </div>
            </div>
            <div
                className="item-content"
                onTouchEnd={() => this.linkToClick("news-phone-detail-nine")}
            >
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>03/07/2023</span>
                </div>
                <div className="text1">{t("news.news_7_2023_3_title")}</div>
                <div className="text2">{t("news.news_7_2023_3_text")}</div>
              </div>
            </div>

            <div
              className="item-content"
              onTouchEnd={() => this.linkToClick("news-phone-detail-eight")}
            >
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>12/10/2022</span>
                </div>
                <div className="text1">{t("news.news_21_2022_12_title")}</div>
                <div className="text2">{t("news.news_21_2022_12_text")}</div>
              </div>
            </div>
            <div
              className="item-content"
              onTouchEnd={() => this.linkToClick("news-phone-detail-seven")}
            >
              <div className="img-item">
                <img src={detail7} />
              </div>
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>09/30/2022</span>
                </div>
                <div className="text1">{t("news.seven_title_Sep_30_2022")}</div>
                <div className="text2">
                  {t("news.seven_detail_Sep_30_2022")}
                </div>
              </div>
            </div>
            <div
              className="item-content"
              onTouchEnd={() => this.linkToClick("1st-musicon-composition-competition")}
            >
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>08/01/2022</span>
                </div>
                <div className="text1">
                  {t("news.second_title_July_27_2022")}
                </div>
                <div className="text2">
                  {t("news.second_detail_July_27_2022")}
                </div>
              </div>
            </div>
            <div
              className="item-content"
              onTouchEnd={() => this.linkToClick("news-phone-detail-five")}
            >
              <div className="img-item">
                <img src={city} />
              </div>
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>07/27/2022</span>
                </div>
                <div className="text1">{t("news.sixth_text_student")}</div>
                <div className="text2">{t("news.sixth_text_facemask")}</div>
              </div>
            </div>
            <div
              className="item-content"
              onTouchEnd={() => this.linkToClick("news-phone-detail-four")}
            >
              <div className="img-item">
                <img src={detail5} />
              </div>
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>07/11/2022</span>
                </div>
                <div className="text1">
                  {t("news.first_title_July_27_2022")}
                </div>
                <div className="text2">
                  {t("news.first_detail_July_27_2022")}
                </div>
              </div>
            </div>
            <div
              className="item-content"
              onTouchEnd={() => this.linkToClick("news-phone-detail-three")}
            >
              <div className="img-item">
                <img src={violin} />
              </div>
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>03/08/2021</span>
                </div>
                <div className="text1">{t("home.news.first_text_title")}</div>
                <div className="text2">{t("home.news.first_text_detail")}</div>
              </div>
            </div>
            <div
              className="item-content"
              onTouchEnd={() => this.linkToClick("news-phone-detail-two")}
            >
              <div className="img-item">
                <img src={piano} />
              </div>
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>03/05/2021</span>
                </div>
                <div className="text1">{t("home.news.second_text_title")}</div>
                <div className="text2">{t("home.news.second_text_detail")}</div>
              </div>
            </div>
            <div
              className="item-content"
              onTouchEnd={() => this.linkToClick("news-phone-detail-one")}
            >
              <div className="img-item">
                <img src={paper} />
              </div>
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>02/22/2021</span>
                </div>
                <div className="text1">{t("home.news.third_text_title")}</div>
                <div className="text2">{t("home.news.third_text_detail")}</div>
              </div>
            </div>
            <div className="item-content">
              <div className="img-item">
                <img src={cup} />
              </div>
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>02/04/2021</span>
                </div>
                <div className="text1">{t("news.fourth_text_title")}</div>
                <div className="text2">{t("news.fourth_text_detail")}</div>
              </div>
            </div>
            <div className="item-content">
              <div className="img-item">
                <img src={volin2} />
              </div>
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>01/24/2021</span>
                </div>
                <div className="text1">{t("news.fifth_text_title")}</div>
                <div className="text2">{t("news.fifth_text_detail")}</div>
              </div>
            </div>
            <div className="item-content">
              <div className="img-item">
                <img src={paper2} />
              </div>
              <div className="text-item">
                <div className="date">
                  <div className="icon"></div>
                  <span>11/24/2020</span>
                </div>
                <div className="text1">{t("news.sixth_text_title")}</div>
                <div className="text2">{t("news.sixth_text_detail")}</div>
              </div>
            </div>
          </div>
        </div>
        <Tail_phone></Tail_phone>
      </div>
    );
  }
}

export default withTranslation()(News_phone);
