import React, {Component} from "react"
import "./Tail_phone.scss"
import file from './img/file---47@1x.png'

import icon_phone_1 from "../homeTail/img/icon_phone_1.png"
import icon_phone_2 from "../homeTail/img/icon_phone_2.png"
import icon_phone_3 from "../homeTail/img/icon_phone_3.png"
import icon_phone_4 from "../homeTail/img/icon_phone_4.png"
import note from "./img/note.png"
import {withTranslation} from "react-i18next";

class Tail_phone extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className="Tail-phone-main">

                <img className="note" src={note}/>
                <div className="Tail-one">
                    <span className="text1">MAYFLOWER ART CENTER</span>
                    <span className="text2">美好艺术中心</span>
                    <span className="text3">Email:  office@mayflowerartcenter.org</span>
                    <span className="text3">Address: Andover, Massachusetts</span>
                </div>

                <div className="Tail-two">
                    <div className="div-img">
                        <img src={icon_phone_1}/>
                        <img src={icon_phone_2}/>
                        <img src={icon_phone_3}/>
                        <img src={icon_phone_4}/>
                    </div>
                    <div className="text">
                        © MAYFLOWER ART CENTER 2021. All Rights Reserved.
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Tail_phone);
