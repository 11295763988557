import Header from "../../../../components/header/Header";
import Tail from "../../../../components/tail/Tail";
import React, {Component} from "react";
import './detailCommon.scss'
import line from '../img/thin-line.png'
import yangjin from '../img/郑育婷.png'
import claImg from '../img/pexels-photo-7970108.png'
import {withTranslation} from "react-i18next"

class Clarinet extends Component {

    linkToClick(val) {
        localStorage.setItem("route", val);
        this.props.history.push("/" + val);
        if (val == "music-course") {
            localStorage.setItem("object_state", "1");
        }
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Header></Header>
                <div className="composing"
                     style={localStorage.getItem('Language') == 'en' ? {height: '2218px'} : {height: '2018px'}}>
                    <img src={claImg} className="composingImg"/>
                    <div className="detail_text">
                        <div className="main_title">
                            <div className="top_title">Clarinet</div>
                            <div className="foot_title">单簧管课程</div>
                        </div>
                        <img src={line} alt="" className="thin-line"/>
                        <div className="detail_clarinet"
                             style={localStorage.getItem('Language') == 'en' ? {height: '1280px'} : {height: '1200px'}}>
                            {t('musicCourse.clarinet_detail_first')}
                            <br/> <br/>
                            {t('As a professional clarinet player and a passionate educator, my goal is to inspire students to be effective learners who can be self-directed, developing their passion for the clarinet, and helping them achieve artistic excellence. A holistic learning system is formed to achieve goals at all stages by encouraging students to set clear goals, both short-term and long-term, and achieve them with effective practice.')}
                            <br/> <br/>
                            {t('The clarinet is often considered the closest instrument to the human voice because of its unique tone and range. I insist that students must “sing” through the clarinet like a vocalist and develop their sense of hearing to produce a natural musical sensation, breathing, expression, and the ability to choose the right style and diction, while not only focusing on playing the right notes mechanically. In addition to having fine musicality, I expect my student to work hard on the fundamentals of playing. That can contribute to producing wonderful sound quality, including voice production, breathing support, vocalization, strumming, intonation and reed making, etc. Regarding improving techniques, I provide students with effective practice strategies such as problem-solving, time management, perseverance, and creative thinking. With all the above, my students can overcome technical challenges with efficiency and confidence.')}
                            <br/> <br/>
                            {t('I believe allowing students to explore the artistry of the clarinet, encouraging collaboration, and innovative attempts can create a profound impact on their growth. Regular seminars and recitals can stimulate their motivation and accumulate confidence and performance experience. After graduating from my program, my students will possess the necessary musical skills and be able to thrive and succeed in their musical careers.')}
                        </div>
                        <div className="teacher_title"
                             style={localStorage.getItem('Language') == 'en' ? {letterSpacing: '1px'} : {}}>{t('musicCourse.responsibleTeacher')}</div>
                            <div className="teacher_list">
                                <div className="first_teacher" onClick={this.linkToClick.bind(this, "detail-YutingZheng")}>
                                    <img src={yangjin} alt="" className="teacher-img"/>
                                    <div className="teacher_detail">
                                        <div className="teacher_name">郑育婷</div>
                                        <div className="teacher_name_en">YuTing Zheng</div>
                                    </div>
                                </div>
                            </div>
                        <a href="/music-course" onClick={this.linkToClick.bind(this, "music-course")}>
                            <div className="footer_btn"
                                 style={localStorage.getItem('Language') == 'en' ? {width: '165px'} : {width: '135px'}}>
                                <div className="btn-border"><span>{t('musicCourse.moreCourse')}</span></div>
                            </div>
                        </a>
                    </div>
                </div>
                <Tail></Tail>

            </div>

        )
    }
}

export default withTranslation()(Clarinet)
