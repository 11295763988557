import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './Honors.css';

import file_black from './img/file-black.png'
import file_white from './img/file-white.png'
import $ from "jquery";
import {withTranslation} from "react-i18next"

class Honors extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            type: true,
            status: false,
            news_class_state: true
        })
        if (props.onRef) {//如果父组件传来该方法 则调用方法将子组件this指针传过去
            props.onRef(this)
        }
    }

    componentWillMount() {
        if (localStorage.getItem("honors_state") == "1") {
            this.setState({
                type: false,
                status: true
            })
        }

        if (localStorage.getItem("route") == "news" || localStorage.getItem("route") == "musiCON-International-Composition-Competition") {
            this.setState({
                news_class_state: false
            })
        }
    }

    show() {

        if (this.state.type) {
            this.setState({
                status: true
            })
        }
        if (this.state.news_class_state == false) {
            $("#honors_active").css('color', "#ffffff")
        }
    }

    hide() {
        if (this.state.type) {
            this.setState({
                status: false
            })
        }
        if(this.state.news_class_state == false){
            $("#honors_active").css('color',"#333333")
        }
    }

    click(val) {
        localStorage.setItem("route", val)
        localStorage.setItem("honors_state", "1")
        this.setState({
            type: false
        })
        this.show()
        if (val != "music-course" && val != "study-abroad" && val != "music-production") {
            localStorage.setItem("object_state", "0")
        }
        if(val != "musiCON-International-Composition-Competition"){
            localStorage.setItem("musiCON_state", "0")
        }
    }
    changeClassState() {
        this.setState({
            news_class_state: true
        })
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <div className={' Introdece'}
                     onMouseOver={this.show.bind(this)}
                     onMouseLeave={this.hide.bind(this)}
                >
                    <span id="honors_active" className={this.state.type ? "cursor-active" : (this.state.news_class_state?"active": "active-news") }
                    >{t("header.honors.name")}</span>

                    {
                        this.state.status &&
                        <div className='honor-group'>
                            <Link to='/roster' style={{textDecoration: 'none'}} onClick={this.click.bind(this, "roster")}>
                                <div className={this.state.news_class_state ?
                                    (localStorage.getItem("Language") == "zh"?"text-21-h-zh":"text-21-h-en") :
                                    (localStorage.getItem("Language") == "zh"?"text-21-h-zh text-white":"text-21-h-en text-white")}>
                                    {t("header.honors.admission_list")}
                                    {/*录取名单*/}
                                </div>
                            </Link>
                            <Link to='/award' style={{textDecoration: 'none'}} onClick={this.click.bind(this, "award")}>
                                <div className={this.state.news_class_state ?
                                    (localStorage.getItem("Language") == "zh" ? "text-22-h-zh" : "text-22-h-en") :
                                    (localStorage.getItem("Language") == "zh" ? "text-22-h-zh text-white" : "text-22-h-en text-white")}>
                                    {t("header.honors.awards")}
                                    {/*作曲奖项*/}
                                </div>
                            </Link>
                            <img className={this.state.news_class_state ? "honor-file-black" : "honor-file-white"}
                                   src={this.state.news_class_state ? file_black : file_white}/>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
export default withTranslation()(Honors);
