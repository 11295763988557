import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './Project.css';
import file_black from './img/file-black.png'
import file_white from './img/file-white.png'
import $ from 'jquery'
import {withTranslation} from "react-i18next"

class Project extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            type: true,
            status: false,
            object_class_state: true,
            news_class_state: true
        })
        if (props.onRef) {//如果父组件传来该方法 则调用方法将子组件this指针传过去
            props.onRef(this)
        }
    }

    componentWillMount() {
        if (localStorage.getItem("object_state") == "1") {
            this.setState({
                type: false,
                status: true
            })
        }
        if (localStorage.getItem("route") == "music-course" || localStorage.getItem("route") == "study-abroad") {
            this.setState({
                object_class_state: false
            })
        }

        if (localStorage.getItem("route") == "news") {
            this.setState({
                object_class_state: false,
                news_class_state: false
            })
        }


    }

    changeClassState() {
        this.setState({
            object_class_state: true,
            news_class_state: true
        })
    }

    show() {

        if (this.state.type) {
            this.setState({
                status: true
            })
        }
        if (this.state.news_class_state == false) {
            $("#object_active").css('color', "#ffffff")
        }
    }

    hide() {
        if (this.state.type) {
            this.setState({
                status: false
            })
        }
        if (this.state.news_class_state == false) {
            $("#object_active").css('color', "#333333")
        }
    }

    click(val) {
        localStorage.setItem("route", val)
        localStorage.setItem("object_state", "1")
        this.setState({
            type: false
        })
        this.show()
        if (val != "roster" && val != "award") {
            localStorage.setItem("honors_state", "0")
        }

        if(val != "musiCON-International-Composition-Competition"){
            localStorage.setItem("musiCON_state", "0")
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <div className="Introdece"
                     onMouseOver={this.show.bind(this)}
                     onMouseLeave={this.hide.bind(this)}
                >
                    <span id="object_active"
                          className={this.state.type ? "cursor-active" : (this.state.news_class_state ? "active" : "active-news")}
                    >
                        {t("header.project.name")}
                    </span>

                    {
                        this.state.status &&
                        <div className='overlap-group7'>
                            <Link to='/music-course' style={{textDecoration: 'none'}}
                                  onClick={this.click.bind(this, "music-course")}>
                                <div className={this.state.object_class_state ?
                                    (localStorage.getItem("Language") == "zh" ? "text-21-zh" : "text-21-en") :
                                    (localStorage.getItem("Language") == "zh" ? "text-21-zh text-white" : "text-21-en text-white")}>
                                    {/*音乐课程*/}
                                    {t("header.project.project_a")}
                                </div>
                            </Link>
                            <Link to='/study-abroad' style={{textDecoration: 'none'}}
                                  onClick={this.click.bind(this, "study-abroad")}>
                                <div className={this.state.object_class_state ?
                                    (localStorage.getItem("Language") == "zh" ? "text-22-zh" : "text-22-en") :
                                    (localStorage.getItem("Language") == "zh" ? "text-22-zh text-white" : "text-22-en text-white")}>
                                    {/*音乐留学*/}
                                    {t("header.project.project_b")}
                                </div>
                            </Link>
                            <Link to='/music-production' style={{textDecoration: 'none'}}
                                  onClick={this.click.bind(this, "music-production")}>
                                <div className={this.state.object_class_state ?
                                    (localStorage.getItem("Language") == "zh" ? "text-23-zh" : "text-23-en") :
                                    (localStorage.getItem("Language") == "zh" ? "text-23-zh text-white" : "text-23-en text-white")}>
                                    {/*音乐制作*/}
                                    {t("header.project.project_c")}
                                </div>
                            </Link>
                            <img className={this.state.object_class_state ? "file-black" : "file-white"}
                                 src={this.state.object_class_state ? file_black : file_white}/>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withTranslation()(Project);
