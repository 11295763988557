import React, {Component} from "react";
import Header from "../../components/header/Header";
import "./Home.scss"
import home_line from "./img/home-line.png"

import about_n from "./img/navigation/about.png"
import award_n from "./img/navigation/award.png"
import contact_n from "./img/navigation/contact.png"
import teacher_n from "./img/navigation/teacher.png"
import project_n from "./img/navigation/project.png"
import news_n from "./img/navigation/news.png"
import navigation_line from "./img/navigation/navigation-line.png"

import about_c from "./img/content/about.png"
import award_c from "./img/content/award.png"
import contact_c from "./img/content/contact.png"
import teacher_c from "./img/content/teacher.png"
import project_c from "./img/content/project.png"
import news_c from "./img/content/news.png"

import piano from "../about/img/piano.png"
import pianoBackground from "../about/img/pianoBackground.png"
import project_A from "./img/content/project_A.png"
import project_B from "./img/content/project_B.png"
import project_C from "./img/content/project_C.png"

import img_A from "./img/content/img_A.png"
import img_B from "./img/content/img_B.png"
import img_C from "./img/content/img_C.png"

import teacher_1_img from "./img/content/teacher_1_img.png"
import teacher_1_bg from "./img/content/teacher_1_bg.png"
import teacher_2_img from "./img/content/teacher_2_img.png"
import teacher_2_bg from "./img/content/teacher_2_bg.png"
import teacher_3_img from "./img/content/teacher_3_img.png"
import teacher_3_bg from "./img/content/teacher_3_bg.png"
import award_img from "./img/content/award_img.png"
// import news_img1 from "./img/content/news-img1.png"
import news_img2 from "../news/img/news_detail_5_1.png"
import news_img3 from "../news/img/victory.png"
import news_img7 from "../news/img/news_detail_7_1.png"
import contact_icon from "./img/content/contact-icon.png"
import contact_wixin_icon from "./img/content/contact-wixin-icon.png"
import strip from "./img/navigation/strip.png"
import OR_code_one from "./img/content/QR-code-one.png"
import OR_code_two from "./img/content/QR-code-two.png"
import HomeTail from "../../components/homeTail/HomeTail";

import {withTranslation} from "react-i18next"
import $ from 'jquery'

// 首页
class Home extends Component {

    constructor() {
        super();
        this.state = ({
            award_state: 0,
            project_state: 1,
            about_state: 0,
            strip_state: 0,
            contact_state: 1,
            home_tail_state: 1
        })
    }

    componentWillMount() {
        localStorage.setItem("route", "homepage-preview")
        localStorage.setItem("object_state", "0")
        localStorage.setItem("honors_state", "0")
        if (!localStorage.getItem("Language")) {
            localStorage.setItem("Language", "en")
        }

        this.setState({
            award_state: 0,
            project_state: 1,
            about_state: 0,
            strip_state: 0,
            contact_state: 1,
            home_tail_state: 1
        })


    }

    awardClick() {
        if (this.state.award_state == 0) {
            $("#award_item_content").animate({
                marginTop: "380px"
            }, 1000)

            $("#award_img").animate({
                width: "586px",
                height: "490px"
            }, 1000)
            $("#contact_p").animate({
                marginTop: "-280px",
                left: "1140px"
            }, 1000)
            $("#award_left_text").animate({
                marginTop: "-380px",
                marginLeft: "960px",
            }, 1000)
            $("#award_left_text").css('pointer-events', "none")
            $("#award_item_content_top").animate({
                marginTop: "0px",
                marginLeft: "240px"
            }, 1000)
            $("#award_item_content_top").css('pointer-events', "none")

            $("#award_top_more").css('display', "block")


            $("#top_right1").animate({
                marginRight: "30px"
            }, 1000)
            $("#top_right2").animate({
                marginRight: "30px"
            }, 1000)
            $("#award_more").animate({
                marginLeft: "690px",
                marginTop: "460px"
            }, 1000)

            this.setState({
                award_state: 1
            })

        } else {
            this.linkToClick("award")
        }
    }

    stripClick() {

        if (this.state.strip_state == 0) {
            this.stripStart();
            this.aboutStart();
            this.projectStart();
            this.contactStart();
            this.setState({
                about_state: 1,
                strip_state: 1,
                project_state: 0,
                contact_state: 0
            })


        } else {

            this.stripEnd();
            this.aboutEnd();
            this.projectEnd();
            this.contactEnd();
            this.setState({
                about_state: 0,
                strip_state: 0,
                project_state: 1,
                contact_state: 1

            })

        }
    }

    stripStart() {
        $("#strip").animate({
            marginLeft: "10px",
        }, 1000)

        $("#letter_m").animate({
            top: "180px",
        }, 1000)
        $("#letter_a").animate({
            top: "180px",
        }, 1000)
        $("#letter_y").animate({
            top: "180px",
        }, 1000)
        $("#letter_f").animate({
            top: "180px",
        }, 1000)
        $("#letter_l").animate({
            top: "180px",
        }, 1000)
        $("#letter_o").animate({
            top: "180px",
        }, 1000)
        $("#letter_w").animate({
            top: "180px",
        }, 1000)
        $("#letter_e").animate({
            top: "180px",
        }, 1000)
        $("#letter_r").animate({
            top: "180px",
        }, 1000)

        $("#line_1").animate({
            top: "78px",
        }, 1000)
        $("#line_3").animate({
            top: "78px",
        }, 1000)
        $("#line_4").animate({
            top: "78px",
        }, 1000)
        $("#line_5").animate({
            top: "78px",
        }, 1000)
        $("#line_6").animate({
            top: "78px",
        }, 1000)
        $("#line_7").animate({
            top: "78px",
        }, 1000)
        $("#line_8").animate({
            top: "78px",
        }, 1000)
    }

    stripEnd() {
        $("#strip").animate({
            marginLeft: "-770px",
        }, 1000)

        $("#letter_m").animate({
            top: "160px",
        }, 1000)
        $("#letter_a").animate({
            top: "60px",
        }, 1000)
        $("#letter_y").animate({
            top: "130px",
        }, 1000)
        $("#letter_f").animate({
            top: "270px",
        }, 1000)
        $("#letter_l").animate({
            top: "200px",
        }, 1000)
        $("#letter_o").animate({
            top: "140px",
        }, 1000)
        $("#letter_w").animate({
            top: "200px",
        }, 1000)
        $("#letter_e").animate({
            top: "24px",
        }, 1000)
        $("#letter_r").animate({
            top: "380px",
        }, 1000)


        $("#line_1").animate({
            top: "8px",
        }, 1000)
        $("#line_3").animate({
            top: "148px",
        }, 1000)
        $("#line_4").animate({
            top: "108px",
        }, 1000)
        $("#line_5").animate({
            top: "5px",
        }, 1000)
        $("#line_6").animate({
            top: "48px",
        }, 1000)
        $("#line_7").animate({
            top: "108px",
        }, 1000)
        $("#line_8").animate({
            top: "218px",
        }, 1000)
    }

    projectClick(value) {

        if (this.state.project_state == 0) {

            this.projectEnd();
            this.stripEnd();
            this.aboutEnd();
            this.contactEnd();
            this.setState({
                about_state: 0,
                project_state: 1,
                strip_state: 1,
                contact_state: 1
            })

        } else {
            if (value == "A") {
                this.linkToClick("music-course")
            } else if (value == "B") {
                this.linkToClick("study-abroad")
            } else {
                this.linkToClick("music-production")
            }

        }
    }

    projectStart() {
        $("#project_img_A_top").css('pointer-events', "auto")
        $("#project_img_A_top").css('cursor', "pointer")
        $("#project_img_A_top").animate({
            marginTop: "-100px",
            marginLeft: "-209px",
        }, 1000)
        $("#project_img_A").animate({
            marginTop: "-230px",
            marginLeft: "-82px"
        }, 1000)

        $("#project_img_B_top").css('pointer-events', "auto")
        $("#project_img_B_top").css('cursor', "pointer")
        $("#project_img_B_top").animate({
            marginTop: "-50px",
            marginLeft: "100px"
        }, 1000)
        $("#project_img_B").animate({
            marginTop: "60px",
            marginLeft: "-82px"
        }, 1000)

        if (localStorage.getItem("Language") == "zh") {
            $("#project_text_B").animate({
                marginTop: "-54px",
                marginRight: "-150px"
            }, 1000)
        } else {
            $("#project_text_B").animate({
                marginTop: "-54px",
            }, 1000)
        }


        $("#project_img_C_top").css('pointer-events', "auto")
        $("#project_img_C_top").css('cursor', "pointer")
        $("#project_img_C_top").animate({
            marginTop: "180px",
            marginLeft: "165px"
        }, 1000)
        $("#project_img_C").animate({
            marginTop: "-580px",
            marginLeft: "292px"
        }, 1000)
        $("#project_text_C").animate({
            marginTop: "180px",
            marginRight: "-240px"
        }, 1000)
    }

    projectEnd() {
        $("#project_img_A_top").css('pointer-events', "none")
        $("#project_img_A_top").animate({
            marginTop: "46px",
            marginLeft: "-20px",
        }, 1000)
        $("#project_img_A").animate({
            marginTop: "-140px",
            marginLeft: "-32px"
        }, 1000)

        $("#project_img_B_top").css('pointer-events', "none")
        $("#project_img_B_top").animate({
            marginTop: "46px",
            marginLeft: "-20px"
        }, 1000)
        if (localStorage.getItem("Language") == "zh") {
            $("#project_img_B").animate({
                marginTop: "-140px",
                marginLeft: "-32px"
            }, 1000)
        } else {
            $("#project_img_B").animate({
                marginTop: "-184px",
                marginLeft: "-32px"
            }, 1000)
        }


        $("#project_text_B").animate({
            marginTop: "10px",
            marginRight: "-16px"
        }, 1000)


        $("#project_img_C_top").css('pointer-events', "none")
        $("#project_img_C_top").animate({
            marginTop: "46px",
            marginLeft: "-20px"
        }, 1000)
        $("#project_img_C").animate({
            marginTop: "-140px",
            marginLeft: "-32px"
        }, 1000)
        $("#project_text_C").animate({
            marginTop: "10px",
            marginRight: "-16px"
        }, 1000)
    }

    aboutClick() {
        if (this.state.about_state == 0) {
            this.aboutStart();
            this.projectStart();
            this.stripStart();
            this.contactStart();
            this.setState({
                about_state: 1,
                project_state: 0,
                strip_state: 1,
                contact_state: 0
            })

        } else {
            this.linkToClick("about-us")
        }

    }

    aboutStart() {
        $("#about_text1").animate({
            marginLeft: "0px",
            letterSpacing: "0px"
        }, 1000)

        $("#about_text2").animate({
            marginLeft: "140px",
            letterSpacing: "0px"
        }, 1000)

        $("#about_text3").animate({
            marginLeft: "140px"
        }, 1000)

        $("#text_button").animate({
            marginLeft: "140px"
        }, 1000)

        $("#img_piano").animate({
            width: "923px",
            height: "633px",
            top: "-620px",
            right: "-640px"
        }, 1000)

        $("#img_pianoBackground").animate({
            width: "904px",
            height: "542px",
            top: "-1178px",
            right: "-690px"

        }, 1000)
    }

    aboutEnd() {
        $("#about_text1").animate({
            marginLeft: "72px",
            letterSpacing: "10px"
        }, 1000)

        $("#about_text2").animate({
            marginLeft: "-60px",
            letterSpacing: "10px"
        }, 1000)

        $("#about_text3").animate({
            marginLeft: "-60px"
        }, 1000)

        $("#text_button").animate({
            marginLeft: "120px"
        }, 1000)

        $("#img_piano").animate({
            width: "1377px",
            height: "805px",
            top: "-760px",
            right: "-270px"
        }, 1000)

        $("#img_pianoBackground").animate({
            width: "1215px",
            height: "729px",
            top: "-1400px",
            right: "-720px"

        }, 1000)

    }

    contactClick = () => {
        if (this.state.contact_state == 0) {
            this.projectEnd();
            this.stripEnd();
            this.aboutEnd();
            this.contactEnd();
            this.setState({
                about_state: 0,
                project_state: 1,
                strip_state: 1,
                contact_state: 1
            })

        } else {
            this.linkToClick("contact")
        }
    }

    contactStart() {
        $("#contact_main_img").css('object-fit', "cover")
        $("#contact_main_img").css('left', "auto")
        $("#contact_main_img").animate({
            width: "1035px",
            height: "718px",
            right: "0px",
            marginTop: "-674px"

        }, 1000)

        $("#contact_item_content").animate({
            height: "576px"
        }, 1000)

        $("#or_code_one").css('position', "absolute")
        $("#or_code_one").animate({
            marginTop: "340px",
            left: "410px",
            zIndex: "14"
        }, 1000)

        $("#or_code_two").css('position', "absolute")
        $("#or_code_two").animate({
            marginTop: "340px",
            left: "80px",
            zIndex: "14"
        }, 1000)

        $("#contact_icon").css('position', "absolute")
        $("#contact_icon").animate({
            marginTop: "220px",
            marginLeft: "-60px"
        }, 1000)

        $("#contact_item1_text1").css('color', "#968745")
        $("#contact_item1_text1").css('position', "absolute")
        $("#contact_item1_text1").animate({
            fontSize: "65px",
            marginTop: "-84px",
            marginLeft: "-50px",
            zIndex: "13"
        }, 1000)

        $("#contact_item1_text2").css('color', "#968745")
        $("#contact_item1_text2").animate({
            fontSize: "52px",
            marginLeft: "-50px",
            marginTop: "40px"
        }, 1000)


        $("#contact_item1_text3").css('position', "absolute")
        $("#contact_item1_text3").animate({
            marginTop: "60px",
            marginLeft: "40px"
        }, 1000)


        $("#contact_item2").css('position', "absolute")
        $("#contact_item2").animate({
            marginTop: "230px",
            marginLeft: "140px"
        }, 1000)

        this.homeTail_ChildPage.contact_home_tail_change();
    }

    contactEnd() {
        $("#contact_main_img").css('right', "auto")
        $("#contact_main_img").css('object-fit', "none")
        $("#contact_main_img").animate({
            width: "812px",
            height: "541px",
            left: "960px",
            marginTop: "-680px"
        }, 1000)

        $("#contact_item_content").animate({
            height: "446px"
        }, 1000)

        $("#or_code_one").css('position', "static")
        $("#or_code_one").animate({
            marginTop: "0px",
            left: "0px",
        }, 1000)

        $("#or_code_two").css('position', "static")
        $("#or_code_two").animate({
            marginTop: "0px",
            left: "0px",
        }, 1000)

        $("#contact_icon").css('position', "static")
        $("#contact_icon").animate({
            marginTop: "0px",
            marginLeft: "0px"
        }, 1000)

        $("#contact_item1_text1").css('color', "#404041")
        $("#contact_item1_text1").css('position', "static")
        $("#contact_item1_text1").animate({
            fontSize: "20px",
            marginTop: "10px",
            marginLeft: "0px",
            zIndex: "auto"
        }, 1000)

        $("#contact_item1_text2").css('color', "#4D4D4D")
        $("#contact_item1_text2").animate({
            fontSize: "16px",
            marginTop: "10px",
            marginLeft: "0px"
        }, 1000)


        $("#contact_item1_text3").animate({
            marginTop: "10px",
            marginLeft: "0px"
        }, 1000)
        $("#contact_item1_text3").css('position', "static")


        $("#contact_item2").animate({
            marginTop: "50px",
            marginLeft: "0px"
        }, 1000)
        $("#contact_item2").css('position', "static")
        this.homeTail_ChildPage.contact_home_tail_revert();
    }

    linkToClick(val) {
        localStorage.setItem("route", val);
        if (val == "music-course" || val == "study-abroad" || val == "music-production") {
            localStorage.setItem("object_state", "1")
        } else {
            localStorage.setItem("object_state", "0")
        }
        if (val == "roster" || val == "award") {
            localStorage.setItem("honors_state", "1")
        } else {
            localStorage.setItem("honors_state", "0")
        }

        this.props.history.push("/" + val);
    }

    scrollToAnchor = (anchorName: any) => {
        let state: any = {
            behavior: 'smooth',
            block: 'start'
        }
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView(state);
            }
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Header></Header>
                <div className="popup-home" onClick={() => this.linkToClick("2nd-musicon-composition-competition-finalists")}>
                    {t("news.news_9_2024_10_title")}！
                </div>

                <div className="Home-main">
                    <div className="x49-8">
                        <div className="item">
                            <div className="x1-25"></div>
                            <div className="text1">天才养成的摇篮,梦想起航的地方</div>
                        </div>
                        <div className="item">
                            <div className="x2-9"></div>
                            <div className="text2">BEST MUSIC NURTURING STUDIO</div>
                        </div>
                    </div>
                    <div className="x22">
                        <img src={strip} id="strip" onClick={this.stripClick.bind(this)}/>
                        <div className="img-left"></div>
                        <div className="img-right"></div>
                    </div>
                    <div className="overlap-group26">
                        <div className="m" id="letter_m">M</div>
                        <div className="a" id="letter_a">A</div>
                        <div className="y" id="letter_y">Y</div>
                        <div className="f" id="letter_f">F</div>
                        <div className="l" id="letter_l">L</div>
                        <div className="o" id="letter_o">O</div>
                        <div className="w" id="letter_w">W</div>
                        <div className="e" id="letter_e">E</div>
                        <div className="r" id="letter_r">R</div>

                        <img className="line1" id="line_1" src={home_line}/>
                        <img className="line2" id="line_2" src={home_line}/>
                        <img className="line3" id="line_3" src={home_line}/>
                        <img className="line4" id="line_4" src={home_line}/>
                        <img className="line5" id="line_5" src={home_line}/>
                        <img className="line6" id="line_6" src={home_line}/>
                        <img className="line7" id="line_7" src={home_line}/>
                        <img className="line8" id="line_8" src={home_line}/>
                    </div>
                    <div className="navigation">
                        <a className="about" onClick={() => {
                            this.scrollToAnchor("about-item")
                        }}>
                            <span>关于我们</span>
                            <img src={about_n}/>
                        </a>
                        <div className="navigation-line">
                            <img src={navigation_line}/>
                        </div>
                        <a className="project" onClick={() => {
                            this.scrollToAnchor("project-item")
                        }}>
                            <span>项目介绍</span>
                            <img src={project_n}/>
                        </a>
                        <div className="navigation-line">
                            <img src={navigation_line}/>
                        </div>
                        <a className="teacher" onClick={() => {
                            this.scrollToAnchor("teacher-item")
                        }}>
                            <span>师资力量</span>
                            <img src={teacher_n}/>
                        </a>
                        <div className="navigation-line">
                            <img src={navigation_line}/>
                        </div>
                        <a className="award" onClick={() => {
                            this.scrollToAnchor("award-item")
                        }}>
                            <span>往期荣誉</span>
                            <img src={award_n}/>
                        </a>
                        <div className="navigation-line">
                            <img src={navigation_line}/>
                        </div>
                        <a className="news" onClick={() => {
                            this.scrollToAnchor("news-item")
                        }}>
                            <span>艺术中心新闻</span>
                            <img src={news_n}/>
                        </a>
                        <div className="navigation-line">
                            <img src={navigation_line}/>
                        </div>
                        <a className="contact" onClick={() => {
                            this.scrollToAnchor("contact-item")
                        }}>
                            <span>联系我们</span>
                            <img src={contact_n}/>
                        </a>
                    </div>
                    <div className="content-item">
                        {/*  关于我们  */}
                        <div className="about-item" id="about-item">
                            <div className="about-item-title">
                                <img src={about_c}/>
                                <div className="block">
                                    <div className="div1">关于我们</div>
                                    <div className="div2">About us</div>
                                </div>
                            </div>
                            <div className="about-item-content">
                                <div className="text1" id="about_text1">
                                    {t("home.about.title_one")}
                                </div>
                                <div className="text2" id="about_text2">
                                    {t("home.about.title_two")}
                                </div>
                                <div className="text3" id="about_text3">
                                    {t("home.about.text_detail")}
                                </div>
                                <a className="text-button" id="text_button" onClick={() => {
                                    this.linkToClick("about-us")
                                }}>
                                    {t("home.about.btn")}
                                </a>
                            </div>
                            <img className="img-piano" src={piano} id="img_piano" onClick={this.aboutClick.bind(this)}/>
                            <img className="img-pianoBackground" id="img_pianoBackground" src={pianoBackground}/>
                        </div>
                        {/*  项目介绍  */}
                        <div className="project-item" id="project-item">
                            <div className="project-item-title">
                                <img src={project_c}/>
                                <div className="block">
                                    <div className="div1">项目介绍</div>
                                    <div className="div2">Music Programs</div>
                                </div>
                            </div>
                            <div className="project-item-content">
                                <div className="contentA">
                                    <div className={this.state.project_state == 1 ? "contentBg" : "contentBg—A"}
                                         id="project_A_Bg">
                                        <img className="img-top" id="project_img_A_top"
                                             onClick={this.projectClick.bind(this, "A")} src={project_A}/>
                                    </div>
                                    <div
                                        className={localStorage.getItem("Language") == "zh" ? "span-text-zh" : "span-text-en"}>
                                        {t("home.project.project_a")}
                                    </div>
                                    <img className="img-A" id="project_img_A" src={img_A}/>
                                </div>
                                <div className="contentB">
                                    <div className={this.state.project_state == 1 ? "contentBg" : "contentBg—A"}
                                         id="project_B_Bg">
                                        <img className="img-top" id="project_img_B_top"
                                             onClick={this.projectClick.bind(this, "B")} src={project_B}/>
                                    </div>
                                    <div
                                        className={localStorage.getItem("Language") == "zh" ? "span-text-zh" : "span-text-en"}
                                        id="project_text_B">
                                        {t("home.project.project_b")}
                                    </div>
                                    <img className={localStorage.getItem("Language") == "zh" ? "img-B-zh" : "img-B-en"}
                                         id="project_img_B" src={img_B}/>
                                </div>
                                <div className="contentC">
                                    <div className="item">
                                        <div className={this.state.project_state == 1 ? "contentBg" : "contentBg—A"}
                                             id="project_C_Bg">
                                            <img className="img-top" id="project_img_C_top"
                                                 onClick={this.projectClick.bind(this, "C")} src={project_C}/>
                                        </div>
                                        <div
                                            className={localStorage.getItem("Language") == "zh" ? "span-text-zh" : "span-text-en"}
                                            id="project_text_C">
                                            {t("home.project.project_c")}
                                        </div>
                                        <img className="img-C" id="project_img_C" src={img_C}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*  师资力量  */}
                        <div className="teacher-item" id="teacher-item">
                            <div className="teacher-item-title">
                                <img src={teacher_c}/>
                                <div className="block">
                                    <div className="div1">师资力量</div>
                                    <div className="div2">Faculties</div>
                                </div>
                            </div>
                            <div className="teacher-item-content">
                                <a className="teacher-1"
                                   onClick={this.linkToClick.bind(this, "teacher")}>
                                    <img className="teacher-1-bg" src={teacher_1_bg}/>
                                    <img className="teacher-1-img" id="teacher_1_img" src={teacher_1_img}/>
                                    <div
                                        className={localStorage.getItem("Language") == "zh" ? "teacher-1-text-zh" : "teacher-1-text-en"}>
                                        <div className="span-text-1">
                                            <span>{t("home.teacher.describe_wu_1")}</span></div>
                                        <div className="span-text-2">
                                            <span>{t("home.teacher.describe_wu_2")}</span></div>
                                    </div>
                                </a>
                                <a className="teacher-2"
                                   onClick={this.linkToClick.bind(this, "teacher")}>
                                    <img className="teacher-2-bg" src={teacher_2_bg}/>
                                    <img className="teacher-2-img" src={teacher_2_img}/>
                                    <div
                                        className={localStorage.getItem("Language") == "zh" ? "teacher-2-text-zh" : "teacher-2-text-en"}>
                                        <div className="span-text-1">
                                            <span>{t("home.teacher.describe_wang_1")}</span>
                                        </div>
                                        {/*<div className="span-text-2">*/}
                                        {/*    <span>*/}
                                        {/*        /!*{t("home.teacher.describe_wang_2")}*!/*/}
                                        {/*        &nbsp;*/}
                                        {/*    </span>*/}
                                        {/*</div>*/}
                                    </div>
                                </a>
                                <a className="teacher-3"
                                   onClick={this.linkToClick.bind(this, "teacher")}>
                                    <img className="teacher-3-bg" src={teacher_3_bg}/>
                                    <img className="teacher-3-img" src={teacher_3_img}/>
                                    <div className={localStorage.getItem("Language") == "zh" ? "teacher-3-text-zh" : "teacher-3-text-en"}>
                                        <div className="span-text-1"><span>{t("home.teacher.describe_yi_1")}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <a className="teacher-button"
                               onClick={this.linkToClick.bind(this, "teacher")}>
                                <div className="button-text">
                                    {t("home.teacher.btn")}
                                </div>
                            </a>
                        </div>
                        {/*  往期荣誉  */}
                        <div className="award-item" id="award-item">
                            <div className="award-item-title">
                                <img src={award_c}/>
                                <div className="block">
                                    <div className="div1">往期荣誉</div>
                                    <div className="div2">Honors & Awards</div>
                                </div>
                            </div>
                            <div className="award-item-content-top" id="award_item_content_top"
                                 onClick={this.awardClick.bind(this)}>
                                <div className="title">学生大学录取名单：<br/><span style={{fontSize: "18px"}}>Student Admission list</span>
                                </div>
                                <div id="award_item_content_top_content">
                                    <div id="top_right1">
                                        <div className="text">
                                            <div className="left-text">2023年 —</div>
                                            <div className="right-text">
                                                {t("home.award.conList_2023_1")}
                                            </div>
                                        </div>
                                        <div className="text">
                                            <div className="left-text"></div>
                                            <div className="right-text">{t("home.award.conList_2023_2")}</div>
                                        </div>
                                        <div className="text">
                                            <div className="left-text"></div>
                                            <div className="right-text">{t("home.award.conList_2023_3")}</div>
                                        </div>
                                        <div className="text">
                                            <div className="left-text"></div>
                                            <div className="right-text">{t("home.award.conList_2023_4")}</div>
                                        </div>
                                    </div>
                                    <div id="top_right2">
                                        <div className="text">
                                            <div className="left-text">2022年 —</div>
                                            <div className="right-text">{t("home.award.conList_2022_1")}</div>
                                        </div>
                                        <div className="text">
                                            <div className="left-text"></div>
                                            <div className="right-text">{t("home.award.conList_2022_2")}</div>
                                        </div>
                                    </div>
                                    {/*<div className="text">*/}
                                    {/*    <div className="left-text">2020年 —</div>*/}
                                    {/*    <div className="right-text">{t("home.award.conList_2020_1")}</div>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="more" id="award_top_more"
                                     onClick={this.linkToClick.bind(this, "award")}>MORE
                                </div>
                            </div>
                            <div className="award-item-content" id="award_item_content">
                                <div className="contact-left">
                                    <img id="award_img" src={award_img} onClick={this.awardClick.bind(this)}/>
                                    <a className="more" id="award_more"
                                       onClick={this.linkToClick.bind(this, "award")}>MORE</a>
                                    <div className="left-text" id="award_left_text"
                                         onClick={this.awardClick.bind(this)}>
                                        <span className="text1">学生获得作曲奖项：</span><br/>
                                        <span className="text2">Music Composition Award Winners:</span>
                                    </div>
                                </div>
                                <div className="contact-right">
                                    <p id="contact_p" className="prize-text">
                                        <span >2024 Tribeca Young composer competition, Honorable Mention<br/><br/></span>
                                        {/*<span className="prize-text">Third Place(Elementary Group) <br/><br/></span>*/}
                                        <span>2023 National Young Composers Challenge, Winner<br/><br/></span>
                                        {/*<span className="prize-text">2nd Prize in Junior Group<br/><br/></span>*/}
                                        <span>2023 National Young Arts Composition, Winner<br/><br/></span>
                                        {/*<span className="prize-text">1st Place (Student Group) <br/><br/></span>*/}
                                        <span>2023 BMI Student Composer Award, Winner<br/><br/></span>
                                        {/*<span className="prize-text">Reached the Finals<br/><br/></span>*/}
                                        <span>2023 The National Youth Orchestra of the United States of America, Apprentice composer<br/><br/></span>
                                        {/*<span className="prize-text">Reached the Finals<br/><br/></span>*/}
                                        <span>2023 MTNA Composition Competition, National 2nd place<br/><br/></span>
                                        {/*<span className="prize-text">Winner of California, Maryland, Pennsylvania, Texas and Columbia<br/></span>*/}
                                        {/*<span className="prize-text">Honorable Mention of New York, New Jersey<br/><br/></span>*/}
                                        <span>2022 National Young Arts Composition, Honorable Mention<br/><br/></span>
                                        {/*<span className="prize-text">Winner of High school division 2021<br/><br/></span>*/}
                                        <span>2022 BMI student composer award, Honorable Mention<br/><br/></span>
                                        {/*<span className="prize-text">Honorable Mention<br/><br/></span>*/}

                                        <span>2022 Luna Compositon Lab, Winner<br/><br/></span>
                                        {/*<span className="prize-text">Honorable Mention<br/><br/></span>*/}
                                        <span>2022 Moton Gould Young composer Awards, Winner<br/><br/></span>
                                        {/*<span className="prize-text">Honorable Mention<br/><br/></span>*/}

                                        <span>2022 MTNA Composition Competition, National 3rd place<br/><br/></span>
                                        {/*<span className="prize-text">2nd Prize ( Orchestral - Division, Age 8-17)<br/><br/></span>*/}

                                        <span>2022 Luna Compositon Lab, Honorable Mention<br/><br/></span>
                                        {/*<span className="prize-text">ORCHESTRAL Composition Division I (ages 8 – 17) , 2nd prize<br/></span>*/}
                                        {/*<span className="prize-text">ORCHESTRAL Composition Division I (ages 8 – 17 ), Honorable Mention<br/><br/></span>*/}

                                        <span>2021 Moton Gould Young composer Awards, Honorable Mention<br/><br/></span>
                                        {/*<span className="prize-text">1st winner, 2nd winner, 3rd winner<br/><br/></span>*/}

                                        <span>2021 BMI student composer award, Honorable Mention<br/><br/></span>
                                        {/*<span className="prize-text">Apprentice Composer<br/><br/></span>*/}

                                        <span>2021 The American Prize, The Ernst Bacon Memorial Award and Winner<br/><br/></span>
                                        <span>2020 The National Youth Orchestra of the United States of America, Apprentice composer<br/><br/></span>
                                        <span>2019 Composition Competition “Sounds of Children’s Rights”, Winner</span>
                                    </p>

                                </div>
                            </div>
                        </div>
                        {/*  艺术中心新闻  */}
                        <div className="news-item" id="news-item">
                            <div className="news-item-title">
                                <img src={news_c}/>
                                <div className="block">
                                    <div className="div1">艺术中心新闻</div>
                                    <div className="div2">Latest New & Events</div>
                                </div>
                            </div>

                            <div className="news-item-content">
                                <div className="news-content">
                                    <a
                                        className="text-item"
                                        onClick={this.linkToClick.bind(this, "2nd-musicon-composition-competition")}
                                    >
                                        <div className="date">
                                            <div className="icon"></div>
                                            <span>04/08/2024</span>
                                        </div>
                                        <div className="text1">
                                            {t("home.news.December_21_2022_title")}
                                        </div>
                                        <div className="text2">
                                            {t("home.news.December_21_2022_text")}
                                        </div>
                                    </a>
                                </div>

                                <div className="news-content">
                                    {/*<div className="img-item2">*/}
                                    {/*    <a onClick={this.linkToClick.bind(this, "detail-seven")}>*/}
                                    {/*        <div className="img-bg"></div>*/}
                                    {/*        <img src={news_img7}/>*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                    <a
                                        className="text-item"
                                        onClick={this.linkToClick.bind(this, "detail-eleven")}
                                    >
                                        <div className="date">
                                            <div className="icon"></div>
                                            <span>11/14/2023</span>
                                        </div>
                                        <div className="text1">
                                            {t("home.news.seven_title_Sep_30_2022")}
                                        </div>
                                        <div className="text2">
                                            {t("home.news.seven_detail_Sep_30_2022")}
                                        </div>
                                    </a>
                                </div>
                                <div className="news-content">
                                    <a
                                        className="text-item"
                                        onClick={this.linkToClick.bind(this, "detail-ten")}
                                    >
                                        <div className="date">
                                            <div className="icon"></div>
                                            <span>05/31/2023</span>
                                        </div>
                                        <div className="text1">
                                            {t("home.news.first_title_July_27_2022")}
                                            {/*Nicole Liang的弦乐四重奏获得了Junior Composers Contest马里兰州<br/>第一名*/}
                                        </div>
                                        <div className="text2">
                                            {t("home.news.first_detail_July_27_2022")}
                                            {/*美好艺术中心的学员Nicole Liang获得了National Federation of Music Clubs作曲比赛的马里兰<br/>州第一名，并将作为马里兰州的唯一代表参加美国东部区的比赛，恭喜！*/}
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="news-item-bottom">
                                <a onClick={this.linkToClick.bind(this, "news")}>
                                    <div className="more">MORE</div>
                                </a>
                            </div>
                        </div>
                        {/*  联系我们  */}
                        <div className="contact-item" id="contact-item">
                            <div className="contact-item-title">
                                <img src={contact_c}/>
                                <div className="block">
                                    <div className="div1">联系我们</div>
                                    <div className="div2">Contact Us</div>
                                </div>
                            </div>
                            <div id="contact_item_content" className="contact-item-content">
                                <div className="item1">
                                    <div className="img-div">
                                        <img id="contact_icon" src={contact_icon}/>
                                    </div>
                                    <div className="text">
                                        <div id="contact_item1_text1" className="text1">MAYFLOWER ART CENTER</div>
                                        <div id="contact_item1_text2" className="text2">美好艺术中心</div>
                                        <div id="contact_item1_text3" className="text3">Email:
                                            &nbsp;office@mayflowerartcenter.org
                                        </div>
                                    </div>
                                </div>
                                <div id="contact_item2" className="item2">
                                    <div className="img-div">
                                        <img src={contact_wixin_icon}/>
                                    </div>
                                    <div className="text">
                                        <div className="text1">
                                            {t("home.contact.describe_text1")}
                                            {/*欢迎关注美好艺术中心微信公众号：*/}
                                        </div>
                                        <div className="text2">[MusiCON声先]</div>
                                    </div>
                                </div>
                                <div className="item3">
                                    <div className="img-div">
                                    </div>
                                    <div className="or-code">
                                        {/*<div id="or_code_one">*/}
                                        {/*    <img src={OR_code_one}/>*/}
                                        {/*    <span>*/}
                                        {/*         {t("home.contact.course_consultation")}*/}
                                        {/*      </span>*/}
                                        {/*</div>*/}
                                        {/*or_code_two*/}
                                        <div className="img" id="or_code_one">
                                            <img src={OR_code_two}/>
                                            {/*<span> {t("home.contact.study_abroad_consultation")}</span>*/}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <HomeTail onRef={ref => this.homeTail_ChildPage = ref} contactClick={this.contactClick}></HomeTail>
            </div>
        );
    }
}

export default withTranslation()(Home);
