import React, {Component} from "react";
import './News.scss'
import line from "../honors/award/img/line.png";
import violin from './img/violin.png'
import piano from './img/piano.png'
import paper from './img/paper.png'
import cup from './img/cup.png'
import volin2 from './img/violin-2.png'
import Header from "../../components/header/Header";
import Tail from "../../components/tail/Tail";
import paper2 from './img/paper-2.png';
import victory2 from './img/victory.png';
import detail5 from './img/news_detail_5_1.png';
import detail7 from './img/news_detail_7_1.png';
import $ from 'jquery'
import {withTranslation} from "react-i18next"

// 艺术中心新闻
class News extends Component {
    constructor() {
        super();

        this.state = ({
            clickState: true,
            a: undefined
        })
    }

    handleClick() {
        if (this.state.clickState) {
            $("#rightCircle").css('background-color', '#fafafa')
            $("#rightCircle").animate({
                'height': '409px',
                'left': '1610px',
                top: '-94px',
                width: '414px'
            }, {
                duration: 1000,
                easing: "easeInCubic"
            });
            $("#box").animate({
                left: "71px",
                top: "738px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            });
            $("#small-circle").animate({
                left: "520px",
                top: "350px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#line").animate({
                left: "278px",
                top: "361px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#main-title").animate({
                position: "absolute",
                left: "440px",
                top: "20px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#title-text").children('span').animate({'font-size': "60px"}, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#title-text").animate({
                letterSpacing: "0",
                left: "278px",
                top: "488px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#last-text").animate({
                left: "278px",
                top: "488px",
                'font-size': "60px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#main").animate({
                top: "600px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#last-text").css('color', "#968745")
            this.changeHeader();


            $("#rightCircle").css('cursor', "default")
            $("#box").css('cursor', "default")
            $("#title-text").css('cursor', "default")

            this.setState({
                clickState: false
            })
        }
    };

    changeHeader() {
        this.ChildPage.changeObject();
    }

    toDetail(index) {
        switch (index) {
            case 1:
                localStorage.setItem("route", "detail-one");
                this.props.history.push("/detail-one")
                break;
            case 2:
                localStorage.setItem("route", "detail-two");
                this.props.history.push("/detail-two")
                break;
            case 3:
                localStorage.setItem("route", "detail-three");
                this.props.history.push("/detail-three")
                break;
            case 4:
                localStorage.setItem("route", "detail-four");
                this.props.history.push("/detail-four")
                break;
            case 5:
                localStorage.setItem("route", "detail-five");
                this.props.history.push("/detail-five")
                break;
            case 6:
                localStorage.setItem("route", "1st-musicon-composition-competition");
                this.props.history.push("/1st-musicon-composition-competition")
                break;
            case 7:
                localStorage.setItem("route", "detail-seven");
                this.props.history.push("/detail-seven")
                break;
            case 8:
                localStorage.setItem("route", "detail-eight");
                this.props.history.push("/detail-eight");
                break;
            case 9:
                localStorage.setItem("route", "detail-nine");
                this.props.history.push("/detail-nine");
                break;
            case 10:
                localStorage.setItem("route", "detail-ten");
                this.props.history.push("/detail-ten");
                break;
            case 11:
                localStorage.setItem("route", "detail-eleven");
                this.props.history.push("/detail-eleven");
                break;
            case 12:
                localStorage.setItem("route", "2nd-musicon-composition-competition");
                this.props.history.push("/2nd-musicon-composition-competition");
                break;
            case 13:
                localStorage.setItem("route", "2nd-musicon-composition-competition-finalists");
                this.props.history.push("/2nd-musicon-composition-competition-finalists");
                break;

        }
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <div>
                    <Header onRef={(ref) => (this.ChildPage = ref)}></Header>
                    <div
                        className="rightCircle"
                        id="rightCircle"
                        onClick={this.handleClick.bind(this)}
                    ></div>
                    <div
                        className="middle-circle"
                        id="box"
                        onClick={this.handleClick.bind(this)}
                    ></div>
                    <div className="small-circle" id="small-circle"></div>
                    <div className="title" id="title">
                        <h1
                            className="title-text"
                            id="title-text"
                            onClick={this.handleClick.bind(this)}
                        >
                            <span>Latest News </span>
                            <span className="last-text" id="last-text">
                                & Events
                            </span>
                        </h1>
                        <img src={line} id="line"/>
                    </div>
                    <div className="main" id="main">
                        <div className="main-title" id="main-title">
                            艺术中心新闻
                        </div>
                        <div className="card card_active" onClick={this.toDetail.bind(this, 13)} id="last-card">
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">09/10/2024</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t("news.news_9_2024_10_title")}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t("news.news_9_2024_10_text")}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card card_active" onClick={this.toDetail.bind(this, 12)} id="last-card">
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">04/08/2024</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t("news.news_4_2024_8_title")}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t("news.news_4_2024_8_text")}</span>
                                </div>
                            </div>
                        </div>

                        <div className="card card_active" onClick={this.toDetail.bind(this, 11)}>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">11/14/2023</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t("news.news_11_2023_14_title")}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t("news.news_11_2023_14_text")}</span>
                                </div>
                            </div>
                        </div>

                        <div className="card card_active" onClick={this.toDetail.bind(this, 10)}>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">05/31/2023</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t("news.news_5_2023_31_title")}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t("news.news_5_2023_31_text")}</span>
                                </div>
                            </div>
                        </div>

                        <div className="card card_active" onClick={this.toDetail.bind(this, 9)}>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">03/07/2023</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t("news.news_7_2023_3_title")}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t("news.news_7_2023_3_text")}</span>
                                </div>
                            </div>
                        </div>

                        <div className="card card_active" onClick={this.toDetail.bind(this, 8)}>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">12/10/2022</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t("news.news_21_2022_12_title")}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t("news.news_21_2022_12_text")}</span>
                                </div>
                            </div>
                        </div>

                        <div className="card card_active" onClick={this.toDetail.bind(this, 7)}>
                            <img src={detail7}/>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">09/30/2022</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t("news.seven_title_Sep_30_2022")}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t("news.seven_detail_Sep_30_2022")}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card card_active" onClick={this.toDetail.bind(this, 6)}>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">08/01/2022</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t('news.second_title_July_27_2022')}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t('news.second_detail_July_27_2022')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card card_active" onClick={this.toDetail.bind(this, 5)}>
                            <img src={victory2}/>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">07/27/2022</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t('news.sixth_text_student')}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t('news.sixth_text_facemask')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card card_active" onClick={this.toDetail.bind(this, 4)}>
                            <img src={detail5}/>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">07/11/2022</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t('news.first_title_July_27_2022')}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t('news.first_detail_July_27_2022')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card card_active" onClick={this.toDetail.bind(this, 3)}>
                            <img src={violin}/>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">03/08/2021</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t('news.first_text_title')}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t('news.first_text_detail')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card card_active" onClick={this.toDetail.bind(this, 2)}>
                            <img src={piano}/>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">03/05/2021</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t('news.second_text_title')}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t('news.second_text_detail')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card card_active" onClick={this.toDetail.bind(this, 1)}>
                            <img src={paper}/>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">02/22/2021</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t('news.third_text_title')}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t('news.third_text_detail')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <img src={cup}/>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">02/04/2021</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t('news.fourth_text_title')}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t('news.fourth_text_detail')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <img src={volin2}/>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">01/24/2021</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t('news.fifth_text_title')}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t('news.fifth_text_detail')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <img src={paper2}/>
                            <div className="card-title">
                                <div className="card-title-top">
                                    <div className="square"></div>
                                    <div className="date">11/24/2020</div>
                                </div>
                                <div className="card-title-body">
                                    <span>{t('news.sixth_text_title')}</span>
                                    <br/>
                                    <br/>
                                    <span className="detail">{t('news.sixth_text_detail')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-Tail">
                        <Tail></Tail>
                    </div>
                </div>
            </div>

        )
    }
}

export default withTranslation()(News)



