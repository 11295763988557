import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import Header_phone from "../../components/header/Header_phone";
import Tail_phone from "../../components/tail/Tail_phone";
import line from "../projectDescription/img/line.png";
import award_img from "./img/award-img.png"
import roster_img from "./img/roster-img.png"

import "./Honors_phone.scss"

class Honors_phone extends Component {

    linkToClick(url) {
        this.props.history.push("/" + url);
    }

    render() {
        const {t} = this.props;
        return (
            <div className="honors-phone-main">
                <Header_phone></Header_phone>
                <div className="honors-phone-content">
                    <div className="honors-title">
                        <img src={line}/>
                        <div className="text1">
                            Honors & Awards：
                        </div>
                        <div className="text2">
                            往期荣誉
                        </div>
                    </div>
                    <div className="project-A">
                        <div className="project-bg-A" onTouchEnd={() => this.linkToClick("roster-phone")}>
                            <img src={roster_img} className="img"/>
                            <span className="text-A">A</span>
                            <span className="text"
                                  style={localStorage.getItem('Language') == 'zh' ? {letterSpacing: '0.4rem'} : {}}>{t("header.honors.admission_list")}</span>
                        </div>
                    </div>
                    <div className="project-B">
                        <div className="project-bg-B" onTouchEnd={() => this.linkToClick("award-phone")}>
                            <img src={award_img} className="img"/>
                            <span className="text-B">B</span>
                            <span className="text"
                                  style={localStorage.getItem('Language') == 'zh' ? {letterSpacing: '0.4rem'} : {}}>{t("header.honors.awards")}</span>
                        </div>
                    </div>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        );
    }
}

export default withTranslation()(Honors_phone);
