import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import "./Navigation_phone.scss"

class Navigation_phone extends Component {

    constructor() {
        super();
    }

    linkToClick(url) {
        localStorage.setItem("route", url)
        this.props.history.push("/" + url);
    }

    render() {
        const {t} = this.props;
        return (
            <div className="navigation-phone">
                <div>
                    <span onTouchEnd={() => {
                        this.linkToClick("home-phone")
                    }}>
										{t("header.home")}
                    {/*中心主页*/}
										</span>
                </div>
                <div>
                    <span onTouchEnd={() => {
                        this.linkToClick("about-phone")
                    }}>
										{t("header.about_us")}
										{/*关于我们*/}
										</span>
                </div>
                <div>
                    <span onTouchEnd={() => {
                        this.linkToClick("project-phone")
                    }}>
                    {t("header.project.name")}
										{/*项目介绍*/}
										</span>
                </div>
                <div>
                    <span onTouchEnd={() => {
                        this.linkToClick("teacher-phone")
                    }}>
										{t("header.faculties")}
										{/*师资力量*/}
										</span>
                </div>
                <div>
                    <span onTouchEnd={() => {
                        this.linkToClick("musiON-phone")
                    }}>
										{t("header.musiCON.name")}
                        {/*musiCON*/}
										</span>
                </div>

                <div>
                    <span onTouchEnd={() => {
                        this.linkToClick("honors-phone")
                    }}>
										{t("header.honors.name")}
										{/*往期荣誉*/}
										</span>
                </div>
                <div>
                    <span onTouchEnd={() => {
                        this.linkToClick("news-phone")
                    }}>
										{t("header.news")}
										{/*艺术中心新闻*/}
										</span>
                </div>
                <div>
                    <span onTouchEnd={() => {
                        this.linkToClick("contact-phone")
                    }}>
										{t("header.contract_us")}
										{/*联系我们*/}
										</span>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Navigation_phone);
