import React, {Component} from "react";
import './detailPhoneCommon.scss'
import line from '../../img/thin-line.png'
import volinImg from './img/volinImg.png'
import diaozhuo from '../../img/diaozhuo.png'
import {withTranslation} from "react-i18next"
import Header_phone from "../../../../../components/header/Header_phone";
import Tail_phone from "../../../../../components/tail/Tail_phone";

class Volin_phone extends Component {

    linkToClick(val) {
        this.props.history.push("/" + val);
    }

    render() {
        const {t}=this.props
        return (
            <div>
                <Header_phone></Header_phone>
                <div className="group">
                    <div className="title">
                        <div className="text1">
                            Violin & Viola
                        </div>
                        <div className="text2" style={{marginLeft: "5rem"}}>
                            <div className="foot_title">小提琴&中提琴课程</div>
                        </div>
                    </div>
                    <img className="thin-line" src={line}/>
                    <img className="right-img" src={volinImg}/>
                    <div className="group-detail"
                         style={localStorage.getItem('Language') == 'en' ? {letterSpacing: "0rem"} : {letterSpacing: "0.05rem"}}>
                        <span>{t('musicCourse.violin_first_span')}</span>{t('musicCourse.violin_first_text')}
                        <br/><br/>
                        <span>{t('musicCourse.violin_second_span')}</span>{t('musicCourse.violin_second_text')}
                        <br/><br/>
                        <span>{t('musicCourse.violin_third_span')}</span>{t('musicCourse.violin_third_text')}
                    </div>
                    <div className="teacher-title">{t('musicCourse.responsibleTeacher')}</div>
                    <div className="teacher-list">
                        <div onTouchEnd={this.linkToClick.bind(this, "detail-phone-ZhuoDiao")}>
                            <img src={diaozhuo} className="teacher-img"/>
                            <div className="teacher-text">
                                <div className="teacher-name">刁卓</div>
                                <div className="teacher-name-en">Zhuo Diao</div>
                            </div>
                        </div>
                    </div>
                    <a href={"musicCourse-phone"}>
                        <div className="btn-phone-border">
                            {t('musicCourse.moreCourse')}
                        </div>
                    </a>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        )
    }
}
export default withTranslation()(Volin_phone)
