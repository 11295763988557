import React, {Component} from "react";
import line from "../../img/thin-line.png";
import './detailPhoneCommon.scss'
import skillsImg from './img/skillsImg.png'
import WangMeng from "../../img/wangmeng.png";
import {withTranslation} from "react-i18next"
import jiaorenhe from "../../img/焦人和.png";
import xuyifei from "../../img/许艺菲.png";
import Header_phone from "../../../../../components/header/Header_phone";
import Tail_phone from "../../../../../components/tail/Tail_phone";

class Skills_phone extends Component {

    linkToClick(val) {
        this.props.history.push("/" + val);
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Header_phone></Header_phone>
                <div className="group">
                    <div className="title">
                        <div className="text1">
                            Aural Skills
                        </div>
                        <div className="text2" style={{marginLeft: "5rem"}}>
                            视唱练耳课程
                        </div>
                    </div>
                    <img className="thin-line" src={line}/>
                    <img className="right-img" src={skillsImg}/>
                    <div className="group-detail"
                         style={localStorage.getItem('Language') == 'en' ? {letterSpacing: "0rem"} : {letterSpacing: "0.05rem"}}>
                        {t('musicCourse.skills_detail')}
                    </div>
                    <div className="teacher-title">{t('musicCourse.responsibleTeacher')}</div>
                    <div className="teacher-list">
                        {/*<div onTouchEnd={this.linkToClick.bind(this, "detail-phone-MengWang")}>*/}
                        {/*    <img src={WangMeng} className="teacher-img"/>*/}
                        {/*    <div className="teacher-text">*/}
                        {/*        <div className="teacher-name">王 萌</div>*/}
                        {/*        <div className="teacher-name-en">Meng Wang</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="many" onTouchEnd={this.linkToClick.bind(this, "detail-phone-YifeiXu")}>*/}
                        {/*    <img src={xuyifei} className="teacher-img"/>*/}
                        {/*    <div className="teacher-text">*/}
                        {/*        <div className="teacher-name">许艺菲</div>*/}
                        {/*        <div className="teacher-name-en">Yifei Xu</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="many" onTouchEnd={this.linkToClick.bind(this, "detail-phone-RenheJiao")}>
                            <img src={jiaorenhe} className="teacher-img"/>
                            <div className="teacher-text">
                                <div className="teacher-name">焦人和</div>
                                <div className="teacher-name-en">Renhe Jiao</div>
                            </div>
                        </div>
                    </div>
                    <a href={"musicCourse-phone"}>
                        <div className="btn-phone-border">
                            {t('musicCourse.moreCourse')}
                        </div>
                    </a>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        )
    }
}

export default withTranslation()(Skills_phone)
