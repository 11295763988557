import React, { Component } from "react";
import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import style from './NewsDetail-5.module.scss';

import { withTranslation } from "react-i18next"

class NewsDetail6 extends Component {
    constructor() {
        window.scrollTo(0, 0)
        super();
    }

    changeLast() {
        this.props.history.push("/detail-seven")
    }
    changeNext() {
        this.props.history.push("/detail-five")
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Header></Header>

                <div className={style.leftCircle}></div>
                <div className={style.rightCircle}></div>

                <div className={style.main}>
                    <div className={style.top_date}>
                        <div></div>
                        <div>08/01/2022</div>
                    </div>
                    <div className={style.mbody}>
                        <div className={style.top_text}>
                            <span>{t('news.second_title_July_27_2022')}</span>
                        </div>
                        <div>
                            <span className={style.text_green}>▋</span>
                            <span className={style.text_weight_black}>{t('news.second_July_27_2022_1')}</span>
                        </div>
                        <div>
                            <span className={style.detail_text}>{t('news.second_July_27_2022_2')}</span>
                            <span className={style.text_green}>{t('news.second_July_27_2022_3')}</span>
                            <span className={style.detail_text}>{t('news.second_July_27_2022_4')}</span>
                            <span className={style.text_green}>{t('news.second_July_27_2022_5')}</span>
                            <span className={style.detail_text}>{t('news.second_July_27_2022_6')}</span>
                        </div>
                        <div className={style.text_weight_black}>
                            <span>{t('news.second_July_27_2022_7')}</span>
                        </div>
                        <div>
                            <span className={style.text_green}>▼</span>
                            <span className={style.text_weight_black}>{t('news.second_July_27_2022_8')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.second_July_27_2022_9')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.second_July_27_2022_10')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.second_July_27_2022_11')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.second_July_27_2022_12')}</span>
                        </div>
                        <div>
                            <span className={style.text_green}>▼</span>
                            <span className={style.text_weight_black}>{t('news.second_July_27_2022_13')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.second_July_27_2022_14')}</span>
                        </div>
                        <div>
                            <span className={style.text_green}>▼</span>
                            <span className={style.text_weight_black}>{t('news.second_July_27_2022_15')}</span>
                        </div>
                        <div>
                            <span className={style.detail_text}>{t('news.second_July_27_2022_16')}</span>
                            <span className={style.text_green}>{t('news.second_July_27_2022_17')}</span>
                            <span className={style.detail_text}>{t('news.second_July_27_2022_18')}</span>
                            <span className={style.text_green}>{t('news.second_July_27_2022_19')}</span>
                            <span className={style.detail_text}>{t('news.second_July_27_2022_20')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.second_July_27_2022_21')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.second_July_27_2022_22')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.second_July_27_2022_23')}</span>
                        </div>
                        <div>
                            <span className={style.text_green}>▼</span>
                            <span className={style.text_weight_black}>{t('news.second_July_27_2022_24')}</span>
                        </div>
                        <div>
                            <span className={style.detail_text}>{t('news.second_July_27_2022_25')}</span>
                            <span className={style.text_green}>{t('news.second_July_27_2022_26')}</span>
                            <span className={style.detail_text}>{t('news.second_July_27_2022_27')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.second_July_27_2022_28')}</span>
                        </div>
                        <div className={style.detail_text}>
                            <span>{t('news.second_July_27_2022_29')}</span>
                        </div>
                    </div>
                </div>
                <div className={style.footer}>
                    <div onClick={this.changeLast.bind(this)}>LAST</div>
                    <div onClick={this.changeNext.bind(this)}>NEXT</div>
                </div>

                <div className={style.detailFooter}>
                    <Tail></Tail>
                </div>

            </div>
        )
    }
}
export default withTranslation()(NewsDetail6)
