import React, {Component} from "react";
import '../../musicCourse/phone/MusicCourse_phone.scss'
import line from '../../img/line.png'
import longLine from '../../musicCourse/img/long-line.png'
import {withTranslation} from "react-i18next"
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import pianoBackground from "../../../about/img/pianoBackground.png";
import courseImg from "../../musicCourse/img/course.png";
import production from "../img/production.png";
import ground from "../../musicCourse/img/ground.png";

class MusicProduction_phone extends Component {

    render() {
        const {t} = this.props
        return (
            <div className="musicCourse-phone-main">
                <Header_phone></Header_phone>

                <div className="musicCourse-phone-content">
                    <div className="musicCourse-title">
                        <img src={line}/>
                        <div className="text1">
                            Music Production:
                        </div>
                        <div className="text2"  style={{marginLeft: "8.6rem"}}>
                            音乐制作
                        </div>
                    </div>
                    <div className="img-div">
                        <img src={ground} className="course-ground"/>
                        <img src={production} className="course-img"/>
                    </div>
                    <div className="circular"></div>

                    <div className="course-content">
                        <div className="line">
                            <div className="point"></div>
                            <div className="course-split"
                                 style={localStorage.getItem('Language') == 'en' ? {letterSpacing: '1px'} : {}}>
                                {t('musicProduction.musicProduction')}
                            </div>
                        </div>
                        <img src={longLine} className="longLine"/>
                        <div className="introduce-phone"
                             style={localStorage.getItem('Language') == 'en' ? {letterSpacing: "0rem"} : {letterSpacing: "0.05rem"}}>
                            {t('musicProduction.detail')}
                        </div>

                    </div>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        )
    }

}

export default withTranslation()(MusicProduction_phone)
