import React, { Component } from "react";
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import style from './NewsDetail-phone-5.module.scss';
import news_detail_5_1 from '../../img/news_detail_5_1.png';
import news_detail_5_2 from '../img/news_detail_5_2.png';
import news_detail_5_3 from '../img/news_detail_5_3.png';

import { withTranslation } from "react-i18next"

class NewsDetailPhone4 extends Component {
    constructor() {
        window.scrollTo(0, 0)
        super();
    }

    click_last() {
        this.props.history.push("/news-phone-detail-five")
    }

    click_next() {
        this.props.history.push("/news-phone-detail-three")
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Header_phone></Header_phone>
                <div className={style.newDetail}>
                    <div className={style.main}>
                        <div className={style.top_date}>
                            <div className={style.icon}></div>
                            <div>07/11/2022</div>
                        </div>
                        <div className={style.mbody}>
                            <div className={style.top_text}>
                                <span>{t('news.first_title_July_27_2022')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.first_July_27_2022_1')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.first_July_27_2022_2')}</span>
                            </div>
                            <img src={news_detail_5_1} />
                            <div className={style.text_center_red}>
                                <span>{t('news.first_July_27_2022_3')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.first_July_27_2022_4')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.first_July_27_2022_5')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.first_July_27_2022_6')}</span>
                            </div>
                            <img src={news_detail_5_2} />
                            <div className={style.text_center_red}>
                                <span>{t('news.first_July_27_2022_7')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.first_July_27_2022_8')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.first_July_27_2022_9')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.first_July_27_2022_10')}</span>
                            </div>
                            <img src={news_detail_5_3} />
                            <div className={style.text_center_red}>
                                <span>{t('news.first_July_27_2022_11')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.first_July_27_2022_12')}</span>
                            </div>
                        </div>
                        <div className={style.detail_btn}>
                            <div className={style.detail_btn_last} onTouchEnd={()=>this.click_last()}>LAST</div>
                            <div className={style.detail_btn_next} onTouchEnd={()=>this.click_next()}>NEXT</div>
                        </div>
                    </div>
                    <Tail_phone></Tail_phone>
                </div>
            </div>
        )
    }
}
export default withTranslation()(NewsDetailPhone4)
