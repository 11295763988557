import React, {Component} from "react";
import './detailPhoneCommon.scss'
import line from '../../img/thin-line.png'
import yangjin from '../../img/郑育婷.png'
import claImg from './img/claImg.png'
import {withTranslation} from "react-i18next"
import Header_phone from "../../../../../components/header/Header_phone";
import Tail_phone from "../../../../../components/tail/Tail_phone";

class Clarinet extends Component {

    linkToClick(val) {
        this.props.history.push("/" + val);
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Header_phone></Header_phone>
                <div className="group">
                    <div className="title">
                        <div className="text1">
                            Clarinet
                        </div>
                        <div className="text2" style={{marginLeft: "3.4rem"}}>
                            单簧管课程
                        </div>
                    </div>
                    <img className="thin-line" src={line}/>

                    <img className="right-img" src={claImg}/>
                    <div className="group-detail"
                         style={localStorage.getItem('Language') == 'en' ? {letterSpacing: "0rem"} : {letterSpacing: "0.05rem"}}>
                        {t('musicCourse.clarinet_detail_first')}
                        <br/> <br/>
                        {t('musicCourse.clarinet_detail_second')}
                        <br/> <br/>
                        {t('The clarinet is often considered the closest instrument to the human voice because of its unique tone and range. I insist that students must “sing” through the clarinet like a vocalist and develop their sense of hearing to produce a natural musical sensation, breathing, expression, and the ability to choose the right style and diction, while not only focusing on playing the right notes mechanically. In addition to having fine musicality, I expect my student to work hard on the fundamentals of playing. That can contribute to producing wonderful sound quality, including voice production, breathing support, vocalization, strumming, intonation and reed making, etc. Regarding improving techniques, I provide students with effective practice strategies such as problem-solving, time management, perseverance, and creative thinking. With all the above, my students can overcome technical challenges with efficiency and confidence.`')}
                        <br/> <br/>
                        {t('musicCourse.clarinet_detail_fourth')}
                    </div>
                    <div className="teacher-title">{t('musicCourse.responsibleTeacher')}</div>
                    <div className="teacher-list">
                        <div onTouchEnd={this.linkToClick.bind(this, "detail-phone-YutingZheng")}>
                            <img src={yangjin} className="teacher-phone-img"/>
                            <div className="teacher-text">
                                <div className="teacher-name">郑育婷</div>
                                <div className="teacher-name-en">YuTing Zheng</div>
                            </div>
                        </div>
                    </div>

                    <a href={"musicCourse-phone"}>
                        <div className="btn-phone-border">
                            {t('musicCourse.moreCourse')}
                        </div>
                    </a>
                </div>
                <Tail_phone></Tail_phone>
            </div>

        )
    }
}

export default withTranslation()(Clarinet)
