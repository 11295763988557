import React, {Component} from "react";
import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import './NewsDetail-1.scss'
import line from './img/line.png'
import piano from './img/piano.png'
import {withTranslation} from "react-i18next"


class NewsDetail2 extends Component {
    constructor() {
        window.scrollTo(0,0)
        super();
    }

    changeLast() {
        this.props.history.push("/detail-three")
    }
    changeNext(){
        this.props.history.push("/detail-one")
    }

    render() {
        const {t}= this.props
        return (
            <div>
                <Header></Header>
                <div className="newDetail">
                    <div className="leftCircle"></div>
                    <div className="rightCircle"></div>
                    <div className="main" id="main">
                        <div className="top-date">
                            <div></div>
                            <div>03/05/2021</div>
                        </div>
                        <div className="mbody">
                            <img className="img" src={piano}/>
                            <div className="top-text">
                                <span>{t('news.second_text_title')}</span>
                            </div>
                            <img src={line} className="line"/>
                            <div className="detail-text">
                                {t('news.second_text_detail')}
                                <br/>
                                <br/>
                                {t('news.detail_page_two_1')}
                                <br/>
                                <br/>
                                {t('news.detail_page_two_2')}
                            </div>
                        </div>
                    </div>
                    <div className="footer-next">
                        <div className="footer-next-div">
                            <div className="btn-round-next">
                                <div className="round-border" onClick={this.changeLast.bind(this)}>
                                    <div className="btn-inner">LAST</div>
                                </div>
                                <div className="round-border" onClick={this.changeNext.bind(this)}>
                                    <div className="btn-inner-next">NEXT</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="detailFooter">
                        <Tail></Tail>
                    </div>
                </div>

            </div>
        )
    }
}
export default withTranslation()(NewsDetail2)
