import React, {Component} from "react"

import "./HomeTail_phone.scss"
import icon_phone_1 from "./img/icon_phone_1.png"
import icon_phone_2 from "./img/icon_phone_2.png"
import icon_phone_3 from "./img/icon_phone_3.png"
import icon_phone_4 from "./img/icon_phone_4.png"

import contact_main_img from "../../pages/home/img/content/contact-main-img.png";
import {withTranslation} from "react-i18next";

class HomeTail_phone extends Component {

    constructor() {
        super();
    }


    render() {
        const {t} = this.props;
        return (
            <div className="HomeTail-phone-main">
                <div className="HomeTail-one">
                    <div className="item1">
                        <div className="phone">
                            <div className="x5"></div>
                            <div className="bottom-text3">{t("tail.describe_text1")}</div>
                        </div>
                        <div className="phone">
                            {/*<div className="text1" style={localStorage.getItem("Language") == "en" ? {width:'2.3rem'}:{width:'1.4rem'}}>{t("tail.describe_text2")}</div>*/}
                            {localStorage.getItem("Language") == "en" ?
                                <div>{t("tail.describe_text2")} {t("tail.describe_text3")}</div>
                                : <div>{t("tail.describe_text2")}{t("tail.describe_text3")}</div>}
                        </div>
                        <div className="phone">
                            {/*<div className="text1" style={localStorage.getItem("Language") == "en" ? {width:'2.3rem'}:{width:'1.4rem'}}>{t("tail.describe_text5")}</div>*/}
                            {localStorage.getItem("Language") == "en" ?
                                <div>{t("tail.describe_text5")} {t("tail.describe_text6")}</div>
                                : <div>{t("tail.describe_text5")}{t("tail.describe_text6")}</div>}
                        </div>
                        {/*<div className="phone">*/}
                        {/*    /!*<div className="text1" style={localStorage.getItem("Language") == "en" ? {width:'2.3rem'}:{width:'1.4rem'}}>{t("tail.describe_text7")}</div>*!/*/}
                        {/*    {localStorage.getItem("Language") == "en" ?*/}
                        {/*        <div>{t("tail.describe_text7")} {t("tail.describe_text8")}</div>*/}
                        {/*        : <div>{t("tail.describe_text7")}{t("tail.describe_text8")}</div>}*/}
                        {/*</div>*/}
                        {/*<div className="phone">*/}
                        {/*    <div className="text1"*/}
                        {/*         style={localStorage.getItem("Language") == "en" ? {width: '2.3rem'} : {width: '1.4rem'}}>&nbsp;</div>*/}
                        {/*    <div>&nbsp;</div>*/}
                        {/*</div>*/}
                    </div>

                    <div className="item1">
                        <div className="company"
                             style={localStorage.getItem("Language") == "en" ? {width: '10rem'} : {width: 'auto'}}>
                            <div className="x5"></div>
                            <div className="bottom-text3">{t("tail.describe_text9")}</div>
                        </div>
                        <div className="company"
                             style={localStorage.getItem("Language") == "en" ? {width: '10rem'} : {width: 'auto'}}>
                            {t("tail.describe_text10")}
                        </div>

                        <div className="company"
                             style={localStorage.getItem("Language") == "en" ? {width: '10rem'} : {width: 'auto'}}>
                            &nbsp;
                        </div>
                        {/*<div className="company"*/}
                        {/*     style={localStorage.getItem("Language") == "en" ? {width: '10rem'} : {width: 'auto'}}>*/}
                        {/*    {t("tail.describe_text11")}*/}
                        {/*</div>*/}
                        {/*<div className="company"*/}
                        {/*     style={localStorage.getItem("Language") == "en" ? {width: '10rem'} : {width: 'auto'}}>*/}
                        {/*    {t("tail.describe_text12")}*/}
                        {/*</div>*/}
                        {/*<div className="company"*/}
                        {/*     style={localStorage.getItem("Language") == "en" ? {width: '10rem'} : {width: 'auto'}}>*/}
                        {/*    {t("tail.describe_text13")}*/}
                        {/*</div>*/}
                    </div>
                </div>

                <div className="HomeTail-two">
                    <div className="div-img">
                        <img src={icon_phone_1}/>
                        <img src={icon_phone_2}/>
                        <img src={icon_phone_3}/>
                        <img src={icon_phone_4}/>
                    </div>
                    <div className="text">
                        © MAYFLOWER ART CENTER 2021. All Rights Reserved.
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(HomeTail_phone);
