import React, { Component } from "react";
import { withTranslation } from "react-i18next"
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import "./NewDetail-phone-4.scss"
import city from "../img/victory.png";
import ago from "../img/meal.png"
import hero from "../img/unit.png"
import model from "../img/annual.png"
import newd from "../img/also.png"
import thone from "../img/found.png"
import show from "../img/music.png"

class NewsDetailPhone5 extends Component {
    constructor() {
        window.scrollTo(0, 0)
        super();
    }

    click_last() {
        this.props.history.push("/1st-musicon-composition-competition")
    }

    click_next() {
        this.props.history.push("/news-phone-detail-four")
    }

    render() {
        const { t } = this.props
        return (
            <div className="news-detail-phone-main">
                <Header_phone></Header_phone>
                <div className="news-detail-phone-content">
                    <div className="date">
                        <div className="icon"></div>
                        <span>07/27/2022</span>
                    </div>
                    <div className="content-detail">
                        <div className="detail-text">
                            <div className="text9">{t('news.sixth_text_student')}
                            </div>
                        </div>
                        <img src={city} className="picture" />
                        <div className="top-print">
                            <span>{t('news.second_text_most')}</span>
                        </div>
                        <div className="detail-text">
                            <div className="text1">{t('news.second_text_rope')}</div>
                            <div className="text2">
                                {t('news.second_textfinger')}
                                <br />
                                <br />
                                <img src={ago} />
                                <br />
                                <div className="top-print">
                                    <span>{t('news.detail_page_two8')}</span>
                                </div>
                                <br />
                                <img src={hero} />
                                <br />
                                <div className="top-print">
                                    <span>{t('news.detail_page_two_9')}</span>
                                </div>
                                <br />
                                {t('news.second_text_as')}
                                <br />
                                <br />
                                <img src={model} />
                                <div className="top-print">
                                    <span>{t('news.detail_page_two_3')}</span>
                                </div>
                                <br />
                                {t('news.second_text_under')}
                                <br />
                                <br />
                                <img src={newd} />
                                <div className="top-print">
                                    <span>{t('news.detail_page_two_4')}</span>
                                </div>
                                <br />
                                {t('news.second_text_winn')}
                                <br />
                                <br />
                                <img src={thone} />
                                <br />
                                <br />
                                <div className="top-print">
                                    <span>{t('news.detail_page_two_5')}</span>
                                </div>
                                <br />
                                {t('news.second_text_says')}
                                <br />
                                <br />
                                <img src={show} />
                                <br />
                                <div className="top-print">
                                    <span>{t('news.detail_page_two_6')}</span>
                                </div>
                                <br />
                                {t('news.second_text_aims')}
                                <br />
                                {t('news.second_text_hope')}
                            </div>
                        </div>
                    </div>
                    <div className="detail-btn">
                        <div className="detail-btn-last" onTouchEnd={() => this.click_last()}>LAST</div>
                        <div className="detail-btn-next" onTouchEnd={() => this.click_next()}>NEXT</div>
                    </div>
                </div>
                <Tail_phone></Tail_phone>
            </div>
        )
    }
}

export default withTranslation()(NewsDetailPhone5)
