import React, {Component} from "react"

import Header_phone from "../../../components/header/Header_phone";

import "./Home_phone.scss"

import {withTranslation} from "react-i18next"
import $ from 'jquery'
import strip from "../img/navigation/strip.png";
import home_line from "../img/home-line.png";
import about_n from "../img/navigation/about.png";
import piano from "../../about/img/piano.png";
import pianoBackground from "../../about/img/pianoBackground.png";
import project_c from "../img/content/project.png";
import img_A from "../img/content/img_A.png";
import project_A from "../img/content/project_A.png";
import img_B from "../img/content/img_B.png";
import project_B from "../img/content/project_B.png";
import project_C from "../img/content/project_C.png";
import img_C from "../img/content/img_C.png";
import teacher_c from "../img/content/teacher.png";
import teacher_1_bg from "../img/content/teacher_1_bg.png";
import teacher_1_hover from "../img/content/teacher_1_hover.png";
import teacher_2_bg from "../img/content/teacher_2_bg.png";
import teacher_2_hover from "../img/content/teacher_2_hover.png";
import teacher_3_bg from "../img/content/teacher_3_bg.png";
import teacher_3_hover from "../img/content/teacher_3_hover.png";
import award_c from "../img/content/award.png";
import award_img from "../img/content/award_img.png";
import news_c from "../img/content/news.png";
import news_img7 from "../../news/img/news_detail_7_1.png"
import contact_c from "../img/content/contact.png";
import contact_icon from "../img/content/contact-icon.png";
import contact_main_img from "../img/content/contact-main-img.png";
import contact_wixin_icon from "../img/content/contact-wixin-icon.png";
import OR_code_two from "../img/content/QR-code-two.png";
import HomeTail_phone from "../../../components/homeTail/HomeTail_phone";

class Home_phone extends Component {
    constructor() {
        super();
        this.state = ({
            strip_state: 0,
        })
    }

    linkToClick(val) {
        this.props.history.push("/" + val);
        localStorage.setItem("route", "")
    }

    stripClick() {
        if (this.state.strip_state == 0) {
            this.stripStart()
            this.setState({
                strip_state: 1
            })
        } else {
            this.stripEnd()
            this.setState({
                strip_state: 0
            })
        }

    }

    stripStart() {
        $("#strip").animate({
            width: "16.8rem",
            marginLeft: "-0.6rem",
        }, 1000)
        $("#letter_m").animate({
            marginTop: "10.2rem",
        }, 1000)
        $("#letter_a").animate({
            marginTop: "10.2rem",
        }, 1000)
        $("#letter_y").animate({
            marginTop: "10.2rem",
        }, 1000)
        $("#letter_f").animate({
            marginTop: "10.2rem",
        }, 1000)
        $("#letter_l").animate({
            marginTop: "10.2rem",
        }, 1000)
        $("#letter_o").animate({
            marginTop: "10.2rem",
        }, 1000)
        $("#letter_w").animate({
            marginTop: "10.2rem",
        }, 1000)
        $("#letter_e").animate({
            marginTop: "10.2rem",
        }, 1000)
        $("#letter_r").animate({
            marginTop: "10.2rem",
        }, 1000)

        $("#line_1").animate({
            marginTop: "3rem",
        }, 1000)
        $("#line_2").animate({
            marginTop: "3rem",
        }, 1000)
        $("#line_3").animate({
            marginTop: "3rem",
        }, 1000)
        $("#line_4").animate({
            marginTop: "3rem",
        }, 1000)
        $("#line_5").animate({
            marginTop: "3rem",
        }, 1000)
        $("#line_6").animate({
            marginTop: "3rem",
        }, 1000)
        $("#line_7").animate({
            marginTop: "3rem",
        }, 1000)
        $("#line_8").animate({
            marginTop: "3rem",
        }, 1000)
    }

    stripEnd() {
        $("#strip").animate({
            width: "50rem",
            marginLeft: "-41rem",
        }, 1000)

        $("#letter_m").animate({
            marginTop: "9rem"
        }, 1000)
        $("#letter_a").animate({
            marginTop: "0rem"
        }, 1000)
        $("#letter_y").animate({
            marginTop: "8.3rem"
        }, 1000)
        $("#letter_f").animate({
            marginTop: "1.8rem"
        }, 1000)
        $("#letter_l").animate({
            marginTop: "6.5rem"
        }, 1000)
        $("#letter_o").animate({
            marginTop: "11.5rem"
        }, 1000)
        $("#letter_w").animate({
            marginTop: "7.9rem"
        }, 1000)
        $("#letter_e").animate({
            marginTop: "16rem"
        }, 1000)
        $("#letter_r").animate({
            marginTop: "13.1rem"
        }, 1000)


        $("#line_1").animate({
            marginTop: "2.5rem"
        }, 1000)
        $("#line_2").animate({
            marginTop: "1.5rem"
        }, 1000)
        $("#line_3").animate({
            marginTop: "5.5rem"
        }, 1000)
        $("#line_4").animate({
            marginTop: "1.5rem"
        }, 1000)
        $("#line_5").animate({
            marginTop: "6.5rem"
        }, 1000)
        $("#line_6").animate({
            marginTop: "7rem"
        }, 1000)
        $("#line_7").animate({
            marginTop: "11rem"
        }, 1000)
        $("#line_8").animate({
            marginTop: "7.2rem"
        }, 1000)
    }

    render() {
        const {t} = this.props;
        return (

            <div className="home-phone-main">
                <div className="popup-home" onTouchEnd={() => this.linkToClick("2nd-musicon-composition-competition-finalists")}>
                    {t("news.news_9_2024_10_title")}！
                </div>

                <Header_phone></Header_phone>
                <div className="home-phone-title">
                    <span className="span1">Mayflower</span>
                    <span className="span2">美好艺术中心</span>
                </div>
                <div className="home-phone-brief">
                    <div className="line-brief1">
                        <div className="brief1-circular"></div>
                        <span className="text1">天才养成的摇篮,梦想起航的地方</span>
                    </div>
                    <div className="line-brief2">
                        <div className="brief2-circular"></div>
                        <span className="text2">BEST MUSIC NURTURING STUDIO</span>
                    </div>
                </div>
                <div className="line-move">
                    <img src={strip} id="strip" onTouchEnd={this.stripClick.bind(this)}/>
                    <div className="img-left"></div>
                    <div className="img-right"></div>
                </div>
                <div className="overlap-group">
                    <div className="m" id="letter_m">M</div>
                    <div className="a" id="letter_a">A</div>
                    <div className="y" id="letter_y">Y</div>
                    <div className="f" id="letter_f">F</div>
                    <div className="l" id="letter_l">L</div>
                    <div className="o" id="letter_o">O</div>
                    <div className="w" id="letter_w">W</div>
                    <div className="e" id="letter_e">E</div>
                    <div className="r" id="letter_r">R</div>
                    <img className="line1" id="line_1" src={home_line}/>
                    <img className="line2" id="line_2" src={home_line}/>
                    <img className="line3" id="line_3" src={home_line}/>
                    <img className="line4" id="line_4" src={home_line}/>
                    <img className="line5" id="line_5" src={home_line}/>
                    <img className="line6" id="line_6" src={home_line}/>
                    <img className="line7" id="line_7" src={home_line}/>
                    <img className="line8" id="line_8" src={home_line}/>
                </div>
                <div className="content-item">
                    <div className="about-content">
                        <div className="about-title">
                            <img src={about_n}/>
                            <span className="span1">关于我们</span>
                            <span className="span2">About us</span>
                        </div>

                        <div className="about-item-content">
                            <img className="img-pianoBackground" src={pianoBackground}/>
                            <img className="img-piano" src={piano}/>

                            <div className="text1" id="about_text1">
                                {t("home.about.title_one")}
                                {/*始于2017年*/}
                            </div>
                            <div className="text2" id="about_text2">
                                {t("home.about.title_two")}
                                {/*强大的综合名师团队*/}
                            </div>
                            <div className="text3"
                                 style={localStorage.getItem('Language') == 'en' ? {letterSpacing: '0rem'} : {}}
                                 id="about_text3">
                                {t("home.about.text_detail_phone")}
                            </div>

                            <div onTouchEnd={() => this.linkToClick("about-phone")} className="text-button"
                                 id="text_button">
                                {t("home.about.btn")}
                            </div>
                        </div>
                    </div>

                    <div className="project-content">
                        <div className="project-title">
                            <img src={project_c}/>
                            <div className="span1">项目介绍</div>
                            <div className="span2">Music Programs</div>
                        </div>

                        <div className="project-item-content">
                            <div className="contentA">
                                <img className="img-A" id="project_img_A" src={img_A}/>
                                <div className="contentBg—A">
                                    <img className="img-top" src={project_A}
                                         onTouchEnd={() => this.linkToClick("musicCourse-phone")}/>
                                    <span
                                        className={localStorage.getItem("Language") == "zh" ? "span-text-zh" : "span-text-en"}>
                                        {t("home.project.project_a")}
                                    </span>
                                </div>
                            </div>
                            <div className="contentB">
                                <img className="img-B" src={img_B}/>
                                <div className="contentBg—B">
                                    <img className="img-top" src={project_B}
                                         onTouchEnd={() => this.linkToClick("studyAbroad-phone")}/>
                                    <span
                                        className={localStorage.getItem("Language") == "zh" ? "span-text-zh" : "span-text-en"}>
                                        {t("home.project.project_b")}
                                    </span>
                                </div>
                            </div>
                            <div className="contentC">
                                <img className="img-C" src={img_C}/>
                                <div className="contentBg—C">
                                    <img className="img-top" src={project_C}
                                         onTouchEnd={() => this.linkToClick("musicProduction-phone")}/>
                                    <span
                                        className={localStorage.getItem("Language") == "zh" ? "span-text-zh" : "span-text-en"}>
                                        {t("home.project.project_c")}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="teacher-content">
                        <div className="teacher-title">
                            <img src={teacher_c}/>
                            <span className="span1">师资力量</span>
                            <span className="span2">Faculties</span>
                        </div>

                        <div className="teacher-item-content">
                            <div className="teacher-1">
                                <div className="teacher-img">
                                    <img className="teacher-1-bg" src={teacher_1_bg}/>
                                    <img className="teacher-1-img" id="teacher_1_img" src={teacher_1_hover}/>
                                </div>
                                <div className="text1">{t("home.teacher.describe_wu_1")}</div>
                                <div className="text2">{t("home.teacher.describe_wu_2")}</div>
                            </div>
                            <div className="teacher-2">
                                <div className="teacher-img">
                                    <img className="teacher-2-bg" src={teacher_2_bg}/>
                                    <img className="teacher-2-img" id="teacher_1_img" src={teacher_2_hover}/>
                                </div>
                                <div className="text1">{t("home.teacher.describe_wang_1")}</div>
                                <div className="text2">{t("home.teacher.describe_wang_2")}</div>
                            </div>
                            <div className="teacher-3">
                                <div className="teacher-img">
                                    <img className="teacher-3-bg" src={teacher_3_bg}/>
                                    <img className="teacher-3-img" id="teacher_1_img" src={teacher_3_hover}/>
                                </div>
                                <div className="text1">{t("home.teacher.describe_yi_1")}</div>
                            </div>
                            <div className="button-text"
                                 onTouchEnd={() => this.linkToClick("teacher-phone")}>{t("home.teacher.btn")}</div>
                        </div>
                    </div>

                    <div className="award-content">
                        <div className="award-title">
                            <img src={award_c}/>
                            <span className="span1">往期荣誉</span>
                            <span className="span2">Honors & Awards</span>
                        </div>
                        <div className="award-item-content-top">
                            <div className="title">学生大学录取名单：<br/>
                                <span style={{fontSize: "0.625rem"}}>Student Admission list</span>
                            </div>
                            <div>
                                <div className="text">
                                    <div className="left-text">2023年 —</div>
                                    <div className="right-text">
                                        {t("home.award.conList_2023_1")}
                                        {/*芝加哥大学：经济、作曲双专业*/}
                                    </div>
                                </div>
                                <div className="text">
                                    <div className="left-text"></div>
                                    <div className="right-text">{t("home.award.conList_2023_2")}</div>
                                </div>
                                <div className="text">
                                    <div className="left-text"></div>
                                    <div className="right-text">{t("home.award.conList_2023_3")}</div>
                                </div>
                                <div className="text">
                                    <div className="left-text"></div>
                                    <div className="right-text">{t("home.award.conList_2023_4")}</div>
                                </div>
                                <div className="text">
                                    <div className="left-text">2022年 —</div>
                                    <div className="right-text">{t("home.award.conList_2022_1")}</div>
                                </div>
                                <div className="text">
                                    <div className="left-text"></div>
                                    <div className="right-text">{t("home.award.conList_2022_2")}</div>
                                </div>
                                {/*<div className="text">*/}
                                {/*    <div className="left-text">2020年 —</div>*/}
                                {/*    <div className="right-text">{t("home.award.conList_2020_1")}</div>*/}
                                {/*</div>*/}
                            </div>
                            <div className="more" onTouchEnd={() => this.linkToClick("roster-phone")}>MORE</div>
                        </div>
                        <div className="award-item-content">
                            <div className="title">学生获得作曲奖项：<br/>
                                <span style={{fontSize: "0.625rem"}}>Music Composition Award Winners:</span>
                            </div>
                            <img src={award_img}/>
                            <p id="contact_p" className="prize-text">
                                <span >2024 Tribeca Young composer competition, Honorable Mention<br/><br/></span>
                                <span>2023 National Young Composers Challenge, Winner<br/><br/></span>
                                <span>2023 National Young Arts Composition, Winner<br/><br/></span>
                                <span>2023 BMI Student Composer Award, Winner<br/><br/></span>
                                <span>2023 The National Youth Orchestra of the United States of America, Apprentice composer<br/><br/></span>
                                <span>2023 MTNA Composition Competition, National 2nd place<br/><br/></span>
                                <span>2022 National Young Arts Composition, Honorable Mention<br/><br/></span>
                                <span>2022 BMI student composer award, Honorable Mention<br/><br/></span>
                                <span>2022 Luna Compositon Lab, Winner<br/><br/></span>
                                <span>2022 Moton Gould Young composer Awards, Winner<br/><br/></span>
                                <span>2022 MTNA Composition Competition, National 3rd place<br/><br/></span>
                                <span>2022 Luna Compositon Lab, Honorable Mention<br/><br/></span>
                                <span>2021 Moton Gould Young composer Awards, Honorable Mention<br/><br/></span>
                                <span>2021 BMI student composer award, Honorable Mention<br/><br/></span>
                                <span>2021 The American Prize, The Ernst Bacon Memorial Award and Winner<br/><br/></span>
                                <span>2020 The National Youth Orchestra of the United States of America, Apprentice composer<br/><br/></span>
                                <span>2019 Composition Competition “Sounds of Children’s Rights”, Winner</span>
                            </p>

                            <div className="more" onTouchEnd={() => this.linkToClick("award-phone")}>MORE</div>
                        </div>
                    </div>

                    <div className="news-content">
                        <div className="news-title">
                            <img src={news_c}/>
                            <span className="span1">艺术中心新闻</span>
                            <span className="span2">Latest New & Events</span>
                        </div>

                        <div className="news-item-content">
                            <div className="item-content">
                                <div className="text-item">
                                    <div className="date">
                                        <div className="icon"></div>
                                        <span>04/08/2024</span>
                                    </div>
                                    <div className="text1">
                                        {t("home.news.December_21_2022_title")}
                                    </div>
                                    <div className="text2">
                                        {t("home.news.December_21_2022_text")}
                                    </div>
                                </div>
                            </div>
                            <div className="item-content">
                                {/*<div className="img-item">*/}
                                {/*    <div>*/}
                                {/*        <div className="img-bg"></div>*/}
                                {/*        <img src={news_img7}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="text-item">
                                    <div className="date">
                                        <div className="icon"></div>
                                        <span>11/14/2023</span>
                                    </div>
                                    <div className="text1">
                                        {t("home.news.seven_title_Sep_30_2022")}
                                    </div>
                                    <div className="text2">
                                        {t("home.news.seven_detail_Sep_30_2022")}
                                    </div>
                                </div>
                            </div>
                            <div className="item-content">
                                <div className="text-item">
                                    <div className="date">
                                        <div className="icon"></div>
                                        <span>05/31/2023</span>
                                    </div>
                                    <div className="text1">
                                        {t("home.news.first_title_July_27_2022")}
                                    </div>
                                    <div className="text2">
                                        {t("home.news.first_detail_July_27_2022")}
                                    </div>
                                </div>
                            </div>

                            <div
                                className="more"
                                onTouchEnd={() => this.linkToClick("news-phone")}
                            >
                                MORE
                            </div>
                        </div>
                    </div>

                    <div className="contact-content">
                        <div className="contact-title">
                            <img src={contact_c}/>
                            <span className="span1">联系我们</span>
                            <span className="span2">Contact Us</span>
                        </div>

                        <div className="contact-item-content">
                            <img className="main-img" src={contact_main_img}
                                 onTouchEnd={() => this.linkToClick("contact-phone")}/>
                            <div className="item1">
                                <div className="img-div">
                                    <img id="contact_icon" src={contact_icon}/>
                                </div>
                                <div className="text">
                                    <div className="text1">MAYFLOWER ART CENTER</div>
                                    <div className="text2">美好艺术中心</div>
                                    <div className="text3">Email:
                                        &nbsp;office@mayflowerartcenter.org
                                    </div>
                                </div>
                            </div>

                            <div className="item2">
                                <div className="img-div">
                                    <img src={contact_wixin_icon}/>
                                </div>
                                <div className="text">
                                    <div className="text1">
                                        {t("home.contact.describe_text1")}
                                        {/*欢迎关注美好艺术中心微信公众号：*/}
                                    </div>
                                    <div className="text2">[MusiCON声先]</div>
                                </div>
                            </div>

                            <div className="item3">
                                <div className="img-div">
                                </div>
                                <div className="or-code">
                                    {/*<div>*/}
                                    {/*    <img src={OR_code_one}/>*/}
                                    {/*    <span>{t("home.contact.course_consultation")}</span>*/}
                                    {/*</div>*/}
                                    <div>
                                        <img src={OR_code_two}/>
                                        {/*<span>{t("home.contact.study_abroad_consultation")}</span>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HomeTail_phone></HomeTail_phone>
            </div>
        );
    }
}

export default withTranslation()(Home_phone);
