import Header from "../../../../components/header/Header";
import Tail from "../../../../components/tail/Tail";
import React, {Component} from "react";
import './detailCommon.scss'
import line from '../img/thin-line.png'
import gulei from '../img/gulei.png'
import guzhengImg from '../img/guzheng.png'
import {withTranslation} from "react-i18next"

class Guzheng extends Component {

    linkToClick(val) {
        localStorage.setItem("route", val);
        this.props.history.push("/" + val);
        if (val == "music-course") {
            localStorage.setItem("object_state", "1");
        }
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Header></Header>
                <div className="composing">
                    <img src={guzhengImg} className="composingImg"/>
                    <div className="detail_text">
                        <div className="main_title">
                            <div className="top_title">Guzheng</div>
                            <div className="foot_title">古筝课程</div>
                        </div>
                        <img src={line} alt="" className="thin-line"/>
                        <div className="detail_guzheng">
                            {t('musicCourse.guzheng_detail')}
                        </div>
                        <div className="teacher_title" style={localStorage.getItem('Language') == 'en' ? {
                            marginTop: '300px',
                            letterSpacing: '1px'
                        } : {marginTop: '68px'}}>{t('musicCourse.responsibleTeacher')}</div>
                        <div className="teacher_list">
                            <div className="first_teacher" onClick={this.linkToClick.bind(this, "detail-LeiGu")}>
                                <img src={gulei} alt="" className="teacher-img"/>
                                <div className="teacher_detail">
                                    <div className="teacher_name">顾磊</div>
                                    <div className="teacher_name_en">Lei Gu</div>
                                </div>
                            </div>
                        </div>
                        <a href="/music-course" onClick={this.linkToClick.bind(this, "music-course")}>
                            <div className="footer_btn"
                                 style={localStorage.getItem('Language') == 'en' ? {width: '165px'} : {width: '135px'}}>
                                <div className="btn-border"><span>{t('musicCourse.moreCourse')}</span></div>
                            </div>
                        </a>
                    </div>
                </div>
                <Tail></Tail>

            </div>

        )
    }
}

export default withTranslation()(Guzheng)
