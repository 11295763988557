import Header_phone from "../../../../../components/header/Header_phone";
import Tail_phone from "../../../../../components/tail/Tail_phone";
import React, {Component} from "react";
import './detailPhoneCommon.scss'
import line from '../../img/thin-line.png'
import wanghanzhi from '../../img/王寒之.png'
import accordionImg from './img/accordionImg.png'
import {withTranslation} from "react-i18next"

class Accordion_phone extends Component {

    linkToClick(val) {
        this.props.history.push("/" + val);
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Header_phone></Header_phone>
                <div className="group">
                    <div className="title">
                        <div className="text1">
                            Classical Accordion
                        </div>
                        <div className="text2" style={{marginLeft: "9rem"}}>
                            古典手风琴课程
                        </div>
                    </div>
                    <img className="thin-line" src={line}/>

                    <img className="right-img" src={accordionImg}/>
                    <div className="group-detail"
                         style={localStorage.getItem('Language') == 'en' ? {letterSpacing: "0rem"} : {letterSpacing: "0.05rem"}}>
                        {t('musicCourse.accordion_detail_first')}
                        <br/><br/>
                        {t('musicCourse.accordion_detail_second')}
                        <br/><br/>
                        {t('musicCourse.accordion_detail_third')}
                        <br/><br/>
                        {t('musicCourse.accordion_detail_fourth')}
                        <br/><br/>
                        {t('musicCourse.accordion_detail_fifth')}
                    </div>
                    <div className="teacher-title">{t('musicCourse.responsibleTeacher')}</div>
                    <div className="teacher-list">
                        <div onTouchEnd={this.linkToClick.bind(this, "detail-phone-HanzhiWang")}>
                            <img src={wanghanzhi} className="teacher-img"/>
                            <div className="teacher-text">
                                <div className="teacher-name">王寒之</div>
                                <div className="teacher-name-en">Hanzhi Wang</div>
                            </div>
                        </div>
                    </div>
                    <a href={"musicCourse-phone"}>
                        <div className="btn-phone-border">
                            {t('musicCourse.moreCourse')}
                        </div>
                    </a>
                </div>
                <Tail_phone></Tail_phone>
            </div>

        )
    }
}

export default withTranslation()(Accordion_phone)
