import i18next from "i18next";
import {initReactI18next} from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import resources from "./resources";
// .use(Backend).use(LanguageDetector)

i18next.use(initReactI18next).init({
        resources: resources,
        lng: localStorage.getItem("Language") ? localStorage.getItem("Language") : "en",
        whitelist: [
            "en",
            "zh"
        ],

        interpolation: {
            escapeValue: false
        }
    }
)
;

export default i18next;