import Header from "../../../../components/header/Header";
import Tail from "../../../../components/tail/Tail";
import React, {Component} from "react";
import './detailCommon.scss'
import line from '../img/thin-line.png'
import xiedongni from "../img/谢冬妮.png"
import jiaorenhe from "../img/焦人和.png"
import pianoImg from "../img/pianoImg.png"
import {withTranslation} from "react-i18next"
import xuyifei from "../img/许艺菲.png";

class Piano extends Component {

    linkToClick(val) {
        localStorage.setItem("route", val);
        this.props.history.push("/" + val);
        if (val == "music-course") {
            localStorage.setItem("object_state", "1");
        }
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Header></Header>
                <div className="composing"
                     style={localStorage.getItem('Language') == 'en' ? {height: '1750px'} : {height: '1450px'}}>
                    <img src={pianoImg} className="composingImg"/>
                    <div className="detail_text">
                        <div className="main_title">
                            <div className="top_title">Piano</div>
                            <div className="foot_title">钢琴课程</div>
                        </div>
                        <img src={line} alt="" className="thin-line"/>
                        <div className="detail_piano"
                             style={localStorage.getItem('Language') == 'en' ? {height: '778px'} : {}}>
                            {t('musicCourse.piano_detail_first')}
                            <br/><br/>
                            <span>{t('musicCourse.violin_first_span')}</span>
                            {t('musicCourse.piano_detail_second')}
                            <br/><br/>
                            <span>{t('musicCourse.piano_second_span')}</span>
                            {t('musicCourse.piano_detail_third')}
                        </div>
                        <div className="teacher_title"
                             style={localStorage.getItem('Language') == 'en' ? {letterSpacing: '1px'} : {}}>{t('musicCourse.responsibleTeacher')}</div>
                        <div className="teacher_list">
                            <div className="first_teacher" onClick={this.linkToClick.bind(this, "detail-DongniXie")}>
                                <img src={xiedongni} alt="" className="teacher-img"/>
                                <div className="teacher_detail">
                                    <div className="teacher_name">谢冬妮</div>
                                    <div className="teacher_name_en">Dongni Xie</div>
                                </div>
                            </div>
                            {/*<div className="second-teacher" onClick={this.linkToClick.bind(this, "detail-YifeiXu")}>*/}
                            {/*    <img src={xuyifei} alt="" className="teacher-img"/>*/}
                            {/*    <div className="teacher_detail">*/}
                            {/*        <div className="teacher_name">许艺菲</div>*/}
                            {/*        <div className="teacher_name_en">Yifei Xu</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="second-teacher" onClick={this.linkToClick.bind(this, "detail-RenheJiao")}>
                                <img src={jiaorenhe} alt="" className="teacher-img"/>
                                <div className="teacher_detail">
                                    <div className="teacher_name">焦人和</div>
                                    <div className="teacher_name_en">Renhe Jiao</div>
                                </div>
                            </div>
                        </div>
                        <a href="/music-course" onClick={this.linkToClick.bind(this, "music-course")}>
                            <div className="footer_btn"
                                 style={localStorage.getItem('Language') == 'en' ? {width: '165px'} : {width: '135px'}}>
                                <div className="btn-border"><span>{t('musicCourse.moreCourse')}</span></div>
                            </div>
                        </a>
                    </div>
                </div>
                <Tail></Tail>

            </div>

        )
    }
}

export default withTranslation()(Piano)
