import Header from "../../../components/header/Header";
import Tail from "../../../components/tail/Tail";
import React, {Component} from "react";
import '../musicCourse/MusicCourse.scss'
import './StudyAbroad.scss'
import ground from '../musicCourse/img/ground.png'
import line from '../musicCourse/img/line.png'
import longLine from '../musicCourse/img/long-line.png'
import studyAbroadImg from './img/study-abroad.png'
import $ from "jquery";
import {withTranslation} from "react-i18next"

class StudyAbroad extends Component {
    constructor() {
        super();
        this.state = ({
            clickState: true
        })
    }

    clickActive() {
        if (this.state.clickState) {
            $("#courseImg").animate({
                top: "310px",
                marginLeft: "680px",
                width: "501px",
                height: "316px",
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#word").animate({
                top: '0',
                left: '0',
                fontSize: "60px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#line-course").animate({
                top: '40px',
                left: '140px'
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#course-ground").animate({
                height: "316px",
                width: "500px",
                marginLeft: "100px",
                top: "158px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })
            $("#word-sp").animate({
                left: "240px",
                top: "75px"
            }, {
                duration: 1000,
                easing: "easeInCubic"
            })

            $("#small_circle").animate({
                marginTop: "560px",
                marginLeft: "20px"
            }, 1000)
            $("#long_line").css("display", "inline")
            $("#long_line").animate({
                top: "922px"
            }, 1000)

            $("#course-ground").css('cursor', "default")
            $("#courseImg").css('cursor', "default")
            $("#word").css('cursor', "default")

            this.changeHeader();
            this.setState({
                clickState: false
            })
        }

    }

    changeHeader() {
        this.ChildPage.changeObject();
    }


    render() {
        const {t} = this.props
        return (
            <div className="study-abroad">
                <Header onRef={ref => this.ChildPage = ref}></Header>
                <div className="MusicCourse" style={{height: '1280px'}}>
                    <div>
                        <img src={studyAbroadImg} className="img-musicCourse" id="courseImg"
                             onClick={this.clickActive.bind(this)}/>
                        <img src={ground} className="img-musicCourse-ground" id="course-ground"
                             onClick={this.clickActive.bind(this)}/>
                    </div>
                    <div className="flex-row">
                        <div className="left-middle-circle"></div>
                        <div className="flex-col">
                            <img className="line-course" src={line} style={{
                                top: '440px', left: '540px',
                                position: 'absolute'
                            }} id="line-course"/>
                            <div className="title" style={{"marginTop": '414px'}}>
                                <div className="word" id="word" style={{left: '360px', top: '80px'}}
                                     onClick={this.clickActive.bind(this)}>Study
                                    Abroad:
                                </div>
                                <div className="word-sp" id="word-sp" style={{top: '140px', left: "70px"}}>音乐留学
                                </div>
                            </div>
                        </div>
                        <div className="small-circle-course">
                            <div className="circleDiv" id="small_circle"
                                 style={{marginLeft: "-470px", marginTop: "340px"}}></div>
                        </div>
                        <div className="right-img">
                            <div className="right-middle-circle" id="rightCircle" style={{top: "440px"}}></div>
                        </div>
                    </div>
                    <div className="course-split" style={localStorage.getItem('Language') == 'en' ? {
                        letterSpacing: '1px',
                        width: '888px'
                    } : {}}>{t('studyAbroad.StudyAbroad')}</div>
                    <div className="point"></div>
                    <img src={longLine} className="longLine" id="long_line"
                         style={{top: "1000px", display: "none"}}/>
                    <div className="introduce" style={{top: "1050px"}}>
                        {t('studyAbroad.detail')}
                    </div>
                </div>
                <Tail></Tail>
            </div>
        )
    }

}

export default withTranslation()(StudyAbroad)
//音乐留学
