import Header from "../../../../components/header/Header";
import Tail from "../../../../components/tail/Tail";
import React, {Component} from "react";
import './detailCommon.scss'
import line from '../img/thin-line.png'
import wanghanzhi from '../img/王寒之.png'
import accordionImg from '../img/pexels-photo-8520185-2.png'
import {withTranslation} from "react-i18next"

class Accordion extends Component {


    linkToClick(val) {
        localStorage.setItem("route", val);
        this.props.history.push("/" + val);
        if (val == "music-course") {
            localStorage.setItem("object_state", "1");
        }
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Header></Header>
                <div className="composing"
                     style={localStorage.getItem('Language') == 'en' ? {height: '2803px'} : {height: '2203px'}}>
                    <img src={accordionImg} className="composingImg"/>
                    <div className="detail_text">
                        <div className="main_title">
                            <div className="top_title">Classical Accordion</div>
                            <div className="foot_title">古典手风琴课程</div>
                        </div>
                        <img src={line} alt="" className="thin-line"/>
                        <div className="detail_accordion"
                             style={localStorage.getItem('Language') == 'en' ? {height: "1883px"} : {height: "1483px"}}>
                            {t('musicCourse.accordion_detail_first')}
                            <br/><br/>
                            {t('musicCourse.accordion_detail_second')}
                            <br/><br/>
                            {t('musicCourse.accordion_detail_third')}
                            <br/><br/>
                            {t('Exploring the classical accordion is a special and unique experience. Students from beginner level to advanced level will receive professional academic level instruction individually. For beginner-level students, this course will guide them through the goals of correct fingering, articulation, and beautiful tones by switching the bellows. Beginner-level students will use original textbooks for classical accordion by Master Wang, published by the recording company Da Capo and the Danish Educate·S Publishing House. In addition, the course will include adaptations from different periods of music. For intermediate students, the course will include more original works for the classical accordion, mainly composed by European composers. In addition, students will perform and collaborate with other musicians on chamber repertoire. For students of advanced level, the main focus will be the possibility of preparing and performing at festivals and concerts in Europe and the United States in the form of solo, chamber, and concerto. Every student involved in the classical accordion course will have the opportunity to attend and be introduced to the Nordaccordion Festival in Norway, considered one of the most important organ music festivals in the world with concerts given by world-class accordion performers and professors.')}
                            <br/><br/>
                            {t('musicCourse.accordion_detail_fifth')}

                        </div>
                        <div className="teacher_title"
                             style={localStorage.getItem('Language') == 'en' ? {letterSpacing: '1px'} : {}}>{t('musicCourse.responsibleTeacher')}</div>

                        <div className="teacher_list">
                            <div className="first_teacher" onClick={this.linkToClick.bind(this, "detail-HanzhiWang")}>
                                <img src={wanghanzhi} alt="" className="teacher-img"/>
                                <div className="teacher_detail">
                                    <div className="teacher_name">王寒之</div>
                                    <div className="teacher_name_en">Hanzhi Wang</div>
                                </div>
                            </div>
                        </div>
                        <a href="/music-course" onClick={this.linkToClick.bind(this, "music-course")}>
                            <div className="footer_btn"
                                 style={localStorage.getItem('Language') == 'en' ? {width: '165px'} : {width: '135px'}}>
                                <div className="btn-border"><span>{t('musicCourse.moreCourse')}</span></div>
                            </div>
                        </a>
                    </div>

                </div>
                <Tail></Tail>
            </div>

        )
    }
}

export default withTranslation()(Accordion)
