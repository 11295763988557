import React, { Component } from "react";
import Header_phone from "../../../../components/header/Header_phone";
import Tail_phone from "../../../../components/tail/Tail_phone";
import style from './NewsDetail-phone-5.module.scss';
import news_detail_5_1 from '../../img/news_detail_5_1.png';
import news_detail_5_2 from '../img/news_detail_5_2.png';
import news_detail_5_3 from '../img/news_detail_5_3.png';
import news_detail_7_1 from '../../img/news_detail_7_1.png';

import { withTranslation } from "react-i18next"

class NewsDetailPhone7 extends Component {
    constructor() {
        window.scrollTo(0, 0)
        super();
    }

    click_last() {
        this.props.history.push("/news-phone-detail-eight");
    }

    click_next() {
        this.props.history.push("/1st-musicon-composition-competition")
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Header_phone></Header_phone>
                <div className={style.newDetail}>
                    <div className={style.main}>
                        <div className={style.top_date}>
                            <div className={style.icon}></div>
                            <div>09/30/2022</div>
                        </div>
                        <div className={style.mbody}>
                            <div className={style.top_text}>
                                <span>{t('news.seven_title_Sep_30_2022')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.seven_Sep_30_2022_1')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.seven_Sep_30_2022_2')}</span>
                            </div>
                            <div>
                                <span className={style.detail_text}>{t('news.seven_Sep_30_2022_3')}</span>
                            </div>
                            <div className={style.text_weight_black}>
                                <span>{t('news.seven_Sep_30_2022_4')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.seven_Sep_30_2022_5')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.seven_Sep_30_2022_5_1')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.seven_Sep_30_2022_5_2')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.seven_Sep_30_2022_5_3')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.seven_Sep_30_2022_5_4')}</span>
                            </div>
                            <div>
                                <span className={style.text_weight_black}>{t('news.seven_Sep_30_2022_6')}</span>
                            </div>
                            <img src={news_detail_7_1} />
                            <div className={style.detail_text}>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.seven_Sep_30_2022_7')}</span>
                            </div>
                            <div className={style.detail_text}>
                                <span>{t('news.seven_Sep_30_2022_8')}</span>
                            </div>
                            <div className={style.detail_text_right}>
                                <span>{t('news.seven_Sep_30_2022_9')}</span>
                            </div>
                        </div>
                        <div className={style.detail_btn}>
                            <div className={style.detail_btn_last} onTouchEnd={()=>this.click_last()}>LAST</div>
                            <div className={style.detail_btn_next} onTouchEnd={()=>this.click_next()}>NEXT</div>
                        </div>
                    </div>
                    <Tail_phone></Tail_phone>
                </div>
            </div>
        )
    }
}
export default withTranslation()(NewsDetailPhone7)
