import React, {Component} from "react";

import "./About.scss"
import piano from './img/piano.png'
import pianoBackground from './img/pianoBackground.png'
import line1 from './img/line1.png'
import line2 from './img/line2.png'
import pianoScore from './img/pianoScore.png'
import piano2 from './img/piano2.png'
import people from './img/people.png'
import Tail from "../../components/tail/Tail";
import Header from "../../components/header/Header";
import $ from 'jquery'
import {withTranslation} from "react-i18next";

//关于我们


class About extends Component {

    constructor(prop) {
        super(prop);
        this.state = ({
            about_state: 0
        })
    }

    componentDidMount() {
        this.setState({
            about_state: 0
        })
    }

    aboutClick() {
        if (this.state.about_state == 0) {
            $("#text").animate({
                marginTop: "-90px",
                marginLeft: "20px"
            }, 1000)
            $("#content3").animate({
                top: "380px",
                left: "290px"
            }, 1000)
            $("#img-piano").animate({
                width: "521px",
                height: "357px",
                top: "116px",
                right: "194px"
            }, 1000)
            $("#img-pianoBackground").animate({
                width: "510px",
                height: "306px",
                top: "185px",
                right: "155px"
            }, 1000)
            $("#circular1").animate({
                top: "600px",
                left: "1040px"
            }, 1000)
            $("#our-story").animate({
                top: "-250px",
                left: "600px"
            }, 1000)
            $("#lineleft").animate({
                top: "-230px",
                left: "0px"
            }, 1000)
            $("#lineRight").animate({
                top: "-230px",
                right: "0px"
            }, 1000)

            this.setState({
                about_state: 1
            })

        } else {
            $("#text").animate({
                marginTop: "-160px",
                marginLeft: "206px"
            }, 1000)
            $("#content3").animate({
                top: "600px",
                left: "230px"
            }, 1000)
            $("#img-piano").animate({
                width: "862px",
                height: "591px",
                top: "10px",
                right: "310px"
            }, 1000)
            $("#img-pianoBackground").animate({
                width: "860px",
                height: "516px",
                top: "200px",
                right: "-130px"
            }, 1000)
            $("#circular1").animate({
                top: "370px",
                left: "480px"
            }, 1000)
            $("#our-story").animate({
                top: "-480px",
                left: "1070px"
            }, 1000)
            $("#lineleft").animate({
                top: "-244px",
                left: "290px"
            }, 1000)
            $("#lineRight").animate({
                top: "-170px",
                right: "-170px"
            }, 1000)
            this.setState({
                about_state: 0
            })
        }

    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Header></Header>
                <div className="about-us">
                    <div className="flex-col-6">
                        <div className="flex-row-4">
                            <div className="upperPart">
                                <div className="welcome-to-mayflower-art-center" id="text">
                                    <span className="content1" id="content1">Welcome to</span><br/>
                                    <span className="content2" id="content2">Mayflower Art Center !</span>
                                </div>
                                <div className="content3" id="content3" style={{whiteSpace: 'pre-wrap'}}>
                                    {t("about.about_content1")}
                                </div>
                            </div>
                            <div className="picture" id="picture">
                                <img className="img-pianoBackground" id="img-pianoBackground" src={pianoBackground}/>
                                <img className="img-piano" id="img-piano" src={piano}
                                     onClick={this.aboutClick.bind(this)}/>
                            </div>
                        </div>
                        <div className="circular1" id="circular1"></div>
                        <div className="linePart" id="linePart">
                            <div className="our-story" id="our-story">Our Story</div>
                            <img className="lineleft" id="lineleft" src={line1}/>
                            <img className="lineRight" id="lineRight" src={line1}/>
                        </div>
                    </div>
                    <div className="overallContent">
                        <div className="leftContent">
                            <div className="paragraph1">
                                <span className="">{t("about.paragraph1_text1")}</span>
                                <span className="writtenWords1">{t("about.paragraph1_text2")}</span>
                                <span className="">{t("about.paragraph1_text3")}</span>
                                <span className="writtenWords1">{t("about.paragraph1_text4")}</span>
                                <span className="" style={{whiteSpace: 'pre-wrap'}}>{t("about.paragraph1_text5")}</span>
                                {/*<span className="">{t("about.paragraph1_text9")}</span>*/}
                                <span className="writtenWords1">{t("about.paragraph1_text6")}</span>
                                <span className="">
                                    {t("about.paragraph1_text7")}
                                    <br/><br/>
                                    {t("about.paragraph1_text8")}
                                </span>
                            </div>
                            <div className="circular2"></div>
                            <img className="piano2" src={piano2}/>
                            <img className="line2" src={line2}/>
                            <div className="paragraph3">
                                <span className=""> {t("about.paragraph3_text1")}</span>
                                <span className="" style={{whiteSpace: 'pre-wrap'}}>
                                    <br/>
                                    <br/>
                                    {t("about.paragraph3_text2")}
                                    <br/>
                                    <br/>
                                    {t("about.paragraph3_text3")}
                                    <br/>
                                    <br/>
                                    {t("about.paragraph3_text4")}
                                    <br/>
                                    <br/>
                                    {t("about.paragraph3_text5")}
                                </span>
                            </div>
                        </div>

                        <div className="rightContent">
                            <img className="pianoScore" src={pianoScore}/>
                            <img className="line2" src={line2}/>
                            <div className="paragraph2">
                                <span className="">{t("about.paragraph2_text1")}</span>
                                <span className="writtenWords1">{t("about.paragraph2_text2")}</span>
                                <span className="">
                                    {t("about.paragraph2_text3")}<br/><br/>
                                    {t("about.paragraph2_text4")}</span><br/><br/>
                                <span className="">{t("about.paragraph2_text6")}</span>
                            </div>
                            <img className="people" src={people}/>
                            <img className="line2" src={line2}/>
                            <div className="circular3"></div>
                        </div>
                    </div>
                </div>
                <Tail></Tail>
            </div>
        );
    }
}

export default withTranslation()(About);
